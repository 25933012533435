import {authenticatorActionFn, authenticatorActionType} from "./actions";
import moment from 'moment';
import update from 'immutability-helper';
import _ from 'lodash';

let today = new Date();
let currentDMY = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
let todayMoment = new moment();


const initial = {
        jwtToken: "",
        authenticated: false,
        user: {
            username: "",
            realname: "",
            permission: 0,
        },
        system: "OTHER",
        information: {},
        expiration: 0,
    }
;

export default function authReducer(state = initial, action) {
    switch (action.type) {
        case authenticatorActionType.AUTHENTICATOR.LOGIN_SUCCESS:
            let informationField= {};
            if ((action.payload.user.system) && (action.payload.user.information[action.payload.user.system])) {
                informationField = action.payload.user.information[action.payload.user.system][0]
            }
            return update(state, {
                authenticated: {$set: true},
                user: {$set: action.payload.user},
                system:  {$set: action.payload.user.system},
                information: {$set: informationField},
                // group: {$set:action.payload.group},
                // groupPermission: {$set:action.payload.groupPermission},
            })
        // return {
        //     jwtToken: action.payload.token,
        //     user: action.payload.user,
        // };
        case authenticatorActionType.AUTHENTICATOR.LOGOUT_SUCCESS:
            return initial;
        default:
            return state;
    }

}

//
// const initState = { idToken: null };
//
// export default function authReducer(state = initState, action) {
//   switch (action.type) {
//     case actions.LOGIN_SUCCESS:
//       return {
//         idToken: action.token
//       };
//     case actions.LOGOUT:
//       return initState;
//     default:
//       return state;
//   }
// }
