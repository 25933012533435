// import {contacts} from "./reducer";
import {createAction} from "redux-actions";
import {createSelector} from "reselect";

function ascendingSort(contact1, contact2) {
    const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
    const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

export const dipDataActionType = {
    SET_CURRENT_MENU: "DIPDATA::SET_CURRENT_MENU",

    DIPDATA: {
        FETCH: "DIPDATA::DIPDATA_FETCH",
        START: "DIPDATA::DIPDATA_START",
        FAIL: "DIPDATA::DIPDATA_FAIL",
        SUCCESS: "DIPDATA::DIPDATA_SUCCESS",
    },
    DIPUPDATE: {
        FETCH: "DIPDATA::DIPUPDATE_FETCH",
        START: "DIPDATA::DIPUPDATE_START",
        FAIL: "DIPDATA::DIPUPDATE_FAIL",
        SUCCESS: "DIPDATA::DIPUPDATE_SUCCESS",
    },
    DIPINFOUPDATE: {
        FETCH: "DIPDATA::DIPINFOUPDATE_FETCH",
        START: "DIPDATA::DIPINFOUPDATE_START",
        FAIL: "DIPDATA::DIPINFOUPDATE_FAIL",
        SUCCESS: "DIPDATA::DIPINFOUPDATE_SUCCESS",
    },
    DIPDEPOSITUPDATE: {
        FETCH: "DIPDATA::DIPDEPOSITUPDATE_FETCH",
        START: "DIPDATA::DIPDEPOSITUPDATE_START",
        FAIL: "DIPDATA::DIPDEPOSITUPDATE_FAIL",
        SUCCESS: "DIPDATA::DIPDEPOSITUPDATE_SUCCESS",
    },
    DIPSTAKEHOLDER_RESET: {
        FETCH: "DIPDATA::DIPSTAKEHOLDER_RESET_FETCH",
        START: "DIPDATA::DIPSTAKEHOLDER_RESET_START",
        FAIL: "DIPDATA::DIPSTAKEHOLDER_RESET_FAIL",
        SUCCESS: "DIPDATA::DIPSTAKEHOLDER_RESET_SUCCESS",
    },
    DIPSALESDATA: {
        FETCH: "DIPDATA::DIPSALESDATA_FETCH",
        START: "DIPDATA::DIPSALESDATA_START",
        FAIL: "DIPDATA::DIPSALESDATA_FAIL",
        SUCCESS: "DIPDATA::DIPSALESDATA_SUCCESS",
    },

    SET_FILTERED_AGENT:"DIPDATA::SET_FILTERED_AGENT",



};


export const dipDataActionFn = {

    dipdataFetch: createAction(dipDataActionType.DIPDATA.FETCH),
    dipdataFetchStart: createAction(dipDataActionType.DIPDATA.START),
    dipdataFetchFail: createAction(dipDataActionType.DIPDATA.FAIL),
    dipdataFetchSuccess: createAction(dipDataActionType.DIPDATA.SUCCESS),

    dipdataUpdate: createAction(dipDataActionType.DIPUPDATE.FETCH),
    dipdataUpdateStart: createAction(dipDataActionType.DIPUPDATE.START),
    dipdataUpdateFail: createAction(dipDataActionType.DIPUPDATE.FAIL),
    dipdataUpdateSuccess: createAction(dipDataActionType.DIPUPDATE.SUCCESS),

    dipdataInfoupdate: createAction(dipDataActionType.DIPINFOUPDATE.FETCH),
    dipdataInfoupdateStart: createAction(dipDataActionType.DIPINFOUPDATE.START),
    dipdataInfoupdateFail: createAction(dipDataActionType.DIPINFOUPDATE.FAIL),
    dipdataInfoupdateSuccess: createAction(dipDataActionType.DIPINFOUPDATE.SUCCESS),

    dipdataDepositupdate: createAction(dipDataActionType.DIPDEPOSITUPDATE.FETCH),
    dipdataDepositupdateStart: createAction(dipDataActionType.DIPDEPOSITUPDATE.START),
    dipdataDepositupdateFail: createAction(dipDataActionType.DIPDEPOSITUPDATE.FAIL),
    dipdataDepositupdateSuccess: createAction(dipDataActionType.DIPDEPOSITUPDATE.SUCCESS),
    
    dipdataStakeholderReset: createAction(dipDataActionType.DIPSTAKEHOLDER_RESET.FETCH),
    dipdataStakeholderResetStart: createAction(dipDataActionType.DIPSTAKEHOLDER_RESET.START),
    dipdataStakeholderResetFail: createAction(dipDataActionType.DIPSTAKEHOLDER_RESET.FAIL),
    dipdataStakeholderResetSuccess: createAction(dipDataActionType.DIPSTAKEHOLDER_RESET.SUCCESS),
    
    dipdataSalesFetch: createAction(dipDataActionType.DIPSALESDATA.FETCH),
    dipdataSalesFetchStart: createAction(dipDataActionType.DIPSALESDATA.START),
    dipdataSalesFetchFail: createAction(dipDataActionType.DIPSALESDATA.FAIL),
    dipdataSalesFetchSuccess: createAction(dipDataActionType.DIPSALESDATA.SUCCESS),

    dipdataSetFilteredAgent:  createAction(dipDataActionType.SET_FILTERED_AGENT),

};


export default {...dipDataActionFn};
