import React, {Component} from 'react';
import {
    Col, Row, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, BackTop, Affix, Form,
    Button, DatePicker
} from 'antd';
import {notification, message} from 'antd';
import {EditOutlined, LockOutlined, DeleteOutlined} from '@ant-design/icons'

import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import NumberFormat from "react-number-format";
// import "../Statement/customStatementStyle.css";
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';

const {TextArea} = Input;
const FormItem = Form.Item;
const {Option, OptGroup} = Select;
const moment = Moment;
// const uuidv4 = require('uuid/v4');
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";
const serverPrefix = (window.location.port === "3000") ? "" : "";
let todayMoment = new moment();


function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
}

class InputStateful extends Component {
    handleFocus = (event) => {
        event.target.select()
    };

    render() {
        return (<Input {...this.props} onClick={this.handleFocus}/>)
    }
}

class basedataEditorForm extends Component {

    state = {
        data: [],
        filteredData: [],
        editable: {},
    };
    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });
    renderSuggestionsContainer = ({containerProps, children, query}) => {
        // console.log(children);

        return <div {...containerProps} style={{
            clear: 'both',
            position: 'absolute',
            minWidth: "360px",
            zIndex: "999",
            backgroundColor: "white",
            padding: "4px",
            border: "1px solid #aaa",
            borderRadius: "4px",
        }}>
            {children}
        </div>;
    };
    dataFetcher = (urlToFetch) => {
        const that = this;
        return fetch(serverPrefix + urlToFetch,
            {
                mode: 'cors',
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('userData')).token
                }
            })
            .then(res => {
                message.success(urlToFetch + " : data loaded", 3, null);
                return res.json();
            }).then(jsonData => {
                //console.log("JSONDATA", jsonData);
                that.setState({
                    data: jsonData.data,
                    filteredData: jsonData.data,
                });
            })
            .catch(err => console.log(err));
    };

    dataSaver = (urlToFetch, data, primaryKey) => {
        const that = this;
        let pKey = primaryKey || "uuid";
        return fetch(serverPrefix + urlToFetch,
            {
                mode: 'cors',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('userData')).token
                },
                body: JSON.stringify({detail: data, key: pKey})
            })
            .then(res => {
                message.success(urlToFetch + " : data saved", 3, null);
                return res.json();
            }).then(jsonData => {
                this.resetState();
                //console.log("JSONDATA", jsonData);
            })
            .catch(err => console.log(err));
    };

    // showPDFModal = (e) => {
    //     console.log(e);
    //     this.setState({
    //         displayPDFModal: true,
    //         displayPDFData: e
    //     });
    // }
    //
    // hidePDFModal = (e) => {
    //     console.log(e);
    //     this.setState({
    //         displayPDFModal: false,
    //         displayPDFData: ""
    //     });
    // }
    dataFilter = (filteredText, filteredColumn) => {
        let localFilteredData;
        localFilteredData = _.filter(this.state.data, (o) => {
            return filteredColumn.some((x) => {
                if (typeof o[x.key] !== "undefined") {
                    try {
                        return o[x.key].toLowerCase().indexOf((filteredText).toLowerCase()) !== -1;
                    } catch {
                        return false;
                    }
                } else {
                    return false;
                }

            })
        });

        this.setState(
            {filteredData: localFilteredData}
        );
    };
    resetState = () => {
        let newEditable = {};
        newEditable.uuid = "";
        newEditable.key = "";
        _.each(this.props.columnDef, (item) => {
            switch (item.type) {
                case "percentage":
                    newEditable[item.key] = "0.00";
                    break;
                case "text":
                    newEditable[item.key] = "";
                    break;
                case "string":
                    newEditable[item.key] = "";
                    break;
                case "password":
                    newEditable[item.key] = "";
                    break;
                case "flag":
                    newEditable[item.key] = "";
                    break;
                default:
                    newEditable[item.key] = "";
                    break;
            }
        });
        this.setState({editable: newEditable});
        this.dataFetcher(this.props.updateDataURL);
    };
    menuItem = this.props.columnDef;
    rowKey = this.props.rowKey || "uuid";

    constructor(props) {
        super(props);
        this.inputBoxRef = React.createRef()   // Create a ref object
    }

    componentDidMount() {
        this.resetState();
        // console.log(this.formData);
    }

    render() {

        const rowStyle = {
            width: '100%',
            display: 'flex',
            flexFlow: 'row wrap'
        };
        const colStyle = {
            marginBottom: '6px'
        };
        const gutter = 16;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
            layout: "horizontal"
        };

        const generateFormDetail = (menu) => {
            let formItemList = [];
            let tableColumnList = [];
            let searchableColumnList = [];
            _.each(menu, (item) => {
                    let renderOption = ("renderOption" in item)
                        ? item.renderOption
                        : {};

                    if ("searchable" in item) {
                        searchableColumnList.push({
                            key: item.key,
                            label: item.label,
                            type: item.type
                        })
                    }

                    switch (item.type) {
                        case "percentage":
                            formItemList.push({
                                key: item.key,
                                label: item.label,
                                component: <NumberFormat
                                    customInput={Input}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    suffix=" %"
                                    fixedDecimalScale={true}
                                    style={{textAlign: "left"}}
                                    onClick={this.handleFocus}
                                    onChange={(e) => {
                                        this.setState({
                                            editable: {
                                                ...this.state.editable,
                                                [item.key]: e.target.value.replace(/,/g, '').replace(/ ?% ?/g, '')
                                            }
                                        })
                                    }}
                                    key={[item.key] + "_component"}
                                    value={this.state.editable[item.key]}
                                />
                            });
                            tableColumnList.push({
                                title: item.label, dataIndex: item.key, key: item.key, align: "center", width: "100px",
                                render: (x) => <NumberFormat
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    suffix=" %"
                                    fixedDecimalScale={true}
                                    value={x}
                                    {...renderOption}
                                />
                            });
                            break;
                        case "datetime":
                            formItemList.push({
                                key: item.key+"_datetimer",
                                label: item.label,
                                component: <DatePicker showTime
                                                       onChange={(value, dateString) => {
                                                           this.setState({
                                                               editable: {
                                                                   ...this.state.editable,
                                                                   [item.key]: value
                                                               }
                                                           })
                                                       }}
                                                       key={[item.key] + "_datetime"}
                                                       value={this.state.editable[item.key]}
                                />
                            });
                            tableColumnList.push({
                                title: item.label, dataIndex: item.key, key: item.key+"_datetime", align: "center",
                                ...renderOption, render: (x) => {
                                    return moment(x).isValid() ? moment(x).format("l LTS") : "-"
                                }
                            });
                            break;
                        case "text":
                            formItemList.push({
                                key: item.key+"_text",
                                label: item.label,
                                component: <TextArea
                                    key={[item.key] + "_component"}
                                    small="true"
                                    autosize={{minRows: 3, maxRows: 6}}
                                    value={this.state.editable[item.key]}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                editable:
                                                    {
                                                        ...this.state.editable,
                                                        [item.key]: e.target.value
                                                    }
                                            })
                                    }}
                                />
                            });
                            tableColumnList.push({
                                title: item.label, dataIndex: item.key, key: item.key+"_text", align: "left",
                                ...renderOption
                            });
                            break;
                        case "flag":
                            formItemList.push({
                                key: item.key,
                                label: item.label,
                                component: <Select key={[item.key] + "_component"}
                                                   value={this.state.editable[item.key] || ""}
                                                   style={{width: "100%"}}
                                    // labelInValue={true}
                                                   onChange={(e) => {
                                                       this.setState(
                                                           {
                                                               editable:
                                                                   {
                                                                       ...this.state.editable,
                                                                       [item.key]: e
                                                                   }
                                                           }
                                                       )
                                                   }}>
                                    {_.map(item.flagOption, function (value, index) {
                                        return <Option key={[item.key]+index} value={index} title={value}>{value}</Option>
                                    })}

                                </Select>
                            });
                            tableColumnList.push({
                                title: item.label, dataIndex: item.key, key: item.key+"_bool", align: "center", width: "80px",
                                render: (x) => {
                                    return (typeof x === "boolean")
                                        ? (x ? "Y" : "N")
                                        : x
                                },
                                ...renderOption
                            });
                            break;
                        case "string":
                            formItemList.push({
                                key: item.key,
                                label: item.label,
                                component: <Input
                                    key={[item.key] + "_component"}
                                    small="true"
                                    value={this.state.editable[item.key]}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                editable:
                                                    {
                                                        ...this.state.editable,
                                                        [item.key]: e.target.value
                                                    }
                                            })
                                    }}
                                />
                            });
                            tableColumnList.push({
                                title: item.label, dataIndex: item.key, key: item.key+"_bool2", align: "center",
                                ...renderOption
                            });
                            break;
                        case "password":
                            formItemList.push({
                                key: item.key,
                                label: item.label,
                                component: <Input
                                    key={[item.key] + "_component"}
                                    small="true"
                                    value={this.state.editable[item.key]}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                editable:
                                                    {
                                                        ...this.state.editable,
                                                        [item.key]: e.target.value
                                                    }
                                            })
                                    }}
                                />
                            });
                            // tableColumnList.push({
                            //     title: item.label, dataIndex: item.key, key: item.key, align: "center",
                            //     ...renderOption
                            // });
                            break;
                        case "download":
                            tableColumnList.push({
                                title: 'ดาวน์โหลด', dataIndex: item.key, key: item.key + '_download', width: "50px",
                                render: (x) => {
                                    return <Button size="small" onClick={(y) => {
                                        // this.props.getAttachmentFile(x.uuid);
                                        fetch(serverPrefix + `/policy/getAttachmentFile`,
                                            {
                                                mode: 'cors',
                                                method: 'SEARCH',
                                                headers: {
                                                    'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('userData')).token,
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                    uuid: x.uuid,
                                                }),

                                                //responseType: 'blob'
                                            })
                                            .then(response => response.blob())
                                            .then(blob => saveAs(blob, x.originalFile));
                                    }}>ดาวน์โหลด</Button>
                                }
                            },);
                            break;
                        default:
                            break;
                    }
                }
            );
            tableColumnList.push({
                title: "Action", key: "actionpanel", align: "center", width: "180px",
                render: (x) => <div>
                    <Button size="small" onClick={(y) => {
                        setFormFieldValue(x);
                    }}><EditOutlined/></Button>
                    &nbsp;
                    {
                        ("lockDataURL" in this.props) &&
                        <Popconfirm placement="topRight" title="ท่านต้องการ Lock รายการดังกล่าวหรือไม่"
                                    onConfirm={(y) => {
                                        this.dataSaver(this.props.lockDataURL, x);
                                    }} okText="ยืนยัน" cancelText="ยกเลิก">
                            <Button size="small" onClick={(y) => {
                                console.log(x);
                            }}><LockOutlined/></Button>
                        </Popconfirm>

                    }
                    {
                        ("deleteDataURL" in this.props) &&
                        <Popconfirm placement="topRight" title="ท่านต้องการลบรายการดังกล่าวหรือไม่"
                                    onConfirm={(y) => {
                                        this.dataSaver(this.props.deleteDataURL, x);
                                    }} okText="ยืนยัน" cancelText="ยกเลิก">
                            <Button size="small" onClick={(y) => {
                                console.log(x);
                            }}><DeleteOutlined/></Button>
                        </Popconfirm>
                    }
                </div>
            });
            // console.log(formItemList);
            return {formItemList, tableColumnList, searchableColumnList};
        };
        const formDetail = generateFormDetail(this.menuItem);
        const formData = _.map(formDetail.formItemList, (item) => {
            return <Col md={8} sm={12} xs={12} style={colStyle}>
                <FormItem
                    key={[item.key] + "formItem"}
                    {...formItemLayout}
                    label={item.label}
                >
                    {item.component}
                </FormItem>
            </Col>
        });

        const tableColumn = formDetail.tableColumnList;
        const searchableColumn = formDetail.searchableColumnList;

        const setFormFieldValue = (data) => {
            let newEditable = {};
            newEditable.uuid = data.uuid;
            _.each(this.menuItem, (column) => {
                if (column.type === "flag") {
                    newEditable[column.key] = data[column.key]
                } else if (column.type === "password") {
                    newEditable[column.key] = "";
                } else if (column.type === "datetime") {
                    newEditable[column.key] = moment(data[column.key]);
                } else {
                    newEditable[column.key] = data[column.key]
                }

            });

            this.setState({
                editable: newEditable
            });
        };


        const cellStyle = {
            verticalAlign: "top",
            padding: "5px",
            // border: "1px solid grey",
            // borderSpacing: "5px",
            // marginBottom: '6px'
        };
        const headerCellStyle = {
            verticalAlign: "top",
            textAlign: "center",
            padding: "5px",
            background: "#F6F6F6",
            fontWeight: "bold",
            fontSize: "1.1em",
            // border: "1px solid grey",
            // borderSpacing: "5px",
            // marginBottom: '6px'
        };

        const headerStyle = {
            fontWeight: "bold",
            fontSize: "1.05em",
            display: "block",
            textDecoration: "underline",
        };


        const formItemCustomBlockLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        const buttonMargin = {
            margin: '0 8px 8px 0'
        };

        return ([
                <div style={{
                    border: "2px",
                    padding: "6px",
                    width: "100%",
                    backgroundColor: "#FFFFFF"
                }}>
                    <h4 style={{marginLeft: "10px", marginTop: "10px"}}>{this.props.headerText}</h4><br/>
                    <Row style={rowStyle} gutter={gutter} justify="center" key="editAdminData" ref={this.inputBoxRef}>
                        {formData}
                        <Col md={24} sm={24} xs={24} style={colStyle}>
                            <div title="ข้อมูล">
                                <div style={{textAlign: "center"}}>
                                    <Col md={24} sm={24} xs={24} style={colStyle}>
                                        {((this.state.editable[this.props.primaryKey || "uuid"] || "") === "") &&
                                        <Button type="primary"
                                                onClick={() => {
                                                    //console.log(this.state.editable);
                                                    this.dataSaver(this.props.createDataURL, this.state.editable);

                                                }}>
                                            สร้างรายการใหม่
                                        </Button>
                                        }
                                        {((this.state.editable[this.props.primaryKey || "uuid"] || "") !== "") &&
                                        <Button type="primary"
                                                onClick={() => {
                                                    this.dataSaver(this.props.saveDataURL, this.state.editable, this.props.primaryKey);
                                                }}>
                                            แก้ไขข้อมูล
                                        </Button>
                                        }
                                        &nbsp;
                                        <Button type="danger"
                                                onClick={this.resetState}>
                                            เริ่มการค้นหา/แก้ไขใหม่
                                        </Button>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>,
                <br/>,
                <div style={{
                    border: "2px",
                    padding: "6px",
                    width: "100%",
                    backgroundColor: "#FFFFFF"
                }}>
                    <Row style={{...rowStyle}} gutter={gutter} justify="center" key="editAdminDataTable">
                        <Col md={24} sm={24} xs={24} style={colStyle}>
                            <div title="รายการ">
                                <Input placeholder="ค้นหาข้อมูล"
                                       size="large"
                                       onChange={(e) => this.dataFilter(e.target.value, searchableColumn)}/>
                                <Table
                                    bordered={true}
                                    size={"small"}
                                    columns={tableColumn}
                                    scroll={{y: 300}}
                                    dataSource={this.state.filteredData}
                                    rowKey={this.props.primaryKey || "uuid"}
                                    rowKeys={this.props.primaryKey || "uuid"}
                                    // rowKey="uuid"
                                    // rowKeys="uuid"
                                    pagination={{pageSize: 50}}
                                />
                                {/*<Button onClick={()=>{console.log(this.props,this.props.primaryKey)}}>Test</Button>*/}
                            </div>
                        </Col>
                    </Row>
                </div>
            ]
        );
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        auth: state.Auth,
        // currentCustomer: state.Transactions.currentCustomer,
        // currentInsurance: state.Transactions.currentInsurance,
        // financial: state.Transactions.financial,
        // endorsementSave: state.Transactions.endorsementSave,

        // fromDB: state.Transactions.fromDB,
    }
}

// export default (App);

//const WrappedFormWIthSubmissionButton = Form.create()(basedataEditorForm);
//export default connect(mapStateToProps, actions)(WrappedFormWIthSubmissionButton);


export default connect(mapStateToProps,
    {}
)(basedataEditorForm);
