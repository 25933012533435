import React, {useEffect,lazy,useState} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route, Link, withRouter, NavLink, Redirect} from 'react-router-dom';
import {
    Layout, Col, Row, Tabs, Table, Button, Input, Affix,
    Icon, Box, Radio, Form, Select, Checkbox, Menu, Breadcrumb, Popover
    // Menu, Breadcrumb, Highlight,
} from 'antd';
import {UserOutlined, LockOutlined, LogoutOutlined} from "@ant-design/icons";
import {Helmet} from 'react-helmet';
import {connect} from "react-redux";
import {baseDataActionFn} from "../redux/basedata/actions";
import SideMenu from "./Menu"
import DataManagementMenu from "./dataManagement/Menu"
import UserManagementMenu from "./userManagement/Menu"
import SuffixMenu from "./userManagement/SuffixMenu"
import {permissionParser,permissionChecker} from "../utility/permissionHelper";
import LoginForm from "./login/login"
import './MainLayout.css';
import metadataFile from '../metadata.json';


import userManagement from "./userManagement/index.users"
import dipUserManagement from "./userManagement/index.dipusers"
import profileManagement from "./userManagement/index.useredit"
import exclusionManagement from "./dataManagement/index.exclusion"

import Error403 from "./statusPage/403"
import Error501 from "./statusPage/501"
import Error404 from "./statusPage/404"

import EmbedLoginForm from "./login/embedLoginForm"
import Announcement from "./announcement/index.public"
import MembershipAnnouncement from "./announcement/index.member"
import SignUpForm from "./registeration/index"
import DipReport from "./dip/index"
import * as Constant from "../constants"
import {authenticatorActionFn} from "../redux/auth/actions";

// import { asyncComponent } from 'react-async-component';
// import {DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
// import _ from "lodash";
// import NumberFormat from "react-number-format";
// import LoadingScreen from 'react-loading-screen';
// import Highlighter from 'react-highlight-words';


const {TabPane} = Tabs;
const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

function ProtectedRoute({component: Component, permission: Permission, ...restOfProps}) {
    const userDataString = localStorage.getItem("userData");
    const defaultUser = {user:{permissionList:[],success:false}}

    let userData;
    if (userDataString !== "") {
        try {
            userData = JSON.parse(userDataString);
        } catch (e) {
            userData = defaultUser
        }
    } else {
        userData = defaultUser
    }

    const currentPermission = permissionParser(userData);
    const allowed = permissionChecker(currentPermission,Permission || ['LOGIN'])

    // console.log(userData,userData.success ,currentPermission,allowed)

    return (
        <Route
            {...restOfProps}
            render={(props) => {
                if (userData && userData.success && allowed) {
                    return <Component {...props} />
                } else if (userData && userData.success) {
                    return <Redirect to="/403"/>
                } else {
                    return <Redirect to="/"/>
                }
            }}
        />
    );
}
const importView = viewPath =>
    lazy(() =>
        import(`./${viewPath}`).catch(() =>
            import(`./statusPage/404`)
        )
    );

class MainLayout extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    componentDidMount = () => {

    }

    componentDidUpdate = () => {

    }

    render() {
        const {collapsed} = this.state;
        const {history} = this.props;
        const getTitle = () => {
            // console.log(this.props.auth);
            if (this.props.auth.system) {
                switch ((this.props.auth.system || "OTHER").toUpperCase()) {
                    case "DCB":
                        return ["DCB","Deposit for Customs Broker"]
                    case "CDI":
                        return ["CDI","Container Deposit Insurance"]
                    case "DIP":
                        return ["DIP","Default Insurance Program"]
                    case "ADMIN":
                        return ["ADM","TSSC e-Admin"]
                    default:
                        return [<>TSSC</>,"TSSC e-Services"]
                }
            }
        }
        return <Router>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getTitle()[1]}</title>
                <link rel="canonical" href="https://eservice.tssc.co.th"/>
            </Helmet>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                collapsible collapsed={collapsed} onCollapse={this.onCollapse} width={300}>

                <Header style={{padding: 0, height: "60px", top: 0}}>
                    <div align={"center"}>
                                <span style={{
                                    color: "#7eca64",
                                    align: "center",
                                    fontSize: collapsed ? "20px" : "16px"
                                }}>{collapsed ? getTitle()[0] : getTitle()[1].toUpperCase()}</span>
                    </div>
                </Header>
                {!collapsed && <EmbedLoginForm />}

                <SideMenu/>
                <DataManagementMenu/>
                <UserManagementMenu/>
                <SuffixMenu/>


            </Sider>
            <Layout className="site-layout" style={{marginLeft: collapsed ? 60 : 300}}>
                {/*<Header className="site-layout-background" style={{padding: 0}}/>*/}
                <Content style={{margin: '0 16px'}}>

                    <Switch>
                        <Route exact path="/dashboard">
                            Welcome
                        </Route>
                        <Route exact path="/genericAnnouncement">
                            <Announcement />
                        </Route>
                        <Route exact path="/membershipAnnouncement">
                            <MembershipAnnouncement />
                        </Route>
                        <Route exact path="/signup/:mode/:system"
                               render={
                                   (props) =>
                                       <SignUpForm {...props} />
                               }
                        />

                        <ProtectedRoute exact path="/dipReport" permission={['ADMIN','DIP-USER','DIP-ADMIN','DIP-INSURANCE']} component={DipReport}/>

{/*                     <Route exact path="/onlineStore/store/:sitename" component={SiteStock}>
                        </Route>
                        <Route exact path="/offlineStore/configuration">
                            <OfflineStoreConfiguration/>
                        </Route>*/}
                        <Route exact path="/">
                            {/*<OfflineStoreConfiguration/>*/}
                        </Route>
                        <ProtectedRoute exact path="/data/exclusion" permission={['ADMIN']} component={exclusionManagement}/>
                        <ProtectedRoute exact path="/account/user" permission={['ADMIN']} component={userManagement}/>
                        <ProtectedRoute exact path="/account/userlist" permission={['ADMIN']} component={userManagement}/>
                        <ProtectedRoute exact path="/account/dipuserlist" permission={['ADMIN']} component={dipUserManagement}/>
                        <ProtectedRoute exact path="/account/userprofile" permission={['LOGIN']} component={profileManagement}/>
                        <ProtectedRoute exact path="/account/my" permission={['LOGIN']} component={Error404}/>
                        <Route exact path="/403" component={Error403}/>
                        <Route exact path="/404" component={Error404}/>
                        <Route exact path="/501" component={Error501}/>

                    </Switch>
                    {/*<div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                                Bill is a cat.
                            </div>*/}
                </Content>
                <Footer style={{textAlign: 'center'}}>{Constant.projectName[(this.props.auth.system || "OTHER").toUpperCase()][1]} - v{metadataFile.buildString} <Popover title={"Developed by"} content={"Natawut.ch - 2021"}>&copy;</Popover></Footer>
            </Layout>

            {/*</Layout>*/}
        </Router>

    }
}


function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        baseData: state.BaseData,
        auth: state.Auth,
        // statement: state.Statement,

    }
}

let MainLayoutWithRouter = withRouter(MainLayout)
export default connect(mapStateToProps,
    {
        ...baseDataActionFn,
        ...authenticatorActionFn
    }
)(MainLayout);
