import {basedataActionFn, baseDataActionType as basedata} from "./actions";
import {createSelector} from 'reselect'
import moment from 'moment';
import _ from 'lodash';
import update from 'immutability-helper';
// import {reportActionType as reportAction} from "../reports/actions";

let today = new Date();
let currentDMY = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
let todayMoment = new moment();


const initial = {
    dataLoaded: {
        productList: "NONE",
        siteList: "NONE",
        storeList: "NONE",
        currentSite: "",
    },
    currentMenu: "baseStore"
};

export default function baseDataReducer(state = initial, action) {

    switch (action.type) {


        case basedata.SET_CURRENT_MENU:
            return update(state,{
                currentMenu:{$set:action.payload},
            })

        default:
            return state;

    }

}
