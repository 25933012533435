import {all, takeEvery, fork, call, put, delay} from 'redux-saga/effects';
import {saleDataActionType, saleDataActionFn} from "./actions";
import {restockDataActionType, restockDataActionFn} from "../restockdata/actions";

import {notification, message} from 'antd';
import * as API from './api';
import _ from "lodash";
import {inspect} from 'util'

const authString = "";

const openNotificationWithIcon = (type, header, detail) => {
    notification[type]({
        message: header,
        description: detail,
        duration: 5,
    });
};

const openStayNotificationWithIcon = (key, duration, type, header) => {
    let targetDuration = duration;
    notification[type]({
        key,
        message: header,
        duration: targetDuration,
    });
};

const closeStayNotification = (key) => {
    notification.close(key);
}


function* fetchsaleData(action, dispatch) {
    let branchNumber = action.payload.branchNumber;
    // console.log(action.payload)
    openStayNotificationWithIcon("saleData", 0, "info", "Loading Sales Data of Branch-" + branchNumber + ".");
    try {
        let salesData = yield call(API.getSalesDetail, action.payload);
        // console.log("DEBUG",salesData);
        if (!("error" in salesData)) {
            openStayNotificationWithIcon("saleData", 3, "success", "Sales Data of Branch-" + branchNumber + " loaded.");
            yield put(saleDataActionFn.saleDataSaleItemSuccess({branchNumber, saleData: salesData.IndividualSale, dataRange:salesData.DataRange}));

        } else {
            openStayNotificationWithIcon("saleData", 3, "error", "Sales Data of Branch-" + branchNumber + " cannot be loaded.");
            yield put(saleDataActionFn.saleDataSaleItemFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("saleData", 3, "error", "Cannot load sales data");
    }
}

function* fetchReorder(action, dispatch) {
    openStayNotificationWithIcon("reorder", 0, "info", "Creating new Re-order Request.");
    try {
        let reorderData = yield call(API.saveReorderData, action.payload);
        // console.log("DEBUG",salesData);
        if (!("error" in reorderData)) {
            openStayNotificationWithIcon("reorder", 3, "success", "New Re-order Request created.");
            yield put(saleDataActionFn.saleDataReorderSuccess(reorderData));
            yield put(restockDataActionFn.restockDataReorderFetch(
                {
                    orderNumber: reorderData.documentNumber,
                    branchName: reorderData.branchResult[0].branchName,
                    mode: "restock"
                }
            ))

        } else {
            openStayNotificationWithIcon("reorder", 3, "error", "New Re-order Request cannot be created.");
            yield put(saleDataActionFn.saleDataSaleItemFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("saleData", 3, "error", "Cannot load sales data");
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(saleDataActionType.SALES_ITEM.FETCH, fetchsaleData),
        takeEvery(saleDataActionType.REORDER.FETCH, fetchReorder),
    ]);
}
