import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
// import createHistory from 'history/createBrowserHistory';
import {routerReducer, routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger'
import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';
// import devToolsEnhancer from 'remote-redux-devtools';
import {composeWithDevTools} from 'redux-devtools-extension';
// import Immutable from "immutable";

const createHistory = require("history").createBrowserHistory;
// const ReduxStore = require('@uppy/store-redux')


const composeEnhancers = composeWithDevTools({
    // serialize: {
    //     immutable: Immutable,
    //     date:true, regex: true, undefined: true, nan: true,
    //     infinity: true, error: true, symbol: true, map: true, set: true
    // }
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const logger = createLogger({
    // diff: true,
    predicate: (getState, action) => {
        switch (action.type) {
            case "COLLPSE_OPEN_DRAWER":
            case "COLLPSE_CHANGE":
            case "TOGGLE_ALL":
                return false;
            default:
                return true
        }
    },
    level: {prevState: false, action: "info", nextState: "info", error: "info"},

    collapsed: (getState, action, logEntry) => !logEntry.error,
    duration: true,
    diff: true,
    // ...options
});

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware, /*logger*/];

const appReducer = combineReducers({
    ...reducers,
    // uppy: ReduxStore.reducer,
    router: routerReducer
});

const rootReducer = (state, action) => {
    // if (action.type === 'REFRESH_DATA_STORE') {
    //     const {router, App, Auth, BaseData, LanguageSwitcher, ThemeSwitcher} = state;
    //     state = {router, App, Auth, BaseData, LanguageSwitcher, ThemeSwitcher}
    // }

    return appReducer(state, action)
};

const store = createStore(
    // combineReducers({
    //     ...reducers,
    //     // uppy: ReduxStore.reducer,
    //     router: routerReducer
    // }),
    rootReducer,
    // applyMiddleware(...middlewares)
    composeEnhancers(applyMiddleware(...middlewares))
);

// const store = createStore(
//     combineReducers({
//         ...reducers,
//         router: routerReducer
//     }),
//     compose(applyMiddleware(...middlewares))
// );
sagaMiddleware.run(rootSaga);
export {store, history};
