// import {contacts} from "./reducer";
import {createAction} from "redux-actions";
import {createSelector} from "reselect";

function ascendingSort(contact1, contact2) {
    const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
    const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

export const baseDataActionType = {
    SET_CURRENT_MENU: "BASEDATA::SET_CURRENT_MENU",

    DIPDATA: {
        FETCH: "BASEDATA::DIPDATA_FETCH",
        START: "BASEDATA::DIPDATA_START",
        FAIL: "BASEDATA::DIPDATA_FAIL",
        SUCCESS: "BASEDATA::DIPDATA_SUCCESS",
    },
    DIPUPDATE: {
        FETCH: "BASEDATA::DIPUPDATE_FETCH",
        START: "BASEDATA::DIPUPDATE_START",
        FAIL: "BASEDATA::DIPUPDATE_FAIL",
        SUCCESS: "BASEDATA::DIPUPDATE_SUCCESS",
    },
    DIPSALESDATA: {
        FETCH: "BASEDATA::DIPSALESDATA_FETCH",
        START: "BASEDATA::DIPSALESDATA_START",
        FAIL: "BASEDATA::DIPSALESDATA_FAIL",
        SUCCESS: "BASEDATA::DIPSALESDATA_SUCCESS",
    },



};


export const baseDataActionFn = {

    basedataSetCurrentMenu: createAction(baseDataActionType.SET_CURRENT_MENU),

    // basedataDipFetch: createAction(baseDataActionType.DIPDATA.FETCH),
    // basedataDipFetchStart: createAction(baseDataActionType.DIPDATA.START),
    // basedataDipFetchFail: createAction(baseDataActionType.DIPDATA.FAIL),
    // basedataDipFetchSuccess: createAction(baseDataActionType.DIPDATA.SUCCESS),
    //
    // basedataDipUpdate: createAction(baseDataActionType.DIPUPDATE.FETCH),
    // basedataDipUpdateStart: createAction(baseDataActionType.DIPUPDATE.START),
    // basedataDipUpdateFail: createAction(baseDataActionType.DIPUPDATE.FAIL),
    // basedataDipUpdateSuccess: createAction(baseDataActionType.DIPUPDATE.SUCCESS),
    //
    // basedataDipSalesFetch: createAction(baseDataActionType.DIPSALESDATA.FETCH),
    // basedataDipSalesFetchStart: createAction(baseDataActionType.DIPSALESDATA.START),
    // basedataDipSalesFetchFail: createAction(baseDataActionType.DIPSALESDATA.FAIL),
    // basedataDipSalesFetchSuccess: createAction(baseDataActionType.DIPSALESDATA.SUCCESS),

};


export default {...baseDataActionFn};
