import fetch from 'isomorphic-fetch';
import {checkStatus} from "../../utility/fetch_preprocessor";
import stringify from 'json-stringify-safe';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// const serverPrefix = "http://localhost:3000";
const serverPrefix = "";
const authString = "";


export function getDipData() {
    // const q = encodeURIComponent(data);
    // console.log("Suggesting Text : ",text);
    return fetch(serverPrefix + `/dip/getCurrentBaseData`,
        {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            }
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function getDipSalesData(selection) {
    // const q = encodeURIComponent(data);
    // console.log("Suggesting Text : ",text);
    return fetch(serverPrefix + `/dip/getRealtimeSalesData/`+selection.year+'/'+selection.type,
        {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            }
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function updateDipData() {
    return  fetch(`/dip/updateBasedata`,
        {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}


