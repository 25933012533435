import React, {Component} from 'react';
import {
    Col, Row, Icon, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, Form, Breadcrumb, Layout,
    Button, Tabs, Card, Statistic, Upload
} from 'antd';
import {ArrowUpOutlined, ReloadOutlined, EditOutlined, DownloadOutlined} from '@ant-design/icons';
import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import Helmet from "react-helmet";
import NumberFormat from "react-number-format";
import ReactQuill, {Quill} from "react-quill";
import 'react-quill/dist/quill.snow.css';
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';
import ReactToPrint from 'react-to-print';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {baseDataActionFn} from "../../redux/basedata/actions";
import {dipDataActionFn} from "../../redux/dipdata/actions";
import {dipAgentList, dipPeriodGroup, dipSales} from "../../redux/dipdata/selector";
import {permissionParser, permissionChecker} from "../../utility/permissionHelper"
import Excel from "exceljs";
// import XLSX from "xlsx";
// import Excel from 'exceljs';

// const {TextArea} = Input;
// const FormItem = Form.Item;
// const uuidv4 = require('uuid/v4');
// const {Option, OptGroup} = Select;
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";

const {Header, Content, Footer} = Layout;
const {TabPane} = Tabs;
const {Column, ColumnGroup} = Table;
const moment = Moment;
moment.tz.setDefault("Asia/Bangkok");

let todayMoment = new moment();


class DipReport extends Component {
    state = {
        mode: "view",
        htmlData: "",
        selectedYear: "",
        selectedPeriod: "",
        periodString: "",
        yearString: "",
        depositString: "",
        adjustString: "",
        selectedAgent: "*",
        // currentTab: "allagentdeposit",
        currentTab: "allagentadjust",
        editFormTaxId: "",
        editFormAddress: "",
        primaryDepositKey: [],
        excessDepositKey: [],
        primaryAdjustKey: [],
        excessAdjustKey: [],
        fileList: [],
        columnList: [],
        fileData: [],
        targetImport: [],
    };

    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        }
    };

    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });

    componentDidMount() {
        // console.log(this.formData);
        this.props.dipdataFetch();
        this.props.dipdataSetFilteredAgent("all");
    }

    dipForm = React.createRef();

    calculatePeriod(year) {
        let depositArray = [];
        let adjustArray = [];

        for (let i = 1; i <= 8; i++) {
            depositArray.push((year - 1).toString() + i.toString().padStart(2, '0') + "01");
            depositArray.push((year - 1).toString() + i.toString().padStart(2, '0') + "02");
            // adjustArray.push(year.toString() + i.toString().padStart(2, '0') + "01")
            // adjustArray.push(year.toString() + i.toString().padStart(2, '0') + "02")
        }
        for (let i = 1; i <= 12; i++) {
            adjustArray.push(year.toString() + i.toString().padStart(2, '0') + "01")
            adjustArray.push(year.toString() + i.toString().padStart(2, '0') + "02")
        }

        let returnValue = {
            depositArray,
            adjustArray,
            depositString: depositArray.join(","),
            adjustString: adjustArray.join(","),
        }

        // console.log(returnValue);
        return returnValue;


    }

    updateBasedata = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Are you sure?',
            text: "This will reset all Airlines/Period/Agent data",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update this!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.dipdataUpdate();
            }
        })

    }

    updateAgentInformation = (targetName, targetId, targetField, currentValue) => {
        const MySwal = withReactContent(Swal);

        // console.log(targetName, targetId, targetField, currentValue)

        MySwal.fire({
            title: 'Updating agent information of ' + targetName,
            input: 'text',
            inputLabel: targetField,
            inputValue: currentValue,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update this!',
            // preConfirm: () => {
            //     const taxid = Swal.getPopup().querySelector('#swal_taxid').value
            //     const address = Swal.getPopup().querySelector('#swal_address').value
            //     return { target:targetId, taxid, address }
            // }
        }).then(result => {
            if (result.isConfirmed) {
                let updateData = {
                    item_id: targetId,
                    item_type: "agent",
                    info_type: targetField,
                    info_text: result.value,
                }
                console.log(updateData);
                this.props.dipdataInfoupdate(updateData);
            } else {
                console.log("SWAL unconfirmed", result);
            }

        })

        // console.log(newValue);

    }

    generateTemplateXlsx = () => {
        const XLSX = require('xlsx');

        const certField = [
            ["agent_id", "รหัสตัวแทน", "text", false],
            ["agent_name", "ชื่อตัวแทน", "text", true],
            ["period", "Period", "text", true],
            ["primary", "Primary Deposit", "text", true],
            ["excess", "Excess Deposit", "text", true],
        ]


        let target = [...certField];
        // let genericTarget = [...genericField];
        let headerList = [];
        let agentList = [];
        let targetYear = this.state.selectedYear;
        let count = 1;
        headerList.push("#(ลำดับREF)");

        for (let x of target) {
            headerList.push(x[0]);
        }

        for (let x of this.props.dipSales.allAgentDisplayArray) {
            agentList.push([
                count++,
                x.agent_id,
                x.agent_name,
                targetYear,
                x.primary_deposit.toNumber(),
                x.excess_deposit.toNumber(),
            ])
        }

        let demoBook = XLSX.utils.book_new();
        let demoSheet = XLSX.utils.aoa_to_sheet([
            headerList,
            ...agentList
        ]);


        XLSX.utils.book_append_sheet(demoBook, demoSheet, "DEPOSIT-IMPORT");
        XLSX.writeFile(demoBook, 'Deposit-Import-Template-' + targetYear + '-' + moment().format("MMDDHHmm") + '.xlsx');
    }

    generateDepositXlsx = () => {

        const Excel = require('exceljs');
        let generatedTime = moment().format("DD-MM-YY---HH-mm-SS");

        let workbook = new Excel.Workbook();
        let tempRow;
        let titleWidth = 38;
        let numericWidth = 18;

        workbook.creator = "TSSC-DIP";
        workbook.lastModifiedBy = "ADMIN";
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        let summarySheet = workbook.addWorksheet('SUMMARY',
            {
                pageSetup: {paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0, orientation: 'portrait',},
                properties: {
                    outlineLevelCol: 2,
                    defaultRowHeight: 21
                }
            }
        );
        tempRow = summarySheet.addRow(["Contract Summary", this.props.dip.dipSalesData.year, "Generated : " + generatedTime]);
        tempRow.font = {bold: true};
        tempRow = summarySheet.addRow(["Type", "", "Deposit", "Duty", "Vat", "Net"]);

        summarySheet.addRow(["Sales Used", "", this.props.dipSales.contractData.depositSales.toNumber(), "", "",]);
        summarySheet.addRow(["Primary Layer",
            "",
            this.props.dipSales.contractData.primaryDepositAmount.toNumber(),
            this.props.dipSales.contractData.primaryDepositDuty.toNumber(),
            this.props.dipSales.contractData.primaryDepositTax.toNumber(),
            this.props.dipSales.contractData.primaryDepositNet.toNumber()]);
        summarySheet.addRow(["Excess Layer",
            "",
            this.props.dipSales.contractData.excessDepositAmount.toNumber(),
            this.props.dipSales.contractData.excessDepositDuty.toNumber(),
            this.props.dipSales.contractData.excessDepositTax.toNumber(),
            this.props.dipSales.contractData.excessDepositNet.toNumber()]);

        summarySheet.addRow([""]);
        tempRow = summarySheet.addRow(["Primary Layer"]);
        tempRow.font = {bold: true};
        summarySheet.addRow(["Name", "Share(%)", "Deposit", "Duty", "Vat", "Net"]);
        for (let primaryLayer of this.props.dipSales.contractData.primaryLayerDeposit) {
            summarySheet.addRow([primaryLayer.name,
                primaryLayer.primaryRate.toNumber(),
                primaryLayer.deposit.toNumber(),
                primaryLayer.duty.toNumber(),
                primaryLayer.tax.toNumber(),
                primaryLayer.net.toNumber()]);

            // eslint-disable-next-line
            let primaryDepositSheet = workbook.addWorksheet('P-' + (primaryLayer.name.replace('บริษัท ', '').replace('จำกัด', '').replace('มหาชน', '').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").substring(0, 20)),
                {
                    pageSetup: {paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0, orientation: 'portrait',},
                    properties: {
                        outlineLevelCol: 2,
                        defaultRowHeight: 21
                    }
                }
            );

            primaryDepositSheet.addRow([primaryLayer.name]);
            primaryDepositSheet.addRow(["Primary Layer Deposit", this.props.dip.dipSalesData.year, "Generated : " + generatedTime]);
            primaryDepositSheet.addRow(["Name", "Deposit", "Duty", "Vat", "Net"]);
            primaryDepositSheet.addRow(["สรุปยอด"]);
            tempRow = primaryDepositSheet.addRow(["Total Amount",
                primaryLayer.deposit.toNumber(),
                primaryLayer.duty.toNumber(),
                primaryLayer.tax.toNumber(),
                primaryLayer.net.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            primaryDepositSheet.addRow([]);
            primaryDepositSheet.addRow(["รายการแยกตาม Agent"]);
            for (let agentRow of primaryLayer.agentList) {
                primaryDepositSheet.addRow([agentRow.agent_name,
                    agentRow.deposit.toNumber(),
                    agentRow.duty.toNumber(),
                    agentRow.tax.toNumber(),
                    agentRow.net.toNumber()]);
            }
            tempRow = primaryDepositSheet.addRow(["Carry Amount",
                primaryLayer.depositCarry.toNumber(),
                primaryLayer.dutyCarry.toNumber(),
                primaryLayer.taxCarry.toNumber(),
                primaryLayer.netCarry.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            primaryDepositSheet.getColumn(2).numFmt = '#,##0.00';
            primaryDepositSheet.getColumn(3).numFmt = '#,##0.00';
            primaryDepositSheet.getColumn(4).numFmt = '#,##0.00';
            primaryDepositSheet.getColumn(5).numFmt = '#,##0.00';
            primaryDepositSheet.getColumn(6).numFmt = '#,##0.00';
            primaryDepositSheet.getColumn(1).width = titleWidth;
            primaryDepositSheet.getColumn(2).width = numericWidth;
            primaryDepositSheet.getColumn(3).width = numericWidth;
            primaryDepositSheet.getColumn(4).width = numericWidth;
            primaryDepositSheet.getColumn(5).width = numericWidth;
            primaryDepositSheet.getColumn(6).width = numericWidth;
        }

        summarySheet.addRow([""]);
        tempRow = summarySheet.addRow(["Excess Layer"]);
        tempRow.font = {bold: true};
        summarySheet.addRow(["Name", "Share(%)", "Deposit", "Duty", "Vat", "Net"]);
        for (let excessLayer of this.props.dipSales.contractData.excessLayerDeposit) {
            summarySheet.addRow([excessLayer.name,
                excessLayer.excessRate.toNumber(),
                excessLayer.deposit.toNumber(),
                excessLayer.duty.toNumber(),
                excessLayer.tax.toNumber(),
                excessLayer.net.toNumber()]);

            // eslint-disable-next-line
            let excessDepositSheet = workbook.addWorksheet('E-' + (excessLayer.name.replace('บริษัท ', '').replace('จำกัด', '').replace('มหาชน', '').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").substring(0, 20)),
                {
                    pageSetup: {paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0, orientation: 'portrait',},
                    properties: {
                        outlineLevelCol: 2,
                        defaultRowHeight: 21
                    }
                }
            );

            excessDepositSheet.addRow([excessLayer.name]);
            excessDepositSheet.addRow(["Excess Layer Deposit", this.props.dip.dipSalesData.year, "Generated : " + generatedTime]);
            excessDepositSheet.addRow(["Name", "Deposit", "Duty", "Vat", "Net"]);
            excessDepositSheet.addRow(["สรุปยอด"]);
            tempRow = excessDepositSheet.addRow(["Total Amount",
                excessLayer.deposit.toNumber(),
                excessLayer.duty.toNumber(),
                excessLayer.tax.toNumber(),
                excessLayer.net.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            excessDepositSheet.addRow([]);
            excessDepositSheet.addRow(["รายการแยกตาม Agent"]);
            for (let agentRow of excessLayer.agentList) {
                excessDepositSheet.addRow([agentRow.agent_name,
                    agentRow.deposit.toNumber(),
                    agentRow.duty.toNumber(),
                    agentRow.tax.toNumber(),
                    agentRow.net.toNumber()]);
            }
            tempRow = excessDepositSheet.addRow(["Carry Amount",
                excessLayer.depositCarry.toNumber(),
                excessLayer.dutyCarry.toNumber(),
                excessLayer.taxCarry.toNumber(),
                excessLayer.netCarry.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            excessDepositSheet.getColumn(2).numFmt = '#,##0.00';
            excessDepositSheet.getColumn(3).numFmt = '#,##0.00';
            excessDepositSheet.getColumn(4).numFmt = '#,##0.00';
            excessDepositSheet.getColumn(5).numFmt = '#,##0.00';
            excessDepositSheet.getColumn(6).numFmt = '#,##0.00';
            excessDepositSheet.getColumn(1).width = titleWidth;
            excessDepositSheet.getColumn(2).width = numericWidth;
            excessDepositSheet.getColumn(3).width = numericWidth;
            excessDepositSheet.getColumn(4).width = numericWidth;
            excessDepositSheet.getColumn(5).width = numericWidth;
            excessDepositSheet.getColumn(6).width = numericWidth;
        }

        summarySheet.addRow([""]);
        tempRow = summarySheet.addRow(["All agent sales data"]);
        tempRow.font = {bold: true};
        let headerShown;

        headerShown = false;
        for (let chunk of _.chunk(this.props.dip.dipSalesData.validPeriod_deposit, 9)) {
            tempRow = summarySheet.addRow([headerShown ? "" : "Deposit Period", chunk.join(", ")]);
            headerShown = true;
        }
        headerShown = false;
        for (let chunk of _.chunk(this.props.dip.dipSalesData.validPeriod_adjust, 9)) {
            tempRow = summarySheet.addRow([headerShown ? "" : "Adjust Period", chunk.join(", ")]);
            headerShown = true;
        }

        summarySheet.addRow(["Name", "-", "Sales for deposit", "",
            // "Sales for adjust",
            ""]);
        for (let thisAgent of this.props.dipSales.allAgentDisplayArray) {
            // this.props.dipSales.salesEntry
            console.log(this.props.dipSales.salesEntry[thisAgent.agent_id]);
            summarySheet.addRow([thisAgent.agent_name,
                "",
                thisAgent.sales_deposit.toNumber(),
                "",
                // thisAgent.sales_adjust.toNumber(),
            ])
        }

        // summarySheet.getColumn(1).numFmt = '#,##0.00';
        summarySheet.getColumn(2).numFmt = '#,##0.00';
        summarySheet.getColumn(3).numFmt = '#,##0.00';
        summarySheet.getColumn(4).numFmt = '#,##0.00';
        summarySheet.getColumn(5).numFmt = '#,##0.00';
        summarySheet.getColumn(6).numFmt = '#,##0.00';
        summarySheet.getColumn(1).width = titleWidth;
        summarySheet.getColumn(2).width = numericWidth;
        summarySheet.getColumn(3).width = numericWidth;
        summarySheet.getColumn(4).width = numericWidth;
        summarySheet.getColumn(5).width = numericWidth;
        summarySheet.getColumn(6).width = numericWidth;


        let detailSheet = [];


        workbook.xlsx.writeBuffer({
            base64: true
        })
            .then((xls64) => {
                // build anchor tag and attach file (works in chrome)
                var a = document.createElement("a");
                var data = new Blob([xls64], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

                var url = URL.createObjectURL(data);
                a.href = url;
                a.download = "Deposit Record of " + this.props.dip.dipSalesData.year + " Generate on " + generatedTime + ".xlsx";
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    },
                    0);
            })
            .catch(function (error) {
                console.log(error.message);
            });

    }

    generateAdjustXlsx = () => {

        const Excel = require('exceljs');
        let generatedTime = moment().format("DD-MM-YY---HH-mm-SS");
        let workbook = new Excel.Workbook();
        let tempRow;
        let titleWidth = 38;
        let numericWidth = 18;

        workbook.creator = "TSSC-DIP";
        workbook.lastModifiedBy = "ADMIN";
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        let summarySheet = workbook.addWorksheet('SUMMARY',
            {
                pageSetup: {paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0, orientation: 'portrait',},
                properties: {
                    outlineLevelCol: 2,
                    defaultRowHeight: 21
                }
            }
        );
        tempRow = summarySheet.addRow(["Contract Summary", this.props.dip.dipSalesData.year, "Generated : " + generatedTime]);
        tempRow.font = {bold: true};
        tempRow = summarySheet.addRow(["Type", "", "Adjust", "Duty", "Vat", "Net"]);

        summarySheet.addRow(["Sales Used", "", this.props.dipSales.contractData.adjustSales.toNumber(), "", "",]);
        summarySheet.addRow(["Primary Layer",
            "Raw Adjust",
            this.props.dipSales.contractData.primaryAdjustAmount.toNumber()]);
        summarySheet.addRow(["",
            "Received Deposit",
            this.props.dipSales.contractData.primaryDepositRecord.toNumber()]);
        summarySheet.addRow(["",
            "Final",
            this.props.dipSales.contractData.primaryRealAdjust.toNumber(),
            this.props.dipSales.contractData.primaryAdjustDuty.toNumber(),
            this.props.dipSales.contractData.primaryAdjustTax.toNumber(),
            this.props.dipSales.contractData.primaryAdjustNet.toNumber()]
        );

        summarySheet.addRow(["Excess Layer",
            "Raw Adjust",
            this.props.dipSales.contractData.excessAdjustAmount.toNumber()]);
        summarySheet.addRow(["",
            "Received Deposit",
            this.props.dipSales.contractData.excessDepositRecord.toNumber()]);
        summarySheet.addRow(["",
            "Final",
            this.props.dipSales.contractData.excessRealAdjust.toNumber(),
            this.props.dipSales.contractData.excessAdjustDuty.toNumber(),
            this.props.dipSales.contractData.excessAdjustTax.toNumber(),
            this.props.dipSales.contractData.excessAdjustNet.toNumber()]
        );

        summarySheet.addRow([""]);
        tempRow = summarySheet.addRow(["Primary Layer"]);
        tempRow.font = {bold: true};
        summarySheet.addRow(["Name", "Share(%)", "Adjust", "Duty", "Vat", "Net"]);
        for (let primaryLayer of this.props.dipSales.contractData.primaryLayerAdjust) {
            summarySheet.addRow([primaryLayer.name,
                primaryLayer.primaryRate.toNumber(),
                primaryLayer.adjust.toNumber(),
                primaryLayer.duty.toNumber(),
                primaryLayer.tax.toNumber(),
                primaryLayer.net.toNumber()]);

            // eslint-disable-next-line
            let primaryAdjustSheet = workbook.addWorksheet('P-' + (primaryLayer.name.replace('บริษัท ', '').replace('จำกัด', '').replace('มหาชน', '').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").substring(0, 20)),
                {
                    pageSetup: {paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0, orientation: 'portrait',},
                    properties: {
                        outlineLevelCol: 2,
                        defaultRowHeight: 21
                    }
                }
            );

            primaryAdjustSheet.addRow([primaryLayer.name]);
            primaryAdjustSheet.addRow(["Primary Layer Adjust", this.props.dip.dipSalesData.year, "Generated : " + generatedTime]);
            primaryAdjustSheet.addRow(["Name", "Adjust", "Duty", "Vat", "Net"]);
            primaryAdjustSheet.addRow(["สรุปยอด"]);
            tempRow = primaryAdjustSheet.addRow(["Total Amount",
                primaryLayer.adjust.toNumber(),
                primaryLayer.duty.toNumber(),
                primaryLayer.tax.toNumber(),
                primaryLayer.net.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            primaryAdjustSheet.addRow([]);
            primaryAdjustSheet.addRow(["รายการแยกตาม Agent"]);
            for (let agentRow of primaryLayer.agentList) {
                primaryAdjustSheet.addRow([agentRow.agent_name,
                    agentRow.adjust.toNumber(),
                    agentRow.duty.toNumber(),
                    agentRow.tax.toNumber(),
                    agentRow.net.toNumber()]);
            }
            tempRow = primaryAdjustSheet.addRow(["Carry Amount",
                primaryLayer.adjustCarry.toNumber(),
                primaryLayer.dutyCarry.toNumber(),
                primaryLayer.taxCarry.toNumber(),
                primaryLayer.netCarry.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            primaryAdjustSheet.getColumn(2).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(3).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(4).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(5).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(6).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(7).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(8).numFmt = '#,##0.00';
            primaryAdjustSheet.getColumn(1).width = titleWidth;
            primaryAdjustSheet.getColumn(2).width = numericWidth;
            primaryAdjustSheet.getColumn(3).width = numericWidth;
            primaryAdjustSheet.getColumn(4).width = numericWidth;
            primaryAdjustSheet.getColumn(5).width = numericWidth;
            primaryAdjustSheet.getColumn(6).width = numericWidth;
            primaryAdjustSheet.getColumn(7).width = numericWidth;
            primaryAdjustSheet.getColumn(8).width = numericWidth;
        }

        summarySheet.addRow([""]);
        tempRow = summarySheet.addRow(["Excess Layer"]);
        tempRow.font = {bold: true};
        summarySheet.addRow(["Name", "Share(%)", "Adjust", "Duty", "Vat", "Net"]);
        for (let excessLayer of this.props.dipSales.contractData.excessLayerAdjust) {
            summarySheet.addRow([excessLayer.name,
                excessLayer.excessRate.toNumber(),
                excessLayer.adjust.toNumber(),
                excessLayer.duty.toNumber(),
                excessLayer.tax.toNumber(),
                excessLayer.net.toNumber()]);

            // eslint-disable-next-line
            let excessAdjustSheet = workbook.addWorksheet('E-' + (excessLayer.name.replace('บริษัท ', '').replace('จำกัด', '').replace('มหาชน', '').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").substring(0, 20)),
                {
                    pageSetup: {paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0, orientation: 'portrait',},
                    properties: {
                        outlineLevelCol: 2,
                        defaultRowHeight: 21
                    }
                }
            );

            excessAdjustSheet.addRow([excessLayer.name]);
            excessAdjustSheet.addRow(["Excess Layer Adjust", this.props.dip.dipSalesData.year, "Generated : " + generatedTime]);
            excessAdjustSheet.addRow(["Name", "Adjust", "Duty", "Vat", "Net"]);
            excessAdjustSheet.addRow(["สรุปยอด"]);
            tempRow = excessAdjustSheet.addRow(["Total Amount",
                excessLayer.adjust.toNumber(),
                excessLayer.duty.toNumber(),
                excessLayer.tax.toNumber(),
                excessLayer.net.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            excessAdjustSheet.addRow([]);
            excessAdjustSheet.addRow(["รายการแยกตาม Agent"]);
            for (let agentRow of excessLayer.agentList) {
                excessAdjustSheet.addRow([agentRow.agent_name,
                    agentRow.adjust.toNumber(),
                    agentRow.duty.toNumber(),
                    agentRow.tax.toNumber(),
                    agentRow.net.toNumber()]);
            }
            tempRow = excessAdjustSheet.addRow(["Carry Amount",
                excessLayer.adjustCarry.toNumber(),
                excessLayer.dutyCarry.toNumber(),
                excessLayer.taxCarry.toNumber(),
                excessLayer.netCarry.toNumber()]);
            tempRow.font = {bold: true, color: "#FF0000"};

            excessAdjustSheet.getColumn(2).numFmt = '#,##0.00';
            excessAdjustSheet.getColumn(3).numFmt = '#,##0.00';
            excessAdjustSheet.getColumn(4).numFmt = '#,##0.00';
            excessAdjustSheet.getColumn(5).numFmt = '#,##0.00';
            excessAdjustSheet.getColumn(6).numFmt = '#,##0.00';
            excessAdjustSheet.getColumn(1).width = titleWidth;
            excessAdjustSheet.getColumn(2).width = numericWidth;
            excessAdjustSheet.getColumn(3).width = numericWidth;
            excessAdjustSheet.getColumn(4).width = numericWidth;
            excessAdjustSheet.getColumn(5).width = numericWidth;
            excessAdjustSheet.getColumn(6).width = numericWidth;
        }

        summarySheet.addRow([""]);
        tempRow = summarySheet.addRow(["All agent sales data"]);
        tempRow.font = {bold: true};
        let headerShown;

        headerShown = false;
        for (let chunk of _.chunk(this.props.dip.dipSalesData.validPeriod_deposit, 9)) {
            tempRow = summarySheet.addRow([headerShown ? "" : "Deposit Period", chunk.join(", ")]);
            headerShown = true;
        }
        headerShown = false;
        for (let chunk of _.chunk(this.props.dip.dipSalesData.validPeriod_adjust, 9)) {
            tempRow = summarySheet.addRow([headerShown ? "" : "Adjust Period", chunk.join(", ")]);
            headerShown = true;
        }

        summarySheet.addRow(["Name", "Total Sales", "Pri-Dep (Cal)", "Pri-Dep (Actual)", "(BG Disc)","Pri-Adj", "Exc-Dep (Cal)", "Exc-Dep (Actual)", "Exc-Adj"]);
        for (let thisAgent of this.props.dipSales.allAgentDisplayArray) {
            // this.props.dipSales.salesEntry
            console.log(this.props.dipSales.salesEntry[thisAgent.agent_id]);
            summarySheet.addRow([thisAgent.agent_name,
                thisAgent.sales_adjust.toNumber(),
                thisAgent.primary_deposit.toNumber(),
                thisAgent.primaryDeposit.toNumber(),
                thisAgent.bank_guarantee_discount.toNumber(),
                thisAgent.primary_real_adjust.toNumber(),
                thisAgent.excess_deposit.toNumber(),
                thisAgent.excessDeposit.toNumber(),
                thisAgent.excess_real_adjust.toNumber(),
                // thisAgent.sales_adjust.toNumber(),
            ])
        }

        // summarySheet.getColumn(1).numFmt = '#,##0.00';
        summarySheet.getColumn(2).numFmt = '#,##0.00';
        summarySheet.getColumn(3).numFmt = '#,##0.00';
        summarySheet.getColumn(4).numFmt = '#,##0.00';
        summarySheet.getColumn(5).numFmt = '#,##0.00';
        summarySheet.getColumn(6).numFmt = '#,##0.00';
        summarySheet.getColumn(7).numFmt = '#,##0.00';
        summarySheet.getColumn(8).numFmt = '#,##0.00';
        summarySheet.getColumn(9).numFmt = '#,##0.00';
        summarySheet.getColumn(1).width = titleWidth;
        summarySheet.getColumn(2).width = numericWidth;
        summarySheet.getColumn(3).width = numericWidth;
        summarySheet.getColumn(4).width = numericWidth;
        summarySheet.getColumn(5).width = numericWidth;
        summarySheet.getColumn(6).width = numericWidth;
        summarySheet.getColumn(7).width = numericWidth;
        summarySheet.getColumn(8).width = numericWidth;
        summarySheet.getColumn(9).width = numericWidth;

        let detailSheet = [];


        workbook.xlsx.writeBuffer({
            base64: true
        })
            .then((xls64) => {
                // build anchor tag and attach file (works in chrome)
                var a = document.createElement("a");
                var data = new Blob([xls64], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

                var url = URL.createObjectURL(data);
                a.href = url;
                a.download = "Adjust Record of " + this.props.dip.dipSalesData.year + " Generate on " + generatedTime + ".xlsx";
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    },
                    0);
            })
            .catch(function (error) {
                console.log(error.message);
            });

    }

    uploadDepositProps = {
        showUploadList: true,
        accept: ".xlsx",
        onChange: info => {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }
                return file;
            });
            console.log("Now Importing", fileList);
            this.setState({fileList, invalidList: []});
        },

        beforeUpload: file => {

            const reader = new FileReader();
            const XLSX = require('xlsx');

            reader.onload = (e) => {
                let data = new Uint8Array(e.target.result);
                let workbook = XLSX.read(data, {type: 'array'});
                let workSheets = workbook.SheetNames;
                let sheet = workbook.Sheets[workSheets[0]];

                // Get Header
                let headers = [];
                let range = XLSX.utils.decode_range(sheet['!ref']);
                let C, R = range.s.r; /* start in the first row */
                /* walk every column in the range */
                for (C = range.s.c; C <= range.e.c; ++C) {
                    let cell = sheet[XLSX.utils.encode_cell({c: C, r: R})] /* find the cell in the first row */

                    let hdr = "UNKNOWN " + C; // <-- replace with your desired default
                    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                    // console.log({Column: C, header: hdr})
                    headers.push({Column: C, header: hdr});
                }

                // console.log(XLSX.utils.sheet_to_row_object_array(sheet, {header: 1}));
                let fileData = XLSX.utils.sheet_to_json(sheet, {header: 1});
                let targetImport = [];
                let targetAgent = [];
                let targetYear = "";
                fileData.shift();
                console.log("File Data", fileData);
                for (let fileDataRow of fileData) {
                    if ((fileDataRow[4] !== "none") && (fileDataRow[5] !== "none")) {
                        console.log(fileDataRow);
                        targetImport.push(
                            {
                                agentId: fileDataRow[1],
                                year: fileDataRow[3],
                                primaryAmount: parseFloat(fileDataRow[4]).toFixed(2),
                                excessAmount: parseFloat(fileDataRow[5]).toFixed(2),
                            }
                        )
                        targetAgent.push(fileDataRow[1])
                        targetYear = fileDataRow[3]
                    }
                }
                console.log(targetImport);
                // console.log(fileData);
                // fileData = _.remove(fileData,function(n) {
                //     return ((n.length || 0) == 0)
                // });

                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    title: 'Updating deposit data for year:' + targetYear + ' of ' + targetAgent.length + " agents",
                    text: targetAgent.join(","),
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Import',
                    denyButtonText: `Don't import`,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    // preConfirm: () => {
                    //     const taxid = Swal.getPopup().querySelector('#swal_taxid').value
                    //     const address = Swal.getPopup().querySelector('#swal_address').value
                    //     return { target:targetId, taxid, address }
                    // }
                }).then(result => {
                    if (result.isConfirmed) {
                        let updateData = {
                            targetYear: targetYear,
                            targetData: targetImport,
                        }
                        // console.log(updateData);
                        this.props.dipdataDepositupdate(updateData);
                    } else {
                        console.log("SWAL unconfirmed", result);
                    }

                })

                this.setState({
                    columnList: headers,
                    fileData,
                    targetImport
                    // parsedData: [],
                    // checkList: [],
                    // newCustomerList: [],
                    // agentList: {},
                    // isValid: false,
                })
                // return headers;
                // console.log(headers);

                /* DO SOMETHING WITH workbook HERE */
            };
            reader.readAsArrayBuffer(file);
            // if (this.state.fileWithDefaultColumn) parseDataToTable();
            return false; // Not Uploading
        },
        //
        // onChange(info) {
        //     console.log(info.fileList[0]);
        // },
    };

    resetStakeholder = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Are you sure?',
            html: "<br/>This will reset all stakeholder to default" +
                "<br/>Primary" +
                "<br/>บริษัท ทิพยประกันภัย จำกัด (มหาชน)		40%" +
                "<br/>บริษัท กรุงเทพ ประกันภัย จำกัด (มหาชน) 	25%" +
                "<br/>บริษัท ประกันคุ้มภัย จำกัด (มหาชน) 		15%" +
                "<br/>บริษัท แอกซ่าประกันภัย จำกัด (มหาชน)		10%" +
                "<br/>บริษัท เทเวศน์ ประกันภัย จำกัด (มหาชน) 		10%" +
                "<br/>Excess" +
                "<br/>บริษัท ทิพยประกันภัย จำกัด (มหาชน) 		52.5%" +
                "<br/>บริษัท ฟอลคอนประกันภัย จำกัด (มหาชน)		22.5%" +
                "<br/>บริษัท กรุงเทพ ประกันภัย จำกัด (มหาชน)		15%" +
                "<br/>บริษัท ประกันคุ้มภัย จำกัด (มหาชน)		10%",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, do this!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.dipdataStakeholderReset();
            }
        })


    }

    numericFieldRenderer = (data, record) => {
        // console.log(data,record);
        if (!data) {
            console.log(record);
            return 0;
        }

        return <NumberFormat
            value={data.isBigNumber ? data.toNumber() : parseFloat(data)}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator={true}
        />
    }

    onTableRowExpand = (expanded, record, target) => {
        // console.log("Expand",expanded, record,target)
        const keys = [];
        if (expanded) {
            keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
        }
        this.setState({[target]: [...keys]});
    }

    render() {
        const currentLocalUser = this.props.auth;
        const currentPermission = permissionParser(currentLocalUser);

        const agentInformationRenderer = (data, record, field) => {
            // console.log(data,record);
            // console.log(this.props.dip.dipData.agentInfo[record.agent_id]);
            if (field in this.props.dip.dipData.agentInfo[record.agent_id]) {
                return <>
                    {permissionChecker(currentPermission, ['ADMIN', 'THAISTAR']) && <Button size={"small"}
                                                                                            onClick={() => {
                                                                                                this.updateAgentInformation(record.agent_name, record.agent_id, field, this.props.dip.dipData.agentInfo[record.agent_id][field])
                                                                                            }}
                    ><EditOutlined/></Button>}
                    {this.props.dip.dipData.agentInfo[record.agent_id][field]}
                </>
            } else {
                return <>
                    {permissionChecker(currentPermission, ['ADMIN', 'THAISTAR']) && <Button size={"small"}
                                                                                            onClick={() => {
                                                                                                this.updateAgentInformation(record.agent_name, record.agent_id, field, "")
                                                                                            }}
                    ><EditOutlined/></Button>}
                    <span style={{fontSize: "6px"}}></span>
                </>
            }
            // if (this.props.dip && this.props.dip.dipData && this.props.dip.dipData.agentInfo) {
            //     if (field in this.props.dip.dipData.agentInfo[data]) {
            //         return "found"
            //     } else {
            //         return "notfound"
            //     }
            // } else {
            //     return "aa"
            // }

        }

        const airlineFieldSize = "80px";
        const numericFieldSize = "130px";
        const excessLayerNumericColor = "#945f34";
        const primaryLayerNumericColor = "#004386";
        const genericThemeNumericColor = "#236e0a";
        const statisticHeaderValueFontSize = "20px";
        const statisticSubValueFontSize = "16px";


        return (
            <Content style={{padding: '0 50px', marginTop: 64}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>DIP Report</Breadcrumb.Item>
                </Breadcrumb>
                <Helmet>
                    DIP Report
                </Helmet>
                <Form layout={"vertical"}
                      ref={this.dipForm}
                >
                    <Row gutter={[8, 16]}>
                        {(this.props.auth.system === "admin") &&
                            <>
                                <Col span={6}>
                                    <Card title={"Debug Data"}>
                                        <Button onClick={() => {
                                            console.log("Contract", this.props.dipSales.contractData);
                                        }}>Contract Data</Button>
                                        <Button onClick={() => {
                                            console.log("Props", this.props);
                                        }}>Props</Button>
                                        <Button onClick={() => {
                                            console.log("State", this.state);
                                        }}>Local State</Button>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title={"Reset Data"}>
                                        <Button onClick={() => {
                                            this.updateBasedata();
                                        }}>Airlines, Agents, Tariff Periods</Button>
                                        <Button onClick={() => {
                                            this.resetStakeholder();
                                        }}>Stakeholder shares<br/>
                                        </Button>
                                    </Card>
                                </Col>
                                <Col span={10}>
                                    <Card title={"Import Data"}>
                                        <Input readOnly disabled placeholder={"จัดเตรียมข้อมูลก่อน"}
                                               style={{width: "150px"}}
                                               value={this.state.selectedYear}></Input>
                                        <Button onClick={() => {
                                            this.generateTemplateXlsx();
                                        }}>Download Template<br/>
                                        </Button>
                                        <Upload {...this.uploadDepositProps}>
                                            <Button onClick={() => {
                                                // this.updateBasedata();
                                            }}>Import Deposit</Button>
                                        </Upload>
                                    </Card>
                                </Col>
                            </>
                        }
                        <Col span={8}>
                            <Card title={<>กรุณาเลือก ปี ที่ต้องการออกรายงาน&nbsp;
                                <Button size={"small"} onClick={() => {
                                    this.props.dipdataFetch();
                                    this.props.dipdataSetFilteredAgent("all");
                                }}><ReloadOutlined/></Button>
                            </>}>
                                <Form.Item
                                    name="periodYear"
                                >
                                    <Select style={{width: "100%"}} value={this.state.selectedYear} onChange={(x) => {
                                        let targetPeriod = this.calculatePeriod(x);
                                        // console.log(targetPeriod);
                                        this.setState({
                                            selectedYear: x,
                                            yearString: x,
                                            adjustString: targetPeriod.adjustString,
                                            depositString: targetPeriod.depositString
                                        })
                                        this.dipForm.current.setFieldsValue({
                                            selectedYear: x,
                                            yearString: x,
                                            adjustString: targetPeriod.adjustString,
                                            depositString: targetPeriod.depositString
                                        })
                                    }}>
                                        {this.props.periodGroup.year.map(option => {
                                            return <Select.Option value={option.toString()}
                                                                  key={option.toString()}>{option}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={"กรุณาเลือก ประเภท ของรายงานที่ต้องการ"}>
                                <Form.Item
                                    name="periodType"
                                    // rules={[{required: true, message: 'Please input your phone number!'}]}
                                >
                                    <Select style={{width: "100%"}} value={this.state.selectedPeriod} onChange={(x) => {
                                        let newTab = x;

                                        switch (x) {
                                            case "initial":
                                                newTab = "allagentadjust";
                                                break;
                                            case "deposit":
                                            case "depositcustom":
                                                newTab = "allagentdeposit";
                                                break;
                                            case "adjust":
                                            case "adjustcustom":
                                                newTab = "allagentadjust";
                                                break;
                                            default:
                                                newTab = "allagentadjust";
                                        }
                                        let newState = {selectedPeriod: x, periodString: x, currentTab: newTab}
                                        console.log(newState);
                                        this.setState(newState)
                                        this.dipForm.current.setFieldsValue({
                                            periodString: x,
                                        })
                                    }
                                    }>
                                        <Select.Option value={"deposit"}>Deposit (Month 1-8)</Select.Option>
                                        <Select.Option value={"adjust"}>Adjust (Month 1-8 & Month 9-12)</Select.Option>
                                        <Select.Option value={"depositcustom"}>Deposit (Custom)</Select.Option>
                                        <Select.Option value={"adjustcustom"}>Adjust (Custom)</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={<>เรียกดูข้อมูล
                                <Button size="small" onClick={() => {
                                    let x = Moment().format("YYYY");
                                    let targetPeriod = this.calculatePeriod(x);
                                    let targetState = {
                                        selectedYear: x,
                                        yearString: x,
                                        periodType: "deposit",
                                        periodYear: x,
                                        currentTab: "allagentdeposit",
                                        periodString: "deposit",
                                        adjustString: targetPeriod.adjustString,
                                        depositString: targetPeriod.depositString
                                    }
                                    this.setState(targetState)
                                    this.dipForm.current.setFieldsValue(targetState)
                                    this.props.dipdataSalesFetch(targetState);
                                }}>{Moment().format("YYYY")}D</Button>
                                <Button size="small" onClick={() => {
                                    let x = Moment().format("YYYY");
                                    let targetPeriod = this.calculatePeriod(x);
                                    let targetState = {
                                        selectedYear: x,
                                        yearString: x,
                                        periodType: "adjust",
                                        periodYear: x,
                                        currentTab: "allagentadjust",
                                        periodString: "adjust",
                                        adjustString: targetPeriod.adjustString,
                                        depositString: targetPeriod.depositString
                                    }
                                    this.setState(targetState)
                                    this.dipForm.current.setFieldsValue(targetState)
                                    this.props.dipdataSalesFetch(targetState);
                                }}>{Moment().format("YYYY")}A</Button>
                                <Button size="small" onClick={() => {
                                    let x = Moment().subtract(1, "year").format("YYYY");
                                    let targetPeriod = this.calculatePeriod(x);
                                    let targetState = {
                                        selectedYear: x,
                                        yearString: x,
                                        periodType: "deposit",
                                        periodYear: x,
                                        currentTab: "allagentdeposit",
                                        periodString: "deposit",
                                        adjustString: targetPeriod.adjustString,
                                        depositString: targetPeriod.depositString
                                    }
                                    this.setState(targetState)
                                    this.dipForm.current.setFieldsValue(targetState)
                                    this.props.dipdataSalesFetch(targetState);
                                }}>{Moment().subtract(1, "year").format("YYYY")}D</Button>
                                <Button size="small" onClick={() => {
                                    let x = Moment().subtract(1, "year").format("YYYY");
                                    let targetPeriod = this.calculatePeriod(x);
                                    let targetState = {
                                        selectedYear: x,
                                        yearString: x,
                                        currentTab: "allagentadjust",
                                        periodType: "adjust",
                                        periodYear: x,
                                        periodString: "adjust",
                                        adjustString: targetPeriod.adjustString,
                                        depositString: targetPeriod.depositString,

                                    }
                                    this.setState(targetState)
                                    this.dipForm.current.setFieldsValue(targetState)
                                    this.props.dipdataSalesFetch(targetState);
                                }}>{Moment().subtract(1, "year").format("YYYY")}A</Button>
                            </>}>
                                <Form.Item
                                    // name="requestData"
                                    // rules={[{required: true, message: 'Please input your phone number!'}]}
                                >
                                    <Button style={{width: "100%"}}
                                            disabled={(this.state.periodString === "") || (this.state.yearString === "")}
                                            onClick={() => {
                                                // console.log(this.dipForm.current.getFieldsValue());
                                                this.props.dipdataSalesFetch(this.dipForm.current.getFieldsValue());
                                            }}>จัดเตรียมข้อมูล
                                    </Button>
                                </Form.Item>
                            </Card>
                        </Col>
                        {/*<Col span={12}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="yearString"*/}
                        {/*        label={<>Year parameter</>}*/}
                        {/*        disabled={true}*/}
                        {/*        // rules={[{required: true, message: 'Please input your phone number!'}]}*/}
                        {/*    >*/}
                        {/*        <Input style={{width: "100%"}}*/}
                        {/*               disabled={true}*/}
                        {/*               value={this.state.yearString}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={12}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="periodString"*/}
                        {/*        label={<>Period parameter</>}*/}
                        {/*        // rules={[{required: true, message: 'Please input your phone number!'}]}*/}
                        {/*    >*/}
                        {/*        <Input style={{width: "100%"}}*/}
                        {/*               disabled={true}*/}
                        {/*               value={this.state.periodString}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={12}>
                            <Card title={<>Tariff Month สำหรับคำนวณ Deposit<br/>(Format : YYYYMMPP / PP=รหัสPeriod /
                                คั่นด้วยเครื่องหมาย comma [,])</>}>
                                <Form.Item
                                    name="depositString"
                                    // rules={[{required: true, message: 'Please input your phone number!'}]}
                                >
                                    <Input style={{width: "100%"}}
                                           disabled={["depositcustom", "adjustcustom"].indexOf(this.state.selectedPeriod) < 0}
                                           value={this.state.depositString}
                                           onChange={(x) => {
                                               // console.log(this.state);
                                               this.setState({
                                                   depositString: x.target.value,
                                               });
                                           }}
                                    />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title={<>Tariff Month สำหรับคำนวณ Adjust<br/>(Format : YYYYMMPP / PP=รหัสPeriod /
                                คั่นด้วยเครื่องหมาย comma [,])</>}>
                                <Form.Item
                                    name="adjustString"

                                    // rules={[{required: true, message: 'Please input your phone number!'}]}
                                >
                                    <Input style={{width: "100%"}}
                                           disabled={["depositcustom", "adjustcustom"].indexOf(this.state.selectedPeriod) < 0}
                                           value={this.state.adjustString}
                                           onChange={(x) => {
                                               // console.log(this.state);
                                               this.setState({
                                                   adjustString: x.target.value,
                                               });
                                           }}
                                    />
                                </Form.Item>
                            </Card>
                        </Col>
                        {/*</Row>*/}
                        {/*<Row gutter={[16, 16]} style={{background: "#FFF", paddingTop: "20px", paddingBottom: "20px"}}>*/}
                        {/*<Col span="8">เลือก Agent เพื่อ Filter ข้อมูล<br/>
                            <Select style={{width: "300px"}}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        if (option.children) {
                                            // console.log(option.children);
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }}
                                    defaultValue={"all"}
                                    value={this.props.dip.filteredAgent} onChange={(x) => {
                                this.props.dipdataSetFilteredAgent(x)
                            }}>
                                <Select.Option value={"all"} key={"all"}>[ALL]</Select.Option>
                                {this.props.agentList.map(option => {
                                    return <Select.Option value={option.agent_id}
                                                          key={option.agent_id}>{option.agent_id + " - " + option.agent_name}</Select.Option>
                                })}
                            </Select><br/><br/>
                        </Col>*/}
                        <Col span="24" style={{textAlign: "right"}}>
                            {(this.state.currentTab === "allagentdeposit") && <>
                                <p><b>Primary layer</b> : <span style={{color: "blue"}}>0.07% of sales entry (Deposit Tariff Month) Rounded up (0.01->1), Minimum of 7000</span><br/>
                                    <b>Excess layer</b> : <span style={{color: "blue"}}>0.04% of sales entry (Deposit Tariff Month) Rounded up (0.01->1)</span><br/>
                                </p>
                            </>}
                            {(this.state.currentTab === "allagentadjust") && <>
                                <p><b>Primary layer</b> : <span style={{color: "green"}}>0.07% of sales entry (Adjust Tariff Month) Rounded up (0.01->1)</span>
                                    <b> - </b> <span style={{color: "blue"}}>Primary Deposit</span> <b>-</b> <span
                                        style={{color: "brown"}}>Discount Bank Guarantee</span><br/>
                                    <b>Excess layer</b> : <span style={{color: "green"}}>0.04% of sales entry (Adjust Tariff Month) Rounded up (0.01->1)</span>
                                    <b> - </b> <span style={{color: "blue"}}>Excess Deposit</span><br/></p>
                            </>}
                        </Col>
                        <Col span="24">
                            <div className="card-container">


                                <Tabs defaultActiveKey="info" activeKey={this.state.currentTab} type="card"
                                      onChange={(x) => {
                                          this.setState({currentTab: x})
                                      }} tabPosition="top">
                                    <TabPane tab="Agent Information" key="info">
                                        <Table
                                            dataSource={this.props.agentList}
                                            size={"small"} bordered pagination={false}
                                            rowKey="id"
                                        >
                                            <Column title="ID" dataIndex="agent_id" key="agent_id"/>
                                            <Column title="Agent Name" dataIndex="agent_name" key="agent_name"/>
                                            <Column title="Tax ID" dataIndex="agent_id" key="agent_name"
                                                    render={(data, record) => {
                                                        return agentInformationRenderer(data, record, "taxid")
                                                    }}/>
                                            <Column title="Address" dataIndex="agent_name" key="agent_name"
                                                    render={(data, record) => {
                                                        return agentInformationRenderer(data, record, "address")
                                                    }}/>

                                            {/*<Column title="Agent Name" dataIndex="agent_name" key="agent_name"/>*/}
                                            {/*<Column title="Bank Guarantee" dataIndex="bnkg_amount" key="bnkg_amount"*/}
                                            {/*        align="right" render={this.numericFieldRenderer}/>*/}
                                        </Table>
                                    </TabPane>
                                    <TabPane tab="Bank Guarantee" key="bankguarantee">
                                        {((this.props.dip.filteredAgent + "_list") in this.props.dipSales.bankGuarantee) &&
                                            <Table
                                                dataSource={this.props.dipSales.bankGuarantee[this.props.dip.filteredAgent + "_list"]}
                                                size={"small"} bordered pagination={false}
                                            >
                                                <Column title="Airline" dataIndex="airline_id" key="airline_id"/>
                                                <Column title="Agent" dataIndex="agent_id" key="agent_id"/>
                                                <Column title="Bank Guarantee" dataIndex="bnkg_amount" key="bnkg_amount"
                                                        align="right" render={this.numericFieldRenderer}/>
                                            </Table>
                                        }
                                        {/*<table>*/}
                                        {/*    <thead>*/}
                                        {/*    <tr>*/}
                                        {/*        <th width={airlineFieldSize}>Airline</th>*/}
                                        {/*        <th width={numericFieldSize}>Bank Guarantee</th>*/}
                                        {/*    </tr>*/}
                                        {/*    </thead>*/}
                                        {/*    <tbody>*/}
                                        {/*    {(this.props.dip.filteredAgent in this.props.dipSales.agentCalculationDetailByAirline)*/}
                                        {/*        ? Object.keys(this.props.dipSales.agentCalculationDetailByAirline[this.props.dip.filteredAgent]).map(option => {*/}
                                        {/*            // console.log(this.props.dip.filteredAgent,this.props.dipSales.bankGuarantee[this.props.dip.filteredAgent],option);*/}
                                        {/*            return <tr key={this.props.dip.filteredAgent + option + "bgdetail"}>*/}
                                        {/*                <td>{option}</td>*/}
                                        {/*                <td align={"right"}>*/}
                                        {/*                    <NumberFormat*/}
                                        {/*                        value={this.props.dipSales.agentCalculationDetailByAirline[this.props.dip.filteredAgent][option].bank_guarantee.toFixed(2)}*/}
                                        {/*                        displayType={'text'}*/}
                                        {/*                        thousandSeparator={true}*/}
                                        {/*                    />*/}

                                        {/*                </td>*/}
                                        {/*            </tr>*/}
                                        {/*        })*/}
                                        {/*        : <tr>*/}
                                        {/*            <td colSpan={2}>No bank guarantee for this agent.</td>*/}
                                        {/*        </tr>*/}

                                        {/*    }*/}
                                        {/*    </tbody>*/}
                                        {/*</table>*/}
                                    </TabPane>
                                    <TabPane tab="Sales Entry" key="sales">
                                        <Select style={{width: "300px"}}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    if (option.children) {
                                                        // console.log(option.children);
                                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                }}
                                                defaultValue={"all"}
                                                value={this.props.dip.filteredAgent} onChange={(x) => {
                                            this.props.dipdataSetFilteredAgent(x)
                                        }}>
                                            <Select.Option value={"all"} key={"all"}>[SELECT AGENT TO VIEW SALES
                                                ENTRY]</Select.Option>
                                            {this.props.agentList.map(option => {
                                                return <Select.Option value={option.agent_id}
                                                                      key={option.agent_id}>{option.agent_id + " - " + option.agent_name}</Select.Option>
                                            })}
                                        </Select>
                                        {((("weird_list") in this.props.dipSales.salesEntry) && (this.props.dipSales.salesEntry.weird_list.length > 0)) && <>
                                            <br/>Entry with 99,999,999.99<br/>
                                            {this.props.dipSales.salesEntry.weird_list.map(option => {
                                                // console.log(option);
                                                return <p>agentID : {option.agent_id}, airlineID : {option.airline_id}, period_month: {option.period_month}</p>
                                            })
                                            }
                                        </>}
                                        {((this.props.dip.filteredAgent + "_deposit_list") in this.props.dipSales.salesEntry) &&
                                            <Table
                                                dataSource={this.props.dipSales.salesEntry[this.props.dip.filteredAgent + "_deposit_list"]}
                                                size={"small"} bordered pagination={false}
                                            >
                                                <Column title="Period Month" dataIndex="period_month" key="period_month"
                                                        align="center"/>
                                                <ColumnGroup title="Sales Data">
                                                    <Column title="Airline" dataIndex="airline_id" key="airline_id"/>
                                                    <Column title="Deposit" dataIndex="net_sale" key="net_sale"
                                                            align="right" render={this.numericFieldRenderer}/>
                                                    <Column title="Submit Time" dataIndex="submittime" key="submittime"
                                                            align="center"/>
                                                </ColumnGroup>
                                            </Table>
                                        }
                                    </TabPane>
                                    <TabPane tab="All agent deposit summary" key="allagentdeposit">
                                        <Row gutter={16} style={{marginBottom: "16px"}}>
                                            <Col span={24}>
                                                <Card>
                                                    <center>
                                                        <Button size={"normal"}
                                                                onClick={() => {
                                                                    this.generateDepositXlsx();
                                                                }}
                                                                style={{background: "#0000CC", color: "#FFFFFF"}}
                                                        ><DownloadOutlined/> Download this report</Button>
                                                    </center>
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card>
                                                    <Statistic
                                                        title="Current sales data used for Deposit"
                                                        value={this.props.dipSales.contractData.depositSales.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: genericThemeNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticHeaderValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginBottom: "16px"}}>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Primary Layer Deposit"
                                                        value={this.props.dipSales.contractData.primaryDepositAmount.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Primary Layer Duty"
                                                        value={this.props.dipSales.contractData.primaryDepositDuty.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Primary Layer Vat"
                                                        value={this.props.dipSales.contractData.primaryDepositTax.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card>
                                                    <Statistic
                                                        title="Net primary layer deposit"
                                                        value={this.props.dipSales.contractData.primaryDepositNet.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>

                                        </Row>
                                        <Row gutter={16} style={{marginBottom: "16px"}}>


                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Excess Layer Deposit"
                                                        value={this.props.dipSales.contractData.excessDepositAmount.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Excess Layer Duty"
                                                        value={this.props.dipSales.contractData.excessDepositDuty.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Excess Layer Vat"
                                                        value={this.props.dipSales.contractData.excessDepositTax.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card>
                                                    <Statistic
                                                        title="Net excess layer deposit"
                                                        value={this.props.dipSales.contractData.excessDepositNet.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                            <Col span={24}>
                                                <Card title="Primary Layer Detail" bordered={true}>
                                                    <Table
                                                        dataSource={this.props.dipSales.contractData.primaryLayerDeposit}
                                                        size={"small"} bordered pagination={false}
                                                        // expandedRowKeys={this.state.primaryDepositKey}
                                                        // onExpand={(expanded,record)=>{this.onTableRowExpand(expanded,record,"primaryDepositKey")}}
                                                        rowKey="id"
                                                        expandedRowRender={(record) => {
                                                            // console.log(record);
                                                            return <>
                                                                <Table
                                                                    dataSource={record.agentList}
                                                                    size={"small"} bordered pagination={false}
                                                                    rowKey="agent_id"
                                                                    summary={() => {
                                                                        return <Table.Summary fixed>
                                                                            <Table.Summary.Row style={{
                                                                                backgroundColor: "#EEE",
                                                                                color: "#F00",
                                                                                textAlign: "right"
                                                                            }}>
                                                                                <Table.Summary.Cell
                                                                                    index={0}>DIFF</Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={1}>
                                                                                    {this.numericFieldRenderer(record.depositCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={2}>
                                                                                    {this.numericFieldRenderer(record.dutyCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={3}>
                                                                                    {this.numericFieldRenderer(record.taxCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={4}>
                                                                                    {this.numericFieldRenderer(record.netCarry)}
                                                                                </Table.Summary.Cell>
                                                                            </Table.Summary.Row>
                                                                        </Table.Summary>
                                                                    }}
                                                                >
                                                                    <Column title="Name" dataIndex="agent_name"
                                                                            key="agent_name"/>
                                                                    <ColumnGroup title={"Distribution"}
                                                                                 align={"center"}>
                                                                        <Column title="Deposit" dataIndex="deposit"
                                                                                key="deposit" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Duty" dataIndex="duty"
                                                                                key="primaryDuty" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Vat" dataIndex="tax"
                                                                                key="primaryTax" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Net" dataIndex="net"
                                                                                key="primaryNet" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                    </ColumnGroup>
                                                                </Table>
                                                            </>

                                                        }}
                                                    >
                                                        <Column title="Name" dataIndex="name" key="name"/>
                                                        <Column title="Share(%)" dataIndex="primaryRate"
                                                                key="primaryRate"
                                                                render={this.numericFieldRenderer}/>
                                                        <ColumnGroup title={"Current calculation"}
                                                                     align={"center"}>
                                                            <Column title="Primary Deposit" dataIndex="deposit"
                                                                    key="deposit" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="duty"
                                                                    key="primaryDuty" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="tax"
                                                                    key="primaryTax" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="net"
                                                                    key="primaryNet" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                            <Col span={24}>
                                                <Card title="Excess Layer Detail" bordered={true}>
                                                    <Table
                                                        dataSource={this.props.dipSales.contractData.excessLayerDeposit}
                                                        size={"small"} bordered pagination={false}
                                                        rowKey="id"
                                                        expandedRowRender={(record) => {
                                                            // console.log(record);
                                                            return <>
                                                                <Table
                                                                    dataSource={record.agentList}
                                                                    size={"small"} bordered pagination={false}
                                                                    rowKey="agent_id"
                                                                    summary={() => {
                                                                        return <Table.Summary fixed>
                                                                            <Table.Summary.Row style={{
                                                                                backgroundColor: "#EEE",
                                                                                color: "#F00",
                                                                                textAlign: "right"
                                                                            }}>
                                                                                <Table.Summary.Cell
                                                                                    index={0}>DIFF</Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={1}>
                                                                                    {this.numericFieldRenderer(record.depositCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={2}>
                                                                                    {this.numericFieldRenderer(record.dutyCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={3}>
                                                                                    {this.numericFieldRenderer(record.taxCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={4}>
                                                                                    {this.numericFieldRenderer(record.netCarry)}
                                                                                </Table.Summary.Cell>
                                                                            </Table.Summary.Row>
                                                                        </Table.Summary>
                                                                    }}
                                                                >
                                                                    <Column title="Name" dataIndex="agent_name"
                                                                            key="agent_name"/>
                                                                    <ColumnGroup title={"Distribution"}
                                                                                 align={"center"}>
                                                                        <Column title="Deposit" dataIndex="deposit"
                                                                                key="deposit" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Duty" dataIndex="duty"
                                                                                key="primaryDuty" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Vat" dataIndex="tax"
                                                                                key="primaryTax" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Net" dataIndex="net"
                                                                                key="primaryNet" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                    </ColumnGroup>
                                                                </Table>
                                                            </>

                                                        }}
                                                    >
                                                        <Column title="Name" dataIndex="name" key="name"/>
                                                        <Column title="Share(%)" dataIndex="excessRate" key="excessRate"
                                                                render={this.numericFieldRenderer}/>
                                                        <ColumnGroup title={"Current calculation"}
                                                                     align={"center"}>
                                                            <Column title="Excess Deposit" dataIndex="deposit"
                                                                    key="deposit" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="duty"
                                                                    key="excessDuty" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="tax"
                                                                    key="excessTax" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="net"
                                                                    key="excessNet" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {(this.props.dipSales.allAgentDisplayArray) &&
                                            <Card
                                                title="ข้อมูลราย Agent (อากร/ภาษี/สุทธิ เป็นค่าประมาณหลังการปัดเศษทศนิยมอากรในแต่ละรายให้เต็มบาท)"
                                                bordered={true}>
                                                <Table
                                                    dataSource={this.props.dipSales.allAgentDisplayArray}
                                                    size={"small"} bordered pagination={false}
                                                    rowKeys="agent_id"
                                                    // expandable={{
                                                    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.agent_id}</p>,
                                                    //     rowExpandable: record => record.agent_id !== 'Not Expandable',
                                                    // }}
                                                    summary={() => {
                                                        return <Table.Summary fixed>
                                                            <Table.Summary.Row
                                                                style={{backgroundColor: "#EEE", textAlign: "right"}}>
                                                                <Table.Summary.Cell index={0}>SUM</Table.Summary.Cell>
                                                                <Table.Summary.Cell
                                                                    index={1}>Summary</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.sales_deposit)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={3}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_deposit)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={4}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_deposit_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={5}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_deposit_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={6}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_deposit_net)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={7}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_deposit)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={8}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_deposit_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={9}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_deposit_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={10}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_deposit_net)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                            {/*<Table.Summary.Row
                                                                style={{backgroundColor: "#AAA", textAlign: "right"}}>
                                                                <Table.Summary.Cell index={0}>MAIN</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1}>Main
                                                                    Policy</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.depositSales)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={3}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryDepositAmount)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={4}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryDepositDuty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={5}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryDepositTax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={6}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryDepositNet)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={7}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessDepositAmount)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={8}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessDepositDuty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={9}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessDepositTax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={10}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessDepositNet)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                            <Table.Summary.Row style={{
                                                                backgroundColor: "#EEE",
                                                                color: "#F00",
                                                                textAlign: "right"
                                                            }}>
                                                                <Table.Summary.Cell index={0}>DIFF</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1}>Top level diff is just for
                                                                    guideline</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.sales_deposit)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={3}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_deposit)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={4}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_deposit_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={5}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_deposit_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={6}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_deposit_net)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={7}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_deposit)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={8}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_deposit_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={9}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_deposit_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={10}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_deposit_net)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>*/}
                                                        </Table.Summary>
                                                    }}
                                                >
                                                    <ColumnGroup title="Agent Data">
                                                        <Column title="ID" dataIndex="agent_id" key="agent_id"
                                                                align="center"/>
                                                        <Column title="Name" dataIndex="agent_name" key="agent_name"
                                                                align="center"/>
                                                    </ColumnGroup>
                                                    <Column title="Total Sales" dataIndex="sales_deposit"
                                                            key="sales_deposit"
                                                            align="right" render={this.numericFieldRenderer}/>
                                                    <ColumnGroup title="Primary Layer">
                                                        <ColumnGroup title="Calculation">
                                                            <Column title="Deposit" dataIndex="primary_deposit"
                                                                    key="primary_deposit"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="primary_deposit_duty"
                                                                    key="primary_deposit_duty"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="primary_deposit_tax"
                                                                    key="primary_deposit_tax"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="primary_deposit_net"
                                                                    key="primary_deposit_net"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </ColumnGroup>
                                                    <ColumnGroup title="Excess Layer">
                                                        <ColumnGroup title="Calculation">
                                                            <Column title="Excess" dataIndex="excess_deposit"
                                                                    key="excess_deposit"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="excess_deposit_duty"
                                                                    key="excess_deposit_duty"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="excess_deposit_tax"
                                                                    key="excess_deposit_tax"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="excess_deposit_net"
                                                                    key="excess_deposit_net"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </ColumnGroup>
                                                </Table></Card>
                                        }
                                    </TabPane>
                                    <TabPane tab="All agent adjust summary" key="allagentadjust">
                                        <Row gutter={16} style={{marginBottom: "16px"}}>
                                            <Col span={24}>
                                                <Card>
                                                    <center>
                                                        <Button size={"normal"}
                                                                onClick={() => {
                                                                    this.generateAdjustXlsx();
                                                                }}
                                                                style={{background: "#0000CC", color: "#FFFFFF"}}
                                                        ><DownloadOutlined/> Download this report</Button>
                                                    </center>
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card>
                                                    <Statistic
                                                        title="Current sales data used for adjust"
                                                        value={this.props.dipSales.contractData.adjustSales.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: genericThemeNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticHeaderValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginBottom: "16px"}}>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Raw Primary Layer Adjust"
                                                        value={this.props.dipSales.contractData.primaryAdjustAmount.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Total deposit received"
                                                        value={this.props.dipSales.contractData.primaryDepositRecord.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Primary (Adjust - Deposit)"
                                                        value={this.props.dipSales.contractData.primaryRealAdjust.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Primary Layer Duty"
                                                        value={this.props.dipSales.contractData.primaryAdjustDuty.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Primary Layer Vat"
                                                        value={this.props.dipSales.contractData.primaryAdjustTax.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Net primary adjust"
                                                        value={this.props.dipSales.contractData.primaryAdjustNet.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: primaryLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} style={{marginBottom: "16px"}}>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Raw Excess Layer Adjust"
                                                        value={this.props.dipSales.contractData.excessAdjustAmount.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Total deposit received"
                                                        value={this.props.dipSales.contractData.excessDepositRecord.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Excess (Adjust - Deposit)"
                                                        value={this.props.dipSales.contractData.excessRealAdjust.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Excess Layer Duty"
                                                        value={this.props.dipSales.contractData.excessAdjustDuty.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Excess Layer Vat"
                                                        value={this.props.dipSales.contractData.excessAdjustTax.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card>
                                                    <Statistic
                                                        title="Net excess layer adjust"
                                                        value={this.props.dipSales.contractData.excessAdjustNet.toFixed(2)}
                                                        precision={2}
                                                        valueStyle={{
                                                            color: excessLayerNumericColor,
                                                            textAlign: "right",
                                                            fontSize: statisticSubValueFontSize
                                                        }}
                                                        suffix="B"
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                            <Col span={24}>
                                                <Card title="Primary Layer Detail" bordered={true}>
                                                    <Table
                                                        dataSource={this.props.dipSales.contractData.primaryLayerAdjust}
                                                        size={"small"} bordered pagination={false}
                                                        // expandedRowKeys={this.state.primaryAdjustKey}
                                                        // onExpand={(expanded,record)=>{this.onTableRowExpand(expanded,record,"primaryAdjustKey")}}
                                                        rowKey="id"
                                                        expandedRowRender={(record) => {
                                                            // console.log(record);
                                                            return <>
                                                                <Table
                                                                    dataSource={record.agentList}
                                                                    size={"small"} bordered pagination={false}
                                                                    rowKey="agent_id"
                                                                    summary={() => {
                                                                        return <Table.Summary fixed>
                                                                            <Table.Summary.Row style={{
                                                                                backgroundColor: "#EEE",
                                                                                color: "#F00",
                                                                                textAlign: "right"
                                                                            }}>
                                                                                <Table.Summary.Cell
                                                                                    index={0}>DIFF</Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={1}>
                                                                                    {this.numericFieldRenderer(record.adjustCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={2}>
                                                                                    {this.numericFieldRenderer(record.dutyCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={3}>
                                                                                    {this.numericFieldRenderer(record.taxCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={4}>
                                                                                    {this.numericFieldRenderer(record.netCarry)}
                                                                                </Table.Summary.Cell>
                                                                            </Table.Summary.Row>
                                                                        </Table.Summary>
                                                                    }}
                                                                >
                                                                    <Column title="Name" dataIndex="agent_name"
                                                                            key="agent_name"/>
                                                                    <ColumnGroup title={"Distribution"}
                                                                                 align={"center"}>
                                                                        <Column title="adjust" dataIndex="adjust"
                                                                                key="adjust" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Duty" dataIndex="duty"
                                                                                key="primaryDuty" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Vat" dataIndex="tax"
                                                                                key="primaryTax" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Net" dataIndex="net"
                                                                                key="primaryNet" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                    </ColumnGroup>
                                                                </Table>
                                                            </>

                                                        }}
                                                    >
                                                        <Column title="Name" dataIndex="name" key="name"/>
                                                        <Column title="Share(%)" dataIndex="primaryRate"
                                                                key="primaryRate"
                                                                render={this.numericFieldRenderer}/>
                                                        <ColumnGroup title={"Current calculation"}
                                                                     align={"center"}>
                                                            <Column title="Primary Adjust" dataIndex="adjust"
                                                                    key="adjust" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="duty"
                                                                    key="primaryDuty" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="tax"
                                                                    key="primaryTax" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="net"
                                                                    key="primaryNet" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                            <Col span={24}>
                                                <Card title="Excess Layer Detail" bordered={true}>
                                                    <Table
                                                        dataSource={this.props.dipSales.contractData.excessLayerAdjust}
                                                        size={"small"} bordered pagination={false}
                                                        rowKey="id"
                                                        expandedRowRender={(record) => {
                                                            // console.log(record);
                                                            return <>
                                                                <Table
                                                                    dataSource={record.agentList}
                                                                    size={"small"} bordered pagination={false}
                                                                    rowKey="agent_id"
                                                                    summary={() => {
                                                                        return <Table.Summary fixed>
                                                                            <Table.Summary.Row style={{
                                                                                backgroundColor: "#EEE",
                                                                                color: "#F00",
                                                                                textAlign: "right"
                                                                            }}>
                                                                                <Table.Summary.Cell
                                                                                    index={0}>DIFF</Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={1}>
                                                                                    {this.numericFieldRenderer(record.adjustCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={2}>
                                                                                    {this.numericFieldRenderer(record.dutyCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={3}>
                                                                                    {this.numericFieldRenderer(record.taxCarry)}
                                                                                </Table.Summary.Cell>
                                                                                <Table.Summary.Cell index={4}>
                                                                                    {this.numericFieldRenderer(record.netCarry)}
                                                                                </Table.Summary.Cell>
                                                                            </Table.Summary.Row>
                                                                        </Table.Summary>
                                                                    }}
                                                                >
                                                                    <Column title="Name" dataIndex="agent_name"
                                                                            key="agent_name"/>
                                                                    <ColumnGroup title={"Distribution"}
                                                                                 align={"center"}>
                                                                        <Column title="adjust" dataIndex="adjust"
                                                                                key="adjust" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Duty" dataIndex="duty"
                                                                                key="primaryDuty" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Vat" dataIndex="tax"
                                                                                key="primaryTax" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                        <Column title="Net" dataIndex="net"
                                                                                key="primaryNet" align="right"
                                                                                render={this.numericFieldRenderer}/>
                                                                    </ColumnGroup>
                                                                </Table>
                                                            </>

                                                        }}
                                                    >
                                                        <Column title="Name" dataIndex="name" key="name"/>
                                                        <Column title="Share(%)" dataIndex="excessRate" key="excessRate"
                                                                render={this.numericFieldRenderer}/>
                                                        <ColumnGroup title={"Current calculation"}
                                                                     align={"center"}>
                                                            <Column title="Excess Adjust" dataIndex="adjust"
                                                                    key="adjust" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="duty"
                                                                    key="excessDuty" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="tax"
                                                                    key="excessTax" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="net"
                                                                    key="excessNet" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {(this.props.dipSales.allAgentDisplayArray) &&
                                            <Card
                                                title="ข้อมูลราย Agent (อากร/ภาษี/สุทธิ เป็นค่าประมาณหลังการปัดเศษทศนิยมอากรในแต่ละรายให้เต็มบาท)"
                                                bordered={true}>
                                                <Table
                                                    dataSource={this.props.dipSales.allAgentDisplayArray}
                                                    size={"small"} bordered pagination={false}
                                                    rowKeys="agent_id"
                                                    // expandable={{
                                                    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.agent_id}</p>,
                                                    //     rowExpandable: record => record.agent_id !== 'Not Expandable',
                                                    // }}
                                                    summary={() => {
                                                        return <Table.Summary fixed>
                                                            <Table.Summary.Row
                                                                style={{backgroundColor: "#EEE", textAlign: "right"}}>
                                                                <Table.Summary.Cell index={0}>SUM</Table.Summary.Cell>
                                                                <Table.Summary.Cell
                                                                    index={1}>Summary</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.sales_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={3}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_real_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={4}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_deposit_record)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={5}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={6}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_adjust_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={7}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_adjust_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={8}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.primary_adjust_net)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={9}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_real_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={10}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_deposit_record)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={11}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={12}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_adjust_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={13}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_adjust_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={14}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.allAgentDisplaySummary.excess_adjust_net)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                            {/*<Table.Summary.Row
                                                                style={{backgroundColor: "#AAA", textAlign: "right"}}>
                                                                <Table.Summary.Cell index={0}>MAIN</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1}>Main
                                                                    Policy</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.adjustSales)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={3}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryAdjustAmount)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={4}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryAdjustDuty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={5}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryAdjustTax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={6}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.primaryAdjustNet)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={7}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessAdjustAmount)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={8}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessAdjustDuty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={9}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessAdjustTax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={10}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractData.excessAdjustNet)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                            <Table.Summary.Row style={{
                                                                backgroundColor: "#EEE",
                                                                color: "#F00",
                                                                textAlign: "right"
                                                            }}>
                                                                <Table.Summary.Cell index={0}>DIFF</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1}>Top level diff is just for
                                                                    guideline</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.sales_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={3}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={4}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_adjust_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={5}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_adjust_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={6}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.primary_adjust_net)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={7}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_adjust)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={8}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_adjust_duty)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={9}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_adjust_tax)}
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell index={10}>
                                                                    {this.numericFieldRenderer(this.props.dipSales.contractDataDiff.excess_adjust_net)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>*/}
                                                        </Table.Summary>
                                                    }}
                                                >
                                                    <ColumnGroup title="Agent Data">
                                                        <Column title="ID" dataIndex="agent_id" key="agent_id"
                                                                align="center"/>
                                                        <Column title="Name" dataIndex="agent_name" key="agent_name"
                                                                align="center"/>
                                                    </ColumnGroup>
                                                    <Column title="Total Sales" dataIndex="sales_adjust"
                                                            key="sales_adjust"
                                                            align="right" render={this.numericFieldRenderer}/>
                                                    <ColumnGroup title="Primary Layer">
                                                        <Column title="PreDep" dataIndex="primary_adjust"
                                                                key="primary_adjust"
                                                                align="right" render={this.numericFieldRenderer}/>
                                                        <Column title="Deposit" dataIndex="primary_deposit_record"
                                                                key="primary_deposit_record"
                                                                align="right" render={this.numericFieldRenderer}/>
                                                        <ColumnGroup title="Calculation">
                                                            <Column title="Adjust" dataIndex="primary_real_adjust"
                                                                    key="primary_real_adjust"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="primary_adjust_duty"
                                                                    key="primary_adjust_duty"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="primary_adjust_tax"
                                                                    key="primary_adjust_tax"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="primary_adjust_net"
                                                                    key="primary_adjust_net"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </ColumnGroup>
                                                    <ColumnGroup title="Excess Layer">
                                                        <Column title="PreDep" dataIndex="excess_adjust"
                                                                key="excess_adjust"
                                                                align="right" render={this.numericFieldRenderer}/>
                                                        <Column title="Deposit" dataIndex="excess_deposit_record"
                                                                key="excess_deposit_record"
                                                                align="right" render={this.numericFieldRenderer}/>
                                                        <ColumnGroup title="Calculation">
                                                            <Column title="Adjust" dataIndex="excess_real_adjust"
                                                                    key="excess_real_adjust"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Duty" dataIndex="excess_adjust_duty"
                                                                    key="excess_adjust_duty"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Vat" dataIndex="excess_adjust_tax"
                                                                    key="excess_adjust_tax"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                            <Column title="Net" dataIndex="excess_adjust_net"
                                                                    key="excess_adjust_net"
                                                                    align="right" render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </ColumnGroup>
                                                </Table></Card>
                                        }
                                    </TabPane>
                                    {/*<TabPane tab="All agent adjust summary" key="allagentadjust">*/}
                                    {/*    <Row gutter={16} style={{marginBottom: "16px"}}>*/}
                                    {/*        <Col span={24}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Current sales data used for Adjust"*/}
                                    {/*                    value={this.props.dipSales.contractData.adjustSales.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: genericThemeNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticHeaderValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*    <Row gutter={16} style={{marginBottom: "16px"}}>*/}
                                    {/*        <Col span={12}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Current calculation of primary layer adjust"*/}
                                    {/*                    value={this.props.dipSales.contractData.primaryAdjustNet.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: primaryLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticHeaderValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col span={12}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Current calculation of excess layer adjust"*/}
                                    {/*                    value={this.props.dipSales.contractData.excessAdjustNet.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: excessLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticHeaderValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*    <Row gutter={16} style={{marginBottom: "16px"}}>*/}
                                    {/*        <Col span={4}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Primary Layer Adjust"*/}
                                    {/*                    value={this.props.dipSales.contractData.primaryAdjustAmount.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: primaryLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticSubValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col span={4}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Primary Layer Duty"*/}
                                    {/*                    value={this.props.dipSales.contractData.primaryAdjustDuty.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: primaryLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticSubValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col span={4}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Primary Layer Vat"*/}
                                    {/*                    value={this.props.dipSales.contractData.primaryAdjustTax.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: primaryLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticSubValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col span={4}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Excess Layer Adjust"*/}
                                    {/*                    value={this.props.dipSales.contractData.excessAdjustAmount.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: excessLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticSubValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col span={4}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Excess Layer Duty"*/}
                                    {/*                    value={this.props.dipSales.contractData.excessAdjustDuty.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: excessLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticSubValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col span={4}>*/}
                                    {/*            <Card>*/}
                                    {/*                <Statistic*/}
                                    {/*                    title="Excess Layer Vat"*/}
                                    {/*                    value={this.props.dipSales.contractData.excessAdjustTax.toFixed(2)}*/}
                                    {/*                    precision={2}*/}
                                    {/*                    valueStyle={{*/}
                                    {/*                        color: excessLayerNumericColor,*/}
                                    {/*                        textAlign: "right",*/}
                                    {/*                        fontSize: statisticSubValueFontSize*/}
                                    {/*                    }}*/}
                                    {/*                    suffix="B"*/}
                                    {/*                />*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}

                                    {/*    </Row>*/}
                                    {/*    <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>*/}
                                    {/*        <Col span={24}>*/}
                                    {/*            <Card title="Primary Layer Detail" bordered={true}>*/}
                                    {/*                <Table*/}
                                    {/*                    dataSource={this.props.dipSales.contractData.primaryLayerAdjust}*/}
                                    {/*                    size={"small"} bordered pagination={false}*/}
                                    {/*                >*/}
                                    {/*                    <Column title="Name" dataIndex="name" key="name"/>*/}
                                    {/*                    <Column title="Share(%)" dataIndex="primaryRate"*/}
                                    {/*                            key="primaryRate"*/}
                                    {/*                            render={this.numericFieldRenderer}/>*/}
                                    {/*                    <ColumnGroup title={"Current calculation"}*/}
                                    {/*                                 align={"center"}>*/}
                                    {/*                        <Column title="Adjust" dataIndex="adjust"*/}
                                    {/*                                key="adjust" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                        <Column title="Duty" dataIndex="duty"*/}
                                    {/*                                key="primaryDuty" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                        <Column title="Vat" dataIndex="tax"*/}
                                    {/*                                key="primaryTax" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                        <Column title="Net" dataIndex="net"*/}
                                    {/*                                key="primaryNet" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                    </ColumnGroup>*/}
                                    {/*                </Table>*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*    <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>*/}
                                    {/*        <Col span={24}>*/}
                                    {/*            <Card title="Excess Layer Detail" bordered={true}>*/}
                                    {/*                <Table*/}
                                    {/*                    dataSource={this.props.dipSales.contractData.excessLayerAdjust}*/}
                                    {/*                    size={"small"} bordered pagination={false}*/}
                                    {/*                >*/}
                                    {/*                    <Column title="Name" dataIndex="name" key="name"/>*/}
                                    {/*                    <Column title="Share(%)" dataIndex="excessRate" key="excessRate"*/}
                                    {/*                            render={this.numericFieldRenderer}/>*/}
                                    {/*                    <ColumnGroup title={"Current calculation"}*/}
                                    {/*                                 align={"center"}>*/}
                                    {/*                        <Column title="Adjust" dataIndex="adjust"*/}
                                    {/*                                key="adjust" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                        <Column title="Duty" dataIndex="duty"*/}
                                    {/*                                key="excessDuty" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                        <Column title="Vat" dataIndex="tax"*/}
                                    {/*                                key="excessTax" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                        <Column title="Net" dataIndex="net"*/}
                                    {/*                                key="excessNet" align="right"*/}
                                    {/*                                render={this.numericFieldRenderer}/>*/}
                                    {/*                    </ColumnGroup>*/}
                                    {/*                </Table>*/}
                                    {/*            </Card>*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*</TabPane>*/}
                                    {/*{false && <>
                                        <TabPane tab="Deposit Calculation" key="deposit">
                                            <Row gutter={16} style={{marginBottom: "16px"}}>
                                                <Col span={24}>
                                                    <Select style={{width: "300px"}}
                                                            showSearch={true}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => {
                                                                if (option.children) {
                                                                    // console.log(option.children);
                                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            }}
                                                            defaultValue={"all"}
                                                            value={this.props.dip.filteredAgent} onChange={(x) => {
                                                        this.props.dipdataSetFilteredAgent(x)
                                                    }}>
                                                        <Select.Option value={"all"} key={"all"}>[FILTERED BY ALL
                                                            AGENT]</Select.Option>
                                                        {this.props.agentList.map(option => {
                                                            return <Select.Option value={option.agent_id}
                                                                                  key={option.agent_id}>{option.agent_id + " - " + option.agent_name}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} style={{marginBottom: "16px"}}>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Sales used for Deposit"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].sales_deposit.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{color: '#3f8600', textAlign: "right"}}
                                                            suffix="B"
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Primary Layer Deposit (W/O ROUNDING)"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].primary_deposit.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{color: '#3f8600', textAlign: "right"}}
                                                            suffix="B"

                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Excess Layer Deposit (W/O ROUNDING)"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].excess_deposit.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{color: '#3f8600', textAlign: "right"}}
                                                            suffix="B"
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {(this.props.dip.filteredAgent in this.props.dipSales.agentCalculationSummaryByAgent) &&
                                                <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                                    <Col span={24}>
                                                        <Card title="Primary Layer Detail" bordered={true}>
                                                            <Table
                                                                dataSource={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].stakeholderArray.primary}
                                                                size={"small"} bordered pagination={false}
                                                            >
                                                                <Column title="Name" dataIndex="name" key="name"/>
                                                                <ColumnGroup title={"Using calculation"}
                                                                             align={"center"}>
                                                                    <Column title="Deposit" dataIndex="deposit"
                                                                            key="deposit" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                                <ColumnGroup title={"Actual/saved amount"}
                                                                             align={"center"}>
                                                                    <Column title="Deposit(Actual)" dataIndex="deposit"
                                                                            key="depositActual" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Duty" dataIndex="depositDuty"
                                                                            key="depositDuty" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Vat" dataIndex="depositVat"
                                                                            key="depositVat" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Net" dataIndex="depositNet"
                                                                            key="depositNet" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Excess Layer Detail" bordered={true}>
                                                            <Table
                                                                dataSource={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].stakeholderArray.excess}
                                                                size={"small"} bordered pagination={false}
                                                            >
                                                                <Column title="Name" dataIndex="name" key="name"/>
                                                                <ColumnGroup title={"Using calculation amount"}
                                                                             align={"center"}>
                                                                    <Column title="Deposit" dataIndex="deposit"
                                                                            key="deposit" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                                <ColumnGroup title={"Actual/saved amount"}
                                                                             align={"center"}>
                                                                    <Column title="Deposit" dataIndex="deposit"
                                                                            key="depositActual" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Duty" dataIndex="depositDuty"
                                                                            key="depositDuty" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Vat" dataIndex="depositVat"
                                                                            key="depositVat" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Net" dataIndex="depositNet"
                                                                            key="depositNet" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            }
                                            {(this.props.dip.filteredAgent in this.props.dipSales.agentCalculationSummaryByAgent) &&
                                                <Card title="Information for calculation" bordered={true}>
                                                    <Table
                                                        dataSource={this.props.dipSales.agentCalculationDetailByAirlineArray[this.props.dip.filteredAgent]}
                                                        size={"small"} bordered pagination={false}
                                                    >
                                                        <Column title="Airline" dataIndex="airline_id"
                                                                key="airline_id"/>
                                                        <ColumnGroup title={"Deposit Period"} align={"center"}>
                                                            <Column title="Sales Data" dataIndex="sales_deposit"
                                                                    key="sales_deposit" align="right"
                                                                    render={this.numericFieldRenderer}/>
                                                        </ColumnGroup>
                                                    </Table>
                                                </Card>
                                            }
                                        </TabPane>
                                        <TabPane tab="Adjust Calculation" key="adjust">
                                            <Row gutter={16} style={{marginBottom: "16px"}}>
                                                <Col span={24}>
                                                    <Select style={{width: "300px"}}
                                                            showSearch={true}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => {
                                                                if (option.children) {
                                                                    // console.log(option.children);
                                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            }}
                                                            defaultValue={"all"}
                                                            value={this.props.dip.filteredAgent} onChange={(x) => {
                                                        this.props.dipdataSetFilteredAgent(x)
                                                    }}>
                                                        <Select.Option value={"all"} key={"all"}>[FILTERED BY ALL
                                                            AGENT]</Select.Option>
                                                        {this.props.agentList.map(option => {
                                                            return <Select.Option value={option.agent_id}
                                                                                  key={option.agent_id}>{option.agent_id + " - " + option.agent_name}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Sales used for Deposit"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].sales_deposit.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{color: '#3f8600', textAlign: "right"}}
                                                            suffix="B"
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Primary Layer Deposit (W/O ROUNDING)"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].primary_deposit.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{color: '#3f8600', textAlign: "right"}}
                                                            suffix="B"

                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Excess Layer Deposit (W/O ROUNDING)"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].excess_deposit.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{color: '#3f8600', textAlign: "right"}}
                                                            suffix="B"
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Sales used for Adjust"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].sales_adjust.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{
                                                                color: this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].sales_adjust.isPositive() ? '#3f8600' : "#861400",
                                                                textAlign: "right"
                                                            }}
                                                            suffix="B"
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Primary Layer Adjust (WITH B/G, WITHOUT DEPOSIT DEDUCT)"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].primary_adjust.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{
                                                                color: this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].primary_adjust.isPositive() ? '#3f8600' : "#861400",
                                                                textAlign: "right"
                                                            }}
                                                            suffix="B"

                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card>
                                                        <Statistic
                                                            title="Excess Layer Adjust (WITH B/G, WITHOUT DEPOSIT DEDUCT)"
                                                            value={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].excess_adjust.toFixed(2)}
                                                            precision={2}
                                                            valueStyle={{
                                                                color: this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].excess_adjust.isPositive() ? '#3f8600' : "#861400",
                                                                textAlign: "right"
                                                            }}
                                                            suffix="B"
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {(this.props.dip.filteredAgent in this.props.dipSales.agentCalculationSummaryByAgent) &&
                                                <Row gutter={16} style={{marginTop: "16px", marginBottom: "16px"}}>
                                                    <Col span={24}>
                                                        <Card title="Primary Layer Detail" bordered={true}>
                                                            <Table
                                                                dataSource={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].stakeholderArray.primary}
                                                                size={"small"} bordered pagination={false}
                                                            >
                                                                <Column title="Name" dataIndex="name" key="name"/>
                                                                <ColumnGroup title="Calculated deposit"
                                                                             align="center">
                                                                    <Column title="Adjust" dataIndex="adjust"
                                                                            key="adjust" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                                <ColumnGroup title="Using actual deposit"
                                                                             align="center">
                                                                    <Column title="Adjust" dataIndex="adjust"
                                                                            key="adjustActual" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Duty" dataIndex="adjustDuty"
                                                                            key="adjustDuty" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Vat" dataIndex="adjustVat"
                                                                            key="adjustVat" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Net" dataIndex="adjustNet"
                                                                            key="adjustNet" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Excess Layer Detail" bordered={true}>
                                                            <Table
                                                                dataSource={this.props.dipSales.agentCalculationSummaryByAgent[this.props.dip.filteredAgent].stakeholderArray.excess}
                                                                size={"small"} bordered pagination={false}
                                                            >
                                                                <Column title="Name" dataIndex="name" key="name"/>
                                                                <ColumnGroup title="Calculated deposit"
                                                                             align="center">
                                                                    <Column title="Adjust" dataIndex="adjust"
                                                                            key="adjust" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                                <ColumnGroup title="Using actual deposit"
                                                                             align="center">
                                                                    <Column title="Adjust" dataIndex="adjust"
                                                                            key="adjustActual" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Duty" dataIndex="adjustDuty"
                                                                            key="adjustDuty" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Vat" dataIndex="adjustVat"
                                                                            key="adjustVat" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="Net" dataIndex="adjustNet"
                                                                            key="adjustNet" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            }

                                            {(this.props.dip.filteredAgent in this.props.dipSales.agentCalculationSummaryByAgent) &&
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Card title="Information for calculation" bordered={true}>
                                                            <Table
                                                                dataSource={this.props.dipSales.agentCalculationDetailByAirlineArray[this.props.dip.filteredAgent]}
                                                                size={"small"} bordered pagination={false}
                                                            >
                                                                <Column title="Airline" dataIndex="airline_id"
                                                                        key="airline_id"/>
                                                                <ColumnGroup title={"Deposit Period"} align={"center"}>
                                                                    <Column title="Sales Data" dataIndex="sales_deposit"
                                                                            key="sales_deposit" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                                <ColumnGroup title="Adjust Period" align={"center"}>
                                                                    <Column title="Sales Data" dataIndex="sales_adjust"
                                                                            key="sales_adjust"
                                                                            align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="B/G" dataIndex="bank_guarantee"
                                                                            key="bank_guarantee"
                                                                            align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                    <Column title="B/G Disc"
                                                                            dataIndex="bank_guarantee_discount"
                                                                            key="bank_guarantee_discount" align="right"
                                                                            render={this.numericFieldRenderer}/>
                                                                </ColumnGroup>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            }

                                        </TabPane>
                                    </>}*/}
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Form>


            </Content>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        auth: state.Auth,
        dip: state.DipData,
        periodGroup: dipPeriodGroup(state.DipData),
        agentList: dipAgentList(state.DipData),
        dipSales: dipSales(state.DipData)
    }
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
// export default connect(mapStateToProps, {})(WrappedFormWIthSubmissionButton);
export default connect(mapStateToProps, {...baseDataActionFn, ...dipDataActionFn})(DipReport);
//export default ;
