import React, {Component} from 'react';
import {
    Col, Row, Icon, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, Form, Breadcrumb, Layout,
    Button, InputNumber, Cascader, AutoComplete
} from 'antd';
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import Helmet from "react-helmet";
import 'react-quill/dist/quill.snow.css';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import update from 'immutability-helper';
// import Reaptcha from 'reaptcha';

import {authenticatorActionFn} from "../../redux/auth/actions";
import ReCAPTCHA from "react-google-recaptcha";
import {recaptchaSitekey, recaptchaTestkey} from "../../constants";
import {permissionParser, permissionChecker, currentUserPermission} from "../../utility/permissionHelper";
import * as Constant from "../../constants"

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const recaptchaRef = React.createRef();
const {Header, Content, Footer} = Layout;
const moment = Moment;
const {Option} = Select;

let todayMoment = new moment();

let recaptchaInstance;
const resetRecaptcha = () => {
    recaptchaInstance.reset();
};

class EmbedLoginForm extends Component {
    state = {
        mode: "view",
        htmlData: "",
        signin: {
            recaptcha: "",
            system: Constant.defaultModule,
            username: "",
            password: "",
        },
        enableAdminSelection: "false",
    };

    loginForm = React.createRef();

    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });


    componentDidMount() {
        // console.log(this.formData);
        this.props.checkAuthorization();
    }


    render() {

        const reCAPTCHAHandle = (value) => {
            this.loginForm.current.setFieldsValue({captcha: value});
            // this.setState({signin: {...this.state.signin, recaptcha: value}})
            // return value
        }

        //
        // const onResolved = () => {
        //     let response = this.recaptchaRef.current.callbacks.getResponse();
        //
        //     alert(
        //         this.props.name
        //         + ': Recaptcha resolved with response: '
        //         + response
        //     );
        //
        //     this.setState({signin: update(this.state.signin, {recaptcha: response})});
        // }
        // const onRestart = () => {
        //     this.setState({signin: update(this.state.signin, {recaptcha: ""})});
        // }

        const submitForm = () => {
            // console.log("Logging in",this.loginForm.current.getFieldsValue())
            this.props.login(this.loginForm.current.getFieldsValue());

            // console.log(this.state.signin);
            // console.log(recaptchaRef);
            // console.log(recaptchaRef.current);
            // let token = recaptchaRef.current.callbacks.execute();
            // token.then((result)=>{
            //     console.log({
            //         ...this.state.signin,
            //         token:result
            //     })
            // })
        }


        let currentLocalUser = this.props.auth;
        let currentPermission = permissionParser(currentLocalUser);
        let isLogin = permissionChecker(currentPermission, ['LOGIN']) && this.props.auth.authenticated

        // console.log(this.props.auth,isLogin,permissionChecker(currentPermission, ['LOGIN']),this.props.auth.authenticated)
        // console.log(isLogin);
        return (
            <div style={{
                backgroundColor: (this.state.enableAdminSelection === true) ? "rgba(255,200,200,0.4)" : "rgba(255,255,255,0.2)",
                textAlign: "center", color: "white",
                margin: "15px 15px 15px 15px",
                padding: "20px 15px 15px 15px",
                borderRadius: "8px",
            }}>
                {isLogin &&
                    <>
                        Logged in as <br/>
                        <b>User :</b> {currentLocalUser.user.username}<br/>
                        <b>Email :</b> {currentLocalUser.user.email}<br/>
                        <b>System :</b> {currentLocalUser.system}<br/>
                        <b>Allowed :</b> {currentPermission.join(", ")}<br/><br/>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button"
                                    onClick={() => {
                                        this.props.logout();
                                    }}
                            >
                                ออกจากระบบ
                            </Button>
                        </Form.Item>
                    </>
                }
                {!isLogin &&
                    <>
                        <Form
                            name="normal_login"
                            className="login-form"
                            ref={this.loginForm}
                            initialValues={{remember: true, system: Constant.defaultModule}}
                            onValuesChange={(x, y) => {
                                if (x.username) {
                                    if (x.username === "admin") {
                                        this.loginForm.current.setFieldsValue({system: "admin"})
                                    }
                                }
                                // console.log(x,y)
                            }}
                            // onFinish={onFinish}
                        >
                            <Form.Item
                                name="system"
                                rules={[{required: true, message: 'Please select service!', initialValue: "DCB"}]}
                            >
                                {/*<Select*/}
                                <div style={{
                                    backgroundColor: "rgba(255,255,255,0.4)",
                                    textAlign: "center", color: "white",
                                    margin: "5px 5px 5px 5px",
                                    padding: "10px 10px 10px 10px",
                                    borderRadius: "3px",
                                }}>
                                    Please select service.
                                    <Radio.Group
                                        value={this.state.signin.system}
                                        style={{textAlign: "left",color:"white"}}
                                        onChange={(x) => {
                                            // console.log(this.state.signin)
                                            // console.log(update(this.state.signin, {system: {$set: x}}));
                                            console.log(x);
                                            this.setState({signin: update(this.state.signin, {system: {$set: x.target.value}})})
                                        }}

                                        placeholder={<>Please select
                                            function</>}>
                                        {(Constant.enabledModule.indexOf("DCB") >= 0) &&
                                            <Radio key={"DCB"} value={"DCB"} style={{textAlign: "left",color:"white"}}>(DCB)
                                                Deposit for Customs Broker</Radio>
                                        }
                                        {(Constant.enabledModule.indexOf("CDI") >= 0) &&
                                            <Radio key={"CDI"} value={"CDI"} style={{textAlign: "left",color:"white"}}>(CDI)
                                                Container Deposit Insurance</Radio>
                                        }
                                        {(Constant.enabledModule.indexOf("DIP") >= 0) &&
                                            <Radio key={"DIP"} value={"DIP"} style={{textAlign: "left",color:"white"}}>(DIP)
                                                Default Insurance Program
                                            </Radio>
                                        }
                                        {(this.state.enableAdminSelection === true) &&
                                            <Radio key={"admin"} value={"admin"} style={{textAlign: "left",color:"red"}}>(ADM)
                                                Internal Account
                                            </Radio>}
                                    </Radio.Group>
                                </div>
                            </Form.Item>
                            <Form.Item
                                name="username"
                                rules={[{required: true, message: 'Please input your Username!'}]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                       value={this.state.signin.username}
                                       onChange={(x) => {
                                           // console.log(x);
                                           if (x.target.value.match(/admin/i)) {
                                               this.setState({
                                                       enableAdminSelection: true,
                                                       signin: update(this.state.signin, {
                                                           system: {$set: "admin"},
                                                           username: {$set: x.target.value}
                                                       })
                                                   }
                                               )
                                           } else {
                                               this.setState({signin: update(this.state.signin, {username: {$set: x.target.value}})})
                                           }
                                           this.setState({signin: update(this.state.signin, {username: {$set: x.target.value}})})
                                       }}
                                       placeholder="เลขที่ทะเบียนนิติบุคคล / Username"/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{required: true, message: 'Please input your Password!'}]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    type="password"
                                    placeholder="รหัสผ่าน"
                                    value={this.state.signin.password}
                                    onChange={(x) => {
                                        this.setState({signin: update(this.state.signin, {password: {$set: x.target.value}})})
                                    }}
                                />
                            </Form.Item>
                            {/*<Recaptcha
                        onResolved={() => console.log('Human detected.')}
                        ref={recaptchaRef}
                        sitekey={recaptchaSitekey}
                    />*/}
                            <Form.Item style={{paddingLeft: "15px", paddingRight: "15px", width: "100%"}}
                                       name="captcha"
                                       rules={[{required: true, message: 'Please validate reCaptcha'}]}
                            >
                                <div style={{marginLeft: "25px", marginRight: "25px"}}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={recaptchaSitekey} //SITE KEY
                                        size={"compact"}
                                        onChange={reCAPTCHAHandle}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button"
                                        onClick={() => {
                                            submitForm();
                                        }}
                                >
                                    เข้าใช้งาน
                                </Button> &nbsp;
                                <Button type="secondary" onClick={() => {
                                    this.props.history.push("/signup/register/" + this.loginForm.current.getFieldValue('system'))
                                }} className="login-form-button">
                                    สมัครสมาชิก
                                </Button>
                            </Form.Item>
                            <Button type={"link"} onClick={() => {
                                const MySwal = withReactContent(Swal);

                                MySwal.fire({
                                    title: 'Forget your password or cannot Login?',
                                    text: "Please contact Thai Star for assistance.",
                                    icon: 'Info',
                                }).then((result) => {
                                    // if (result.isConfirmed) {
                                    //     this.props.dipdataUpdate();
                                    // }
                                })

                            }} className="login-form-forgot">
                                Cannot Login
                            </Button>
                        </Form>
                    </>
                }
            </div>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        auth: state.Auth,
    }
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
const EmbedLoginFormWithRouter = withRouter(EmbedLoginForm)
export default connect(mapStateToProps, {...authenticatorActionFn,})(EmbedLoginFormWithRouter);
//export default ;
