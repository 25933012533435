import React from 'react';
import ReactDOM from 'react-dom';

import {Layout, Menu} from "antd";
import {
    DesktopOutlined,
    BarChartOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    ShopOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {baseDataActionFn} from "../redux/basedata/actions";
import {permissionParser, permissionChecker} from "../utility/permissionHelper";
import * as Constant from "../constants"
import {enabledModule} from "../constants";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

const defaultPermission = ["ADMIN", "THAISTAR", "LOGIN"];

class sideMenu extends React.Component {
    state = {selectedMenu: "baseStore"}

    checkLoginMode(menuItem) {
        let targetSystem = ["all"];
        if (menuItem.module) {
            targetSystem = menuItem.module

            if (Constant.enabledModule.indexOf(menuItem.module) < 0) {
                return false;
            }
        }

        const currentSystem = this.props.auth.system || "none";
        if (currentSystem === "admin") return true;



        if (targetSystem.indexOf(currentSystem) >= 0) {
            return true;
        } else {
            return false;
        }

    }

    render() {
        const {history} = this.props;

        const currentLocalUser = this.props.auth;
        const currentSystem = this.props.auth.system || "none";
        const currentPermission = permissionParser(currentLocalUser);


        const menuItem = [
            // {
            //     key: "baseStore",
            //     url: "/store",
            //     name: "คลังสินค้า",
            //     icon: <ShopOutlined/>,
            // },
            {
                key: "Announcement",
                name: "ประกาศและข่าวสาร",
                module: "ALL",
                icon: <PieChartOutlined/>,
                children: [
                    {
                        key: "GenericAnnouncement",
                        url: "/genericAnnouncement",
                        name: "ข่าวประชาสัมพันธ์ทั่วไป",
                        icon: <SettingOutlined/>
                    },
                    {
                        key: "MembershipAnnouncement",
                        url: "/membershipAnnouncement",
                        name: "แจ้งข่าวสำหรับสมาชิก",
                        icon: <BarChartOutlined/>,
                    },
                ]
            },
            {
                key: "Policy",
                name: "DCB - กรมธรรม์",
                module: "DCB",
                icon: <PieChartOutlined/>,
                children: [
                    {
                        key: "ownPolicy",
                        url: "/offlineStore/configuration",
                        name: "เรียกดูกรมธรรม์",
                        icon: <SettingOutlined/>
                    },
                    {
                        key: "ownPolicyPayment",
                        url: "/offlineStore/configuration",
                        name: "แจ้งชำระเงินค่าธรรมเนียม",
                        icon: <SettingOutlined/>
                    },
                    {
                        key: "allPolicySearch",
                        url: "/offlineStore/configuration",
                        name: "ค้นหาข้อมูลกรมธรรม์",
                        icon: <SettingOutlined/>
                    },
                    {
                        key: "allPolicyReport",
                        url: "/offlineStore/configuration",
                        name: "รายงานกรมธรรม์",
                        icon: <SettingOutlined/>
                    },
                ]
            },
            {
                key: "DIP",
                name: "DIP - Report",
                url: "/dipReport",
                module: "DIP",
                icon: <PieChartOutlined/>,
            }
        ]

        // console.log(this.props)

        return <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline"
                     style={{marginTop: -4}}
                     key={"genericApi"}
                     selectedKeys={this.props.baseData.currentMenu}
                     onSelect={(data) => {
                         // console.log(data.key);
                         this.props.basedataSetCurrentMenu(data.key)
                     }}>
            {
                menuItem.map(menuRow => {
                    // console.log(menuRow);
                    // Add default
                    if (this.checkLoginMode(menuRow)) {
                        if (menuRow.children) {
                            return <SubMenu key={menuRow.key} icon={menuRow.icon} title={menuRow.name}>
                                {menuRow.children.map(subMenuRow => {
                                    if (this.checkLoginMode(subMenuRow)) {
                                        if (subMenuRow.children) {
                                            return <SubMenu key={subMenuRow.key} icon={subMenuRow.icon}
                                                            title={subMenuRow.name}>
                                                {subMenuRow.children.map(subSubMenuRow => {
                                                    return <Menu.Item key={subSubMenuRow.key} icon={subSubMenuRow.icon}>
                                                        <NavLink to={subSubMenuRow.url}>
                                                            {subSubMenuRow.name}
                                                        </NavLink>
                                                    </Menu.Item>
                                                })}
                                            </SubMenu>
                                        } else {
                                            return <Menu.Item key={subMenuRow.key} icon={subMenuRow.icon}>
                                                <NavLink to={subMenuRow.url}>
                                                    {subMenuRow.name}
                                                </NavLink>
                                            </Menu.Item>
                                        }
                                        //
                                        // return <Menu.Item key={subMenuRow.key} icon={subMenuRow.icon}>
                                        //     <NavLink to={subMenuRow.url}>
                                        //         {subMenuRow.name}
                                        //     </NavLink>
                                        // </Menu.Item>
                                    }

                                    return null;
                                })}
                            </SubMenu>
                        } else {
                            return <Menu.Item key={menuRow.key} icon={menuRow.icon}>
                                <NavLink to={menuRow.url}>
                                    {menuRow.name}
                                </NavLink>
                            </Menu.Item>
                        }
                    }
                    return null;

                })
            }
        </Menu>
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        baseData: state.BaseData,
        auth: state.Auth,
        // statement: state.Statement,

    }
}

let sideMenuWithRouter = withRouter(sideMenu)
export default connect(mapStateToProps, {...baseDataActionFn})(sideMenuWithRouter);
