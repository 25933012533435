import fetch from 'isomorphic-fetch';
import {checkStatus} from "../../utility/fetch_preprocessor";
import {store} from './../store';
import stringify from 'json-stringify-safe';

const serverPrefix = "";


export function login(data) {
    return fetch(serverPrefix + `/acs/signin`,
        {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: stringify(data)
        })
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function checktoken(data) {
    // let currentState = store.getState();
    let jwtToken = JSON.parse(localStorage.getItem('userData')).token;
    return fetch(serverPrefix + `/acs/checkToken`,
        {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "bearer " + jwtToken
            },
            body: stringify(data)
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}


export function signin(data) {
    // const q = encodeURIComponent(data);
    // console.log("Suggesting Text : ",text);
    return fetch(serverPrefix + `/acs/signin`,
        {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: stringify(data)
        })
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function signup(data) {
    // const q = encodeURIComponent(data);
    // console.log("Suggesting Text : ",text);
    return fetch(serverPrefix + `/acs/signup`,
        {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: stringify(data)
        })
        .then(res => res.json())
        .catch(err => console.log(err));
}
