import React, {Component} from 'react';
import {
    Col, Row, Icon, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, Form, Breadcrumb, Layout,
    Button,
} from 'antd';
import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import Helmet from "react-helmet";
// import ReactQuill, {Quill} from "react-quill";
// import 'react-quill/dist/quill.snow.css';

// import { EditorState } from 'draft-js';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';


// const {TextArea} = Input;
// const FormItem = Form.Item;
// const uuidv4 = require('uuid/v4');
// const {Option, OptGroup} = Select;
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";

const {Header, Content, Footer} = Layout;

const moment = Moment;

let todayMoment = new moment();

class Announcement extends Component {
    constructor(props) {
        super(props);
        let state = {
            mode: "view",
            htmlData: "",
        };
        const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                ...state,
                editorState,
            };
        } else {
            this.state = state
        }
    }



    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        }
    };

    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });


    componentDidMount() {


        // console.log(this.formData);
    }

    render() {
        const onEditorStateChange = (editorState) => {
            this.setState({
                editorState,
            });
        };

        return (
            <Content style={{padding: '0 50px', marginTop: 64}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>ข่าวประชาสัมพันธ์สำหรับสมาชิก</Breadcrumb.Item>
                </Breadcrumb>
                <Helmet>
                    ประกาศข่าวสารสำหรับสมาชิก
                </Helmet>
                {(this.state.mode === "view") &&
                <>
                    <Button onClick={() => {
                        const contentBlock = htmlToDraft(this.state.htmlData);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            this.setState = {
                                editorState,
                            };
                        }

                        this.setState({mode: "edit"})
                    }}>Edit</Button>
                    {ReactHtmlParser(this.state.htmlData)}
                </>
                }
                {(this.state.mode === "edit") &&
                <>
                    <Button onClick={() => {
                        this.setState({mode: "view"})
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        this.setState({mode: "view"})
                    }}>Save</Button>
                    <Editor
                        editorState={this.state.editorState}
                        onEditorStateChange={onEditorStateChange}
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="wrapperClassName"
                        // editorClassName="editorClassName"
                        onChange={(x)=>{
                            // console.log(x);
                            // console.log(newHtml);
                            let newHtml = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
                            this.setState({htmlData:newHtml});

                        }}
                    />
{/*                    <ReactQuill theme="snow" value={this.state.htmlData}
                                onChange={(x) => {
                                    this.setState({htmlData: x})
                                    console.log(x)
                                }}/>*/}
                </>
                }

            </Content>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {}
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
// export default connect(mapStateToProps, {})(WrappedFormWIthSubmissionButton);
export default connect(mapStateToProps, {})(Announcement);
//export default ;
