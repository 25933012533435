import {restockDataActionFn, restockDataActionType} from "./actions";
import {createSelector} from 'reselect'
import moment from 'moment';
import _ from 'lodash';
import update from 'immutability-helper';
// import {reportActionType as reportAction} from "../reports/actions";

let today = new Date();
let currentDMY = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
let todayMoment = new moment();


const initial = {
    currentBranch: "",
    currentMode:"range",
    currentDataRange:{startDate:moment(),endDate:moment()},
    currentRestockData: [],
    currentRestockDataSelection: [],
    currentSaleData: [],
    currentSaleDataSelection: [],
    currentBuyData: [],
    currentBuyDataSelection: [],
    currentPendingData: [],
    currentPendingDataSelection: [],
    orderData: [],
    requestValue: 0,
    transferValue: 0,
    reorderValue: 0,
    transferOrder: [],
    restockOrder: [],
    restockConfirmModal: false,
    restockViewModal: false,
    buyRevision:0,
    pendingRevision:0,
};

export default function restockDataReducer(state = initial, action) {
    let currentSelectedStatement = [];
    let siteUpdateList = {};
    let siteListByKey = {};
    let storeListByKey = {};

    switch (action.type) {

        case restockDataActionType.REORDERLIST.SUCCESS:
            return update(state,{
                currentRestockData: {$set:action.payload.orderList},
                currentBranch: {$set:action.payload.branchNumber},
                currentDataRange: {$set:action.payload.dataRange}
            });

        case restockDataActionType.REORDERLIST.RESET:
            return update(state,{
                currentRestockData: {$set:[]},
                currentBranch: {$set:""},
                currentDataRange: {$set:action.payload.dataRange}
            });

        // case restockDataActionType.REORDERLIST.SUCCESS:
        //     return update(state,{
        //         currentRestockData: {$set:[]},
        //         currentBranch: {$set:""},
        //         currentDataRange: {$set:action.payload.dataRange}
        //     });

        case restockDataActionType.SALES_ITEM.SUCCESS:
            return update(state,{
                currentSaleData: {$set:action.payload.restockData},
                currentBranch: {$set:action.payload.branchNumber},
                currentDataRange: {$set:action.payload.dataRange}
            });

        case restockDataActionType.SALES_ITEM.RESET:
            return update(state,{
                currentSaleData: {$set:[]},
                currentPendingData: {$set:[]},
                currentBuyData: {$set:[]},
                currentRestockData: {$set:[]},
                currentBranch: {$set:""},
                currentDataRange:{$set:{startDate:moment(),endDate:moment()}},
            });

        case restockDataActionType.SALES_ITEM.SELECT:
            // console.log(action.payload);
            return update(state,{
                currentSaleDataSelection: {$set:action.payload},
            });

        case restockDataActionType.BUY_ITEM.ADD + "_SUCCEEDED" :
            return update(state,{
                currentBuyData: {$set:action.payload.buyData},
                currentPendingData : {$set:action.payload.pendingData},
                buyRevision: {$set:state.buyRevision + 1},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case restockDataActionType.BUY_ITEM.UPDATE + "_SUCCEEDED" :
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentBuyData: {[action.payload.target]:{$set:action.payload.data}},
                buyRevision: {$set:state.buyRevision + 1}
            });

        case restockDataActionType.PENDING_ITEM.UPDATE + "_SUCCEEDED" :
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentPendingData: {[action.payload.target]:{$set:action.payload.data}},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case restockDataActionType.REORDERTRANSFERCONFIRM.START + "_SUCCEEDED" :
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentPendingData: {$set:action.payload.ProductTable},
                transferOrder:{$set:action.payload.transferOrder},
                restockOrder:{$set:action.payload.restockOrder},
                requestValue:{$set:action.payload.requestValue},
                transferValue:{$set:action.payload.transferValue},
                restockConfirmModal: {$set:true},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case restockDataActionType.REORDERTRANSFERCONFIRM.CANCEL:
        case restockDataActionType.REORDERTRANSFERCONFIRM.CONFIRM_DONE:
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentPendingData: {$set:[]},
                transferOrder:{$set:[]},
                restockOrder:{$set:[]},
                restockConfirmModal: {$set:false},
                requestValue:{$set:0},
                transferValue:{$set:0},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case restockDataActionType.REORDERRECEIVECONFIRM.START + "_SUCCEEDED" :
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentPendingData: {$set:action.payload.ProductTable},
                transferOrder:{$set:action.payload.transferOrder},
                restockOrder:{$set:action.payload.restockOrder},
                requestValue:{$set:action.payload.requestValue},
                transferValue:{$set:action.payload.transferValue},
                reorderValue:{$set:action.payload.reorderValue},
                restockConfirmModal: {$set:true},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case restockDataActionType.REORDERRECEIVECONFIRM.CANCEL:
        case restockDataActionType.REORDERRECEIVECONFIRM.CONFIRM_DONE:
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentPendingData: {$set:[]},
                transferOrder:{$set:[]},
                restockOrder:{$set:[]},
                restockConfirmModal: {$set:false},
                requestValue:{$set:0},
                transferValue:{$set:0},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case restockDataActionType.REORDERRECEIVECONFIRM.ITEM_UPDATE + "_SUCCEEDED":
            return update(state,{
                currentPendingData: {[action.payload.target]:{$set:action.payload.data}},
                pendingRevision: {$set:state.pendingRevision + 1},
                reorderValue: {$set:action.payload.reorderValue}
            });

        default:
            return state;

    }

}
