import {all, takeEvery, fork, call, put, delay} from 'redux-saga/effects';
import {restockDataActionType, restockDataActionFn} from "./actions";

import {notification, message} from 'antd';
import * as API from './api';
import * as PDFGen from '../../utility/pdfgen'
import _ from "lodash";
import {inspect} from 'util'

const authString = "";

const openNotificationWithIcon = (type, header, detail) => {
    notification[type]({
        message: header,
        description: detail,
        duration: 5,
    });
};

const openStayNotificationWithIcon = (key, duration, type, header) => {
    let targetDuration = duration;
    notification[type]({
        key,
        message: header,
        duration: targetDuration,
    });
};

const closeStayNotification = (key) => {
    notification.close(key);
}


function* fetchrestockData(action, dispatch) {
    let branchNumber = action.payload.branchNumber;
    // console.log(action.payload)
    openStayNotificationWithIcon("restockData", 0, "info", "Loading Sales Data of Branch-" + branchNumber + ".");
    try {
        let salesData = yield call(API.getSalesDetail, action.payload);
        // console.log("DEBUG",salesData);
        if (!("error" in salesData)) {
            openStayNotificationWithIcon("restockData", 3, "success", "Sales Data of Branch-" + branchNumber + " loaded.");
            yield put(restockDataActionFn.restockDataSaleItemSuccess({branchNumber, restockData: salesData.IndividualSale, dataRange:salesData.DataRange}));

        } else {
            openStayNotificationWithIcon("restockData", 3, "error", "Sales Data of Branch-" + branchNumber + " cannot be loaded.");
            yield put(restockDataActionFn.restockDataSaleItemFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("restockData", 3, "error", "Cannot load sales data");
    }
}

function* fetchReorder(action, dispatch) {
    openStayNotificationWithIcon("reorder", 0, "info", "Get a list of reorder request.");
    try {
        let reorderData = yield call(API.getReorder, action.payload);
        // console.log("DEBUG",salesData);
        if (("done" in reorderData) && reorderData.done) {
            openStayNotificationWithIcon("reorder", 3, "success", "A list of reorder request is loaded.");
            PDFGen.generateRestockItemList(reorderData,action.payload.branchName,action.payload.pdfMode || "order");
            yield put(restockDataActionFn.restockDataReorderSuccess(reorderData));

        } else {
            openStayNotificationWithIcon("reorder", 3, "error", "A list of reorder request cannot be loaded.");
            yield put(restockDataActionFn.restockDataReorderFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("restockData", 3, "error", "Cannot load sales data");
    }
}

function* fetchReorderList(action, dispatch) {
    openStayNotificationWithIcon("reorder", 0, "info", "Get a list of reorder request.");
    try {
        let reorderData = yield call(API.getReorderList, action.payload);
        // console.log("DEBUG",salesData);
        if (("done" in reorderData) && reorderData.done) {
            openStayNotificationWithIcon("reorder", 3, "success", "A list of reorder request is loaded.");
            yield put(restockDataActionFn.restockDataReorderListSuccess(reorderData));

        } else {
            openStayNotificationWithIcon("reorder", 3, "error", "A list of reorder request cannot be loaded.");
            yield put(restockDataActionFn.restockDataReorderListFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("restockData", 3, "error", "Cannot load sales data");
    }
}

function* confirmTransferOrder(action, dispatch) {
    openStayNotificationWithIcon("confirm", 0, "info", "Confirming transfer detail.");
    try {
        let confirmData = yield call(API.saveTransferConfirm, action.payload);
        // console.log("DEBUG",salesData);
        if (("done" in confirmData) && confirmData.done) {
            openStayNotificationWithIcon("confirm", 3, "success", "Confirming Complete.");
            yield put(restockDataActionFn.restockDataTransferConfirmationDone(confirmData));
            yield put(restockDataActionFn.restockDataReorderListFetch({
                    branchNumber: action.payload.selectedBranch,
                    selectedDate: action.payload.selectedDate
            }));


        } else {
            openStayNotificationWithIcon("confirm", 3, "error", "Confirming Fail.");
            yield put(restockDataActionFn.restockDataTransferConfirmationFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("restockData", 3, "error", "Cannot load sales data");
    }
}

function* confirmReceiveOrder(action, dispatch) {
    openStayNotificationWithIcon("confirm", 0, "info", "Confirming receive detail.");
    try {
        let confirmData = yield call(API.saveReceiveConfirm, action.payload);
        // console.log("DEBUG",salesData);
        if (("done" in confirmData) && confirmData.done) {
            openStayNotificationWithIcon("confirm", 3, "success", "Confirming Complete.");
            yield put(restockDataActionFn.restockDataReceiveConfirmationDone(confirmData));
            yield put(restockDataActionFn.restockDataReorderListFetch({
                branchNumber: action.payload.selectedBranch,
                selectedDate: action.payload.selectedDate
            }));

        } else {
            openStayNotificationWithIcon("confirm", 3, "error", "Confirming Fail.");
            yield put(restockDataActionFn.restockDataReceiveConfirmationFail());
        }

    } catch (error) {
        console.log(error);
        openStayNotificationWithIcon("restockData", 3, "error", "Cannot load sales data");
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(restockDataActionType.SALES_ITEM.FETCH, fetchrestockData),
        takeEvery(restockDataActionType.REORDER.FETCH, fetchReorder),
        takeEvery(restockDataActionType.REORDERLIST.FETCH, fetchReorderList),
        takeEvery(restockDataActionType.REORDERTRANSFERCONFIRM.CONFIRM_START, confirmTransferOrder),
        takeEvery(restockDataActionType.REORDERRECEIVECONFIRM.CONFIRM_START, confirmReceiveOrder),
    ]);
}
