import React from 'react';
import ReactDOM from 'react-dom';

import {Layout, Menu,Button} from "antd";
import {
    DesktopOutlined, BarChartOutlined, FileOutlined,
    PieChartOutlined, TeamOutlined, ShopOutlined, ProfileOutlined,
    SettingOutlined, UserOutlined, UserAddOutlined, UsergroupAddOutlined,
    LogoutOutlined, AlertOutlined
} from "@ant-design/icons";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {baseDataActionFn} from "../../redux/basedata/actions";

import {permissionParser,permissionChecker} from "../../utility/permissionHelper"
import {authenticatorActionFn} from "../../redux/auth/actions";
import {dipAgentList, dipPeriodGroup, dipSales} from "../../redux/dipdata/selector";
const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

const defaultPermission = ["ADMIN", "THAISTAR", "LOGIN"];

class userMenu extends React.Component {
    state = {selectedMenu: "baseStore"}

    render() {
        const currentLocalUser = this.props.auth;
        const currentPermission = permissionParser(currentLocalUser);
        const {history} = this.props;

        const menuItem = [
            {
                key: "userManagement",
                name: "Account Management",
                icon: <UserOutlined/>,
                permission: defaultPermission,
                children: [
                    // {
                    //     key: "Self-service",
                    //     name: "Accounts",
                    //     icon: <UserOutlined/>,
                    //     permission: defaultPermission,
                    //     url: "/account/myaccount"
                    // },
                    {
                        key: "userAccount",
                        name: "แก้ไขข้อมูลส่วนบุคคล",
                        icon: <UserOutlined/>,
                        permission: defaultPermission,
                        url: "/account/userprofile"
                    },
                    {
                        key: "userDocument",
                        name: "นำส่ง/เรียกดูเอกสาร",
                        icon: <UserOutlined/>,
                        permission: defaultPermission,
                        url: "/account/document"
                    },
                    // {
                    //     key: "UserPermission",
                    //     url: "/account/permission",
                    //     name: "Account Permissions",
                    //     permission: defaultPermission,
                    //     icon: <UserAddOutlined />,
                    // },
                    // {
                    //     key: "UserGroup",
                    //     url: "/account/group",
                    //     name: "Groups",
                    //     permission: defaultPermission,
                    //     icon: <TeamOutlined/>,
                    // },
                    // {
                    //     key: "GroupPermission",
                    //     url: "/account/group_permission",
                    //     name: "Group Permissions",
                    //     permission: defaultPermission,
                    //     icon: <UsergroupAddOutlined />,
                    // },
                    //
                    // {
                    //     key: "PermissionList",
                    //     url: "/account/permission_list",
                    //     name: "Permission List",
                    //     permission: defaultPermission,
                    //     icon: <ProfileOutlined/>,
                    // },
                ]
            },
        ]
        // console.log(this.props)

        return <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline"
                     key={"userManagementApi"}
                     style={{marginTop: -4}}
                     selectedKeys={this.props.baseData.currentMenu}
                     onSelect={(data) => {
                         // console.log(data.key);
                         this.props.basedataSetCurrentMenu(data.key)
                     }}>
            {false && (permissionChecker(currentPermission, ['ADMIN'])) &&
                <Menu.Item key="Show console(props)" icon={<AlertOutlined />}>
                    <Button type={"text"} onClick={()=>{console.log(this.props)}}>
                        Show console(props)
                    </Button>
                </Menu.Item>
            }


        </Menu>
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        baseData: state.BaseData,
        auth: state.Auth,
        // statement: state.Statement,
        // Data for DIP
        dip: state.DipData,
        periodGroup: dipPeriodGroup(state.DipData),
        agentList: dipAgentList(state.DipData),
        dipSales: dipSales(state.DipData)
    }
}

let userMenuWithRouter = withRouter(userMenu)
export default connect(mapStateToProps, {...baseDataActionFn,
    ...authenticatorActionFn})(userMenuWithRouter);
