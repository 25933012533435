export function permissionParser(currentUserData) {
    if (!currentUserData) {
        console.log("Invalid user");
        return []
    }
    if (!(currentUserData.user.username || currentUserData.authenticated)) {/*console.log("Invalid authentication status");*/
        return []
    }
    let currentUser = currentUserData.user;

    let finalPermissionList = ['LOGIN']
    switch (currentUser.usertype || "") {
        case 'corporate':
        case 'individual':
        case 'thirdparty':
            break;
        case 'thaistar':
            finalPermissionList.push("THAISTAR");
            break;
        case 'admin':
            finalPermissionList.push("ADMIN");
            break;
        default:
    }
    if ("information" in currentUser) {
        // console.log(currentUser.information);
        if ("dcb" in currentUser.information) {
            if (currentUser.information.dcb) {
                finalPermissionList.push("DCB-" + ("role" in currentUser.information.dcb ? currentUser.information.dcb.role.toUpperCase() : "NONE"))
            }
        }
        if ("dip" in currentUser.information) {
            if (currentUser.information.dip) {
                finalPermissionList.push("DIP-" + ("role" in currentUser.information.dip ? currentUser.information.dip.role.toUpperCase() : "NONE"))
            }
        }
        if ("cdi" in currentUser.information) {
            if (currentUser.information.cdi) {
                finalPermissionList.push("CDI-" + ("role" in currentUser.information.container ? currentUser.information.container.role.toUpperCase() : "NONE"))
            }
        }
    }
    // console.log("Current User Permission",finalPermissionList);
    return finalPermissionList;
}

export function currentUserPermission() {
    const currentUserData = JSON.parse(localStorage.getItem('userData'));
    if (!currentUserData) return [];
    return permissionParser(currentUserData);
}

export function permissionChecker(currentUserPermission, targetPermission) {
    // console.log("userPermission",currentUserPermission,targetPermission);
    if (!targetPermission) return true;
    // if (["ADMIN","DCB-ADMIN","DIP-ADMIN","CNT-ADMIN"].some(r => currentUserPermission.includes(r)))
    let targetIncluded = targetPermission.some(r => currentUserPermission.includes(r))
    let adminIncluded = ["ADMIN", "DCB-ADMIN", "DIP-ADMIN", "CNT-ADMIN"].some(r => currentUserPermission.includes(r))
    // console.log("Current",currentUserPermission,"Target",targetPermission,"targetInclude",targetIncluded,"adminInclude",adminIncluded)
    return (targetIncluded || adminIncluded)
}

export function currentUserToken() {
    const currentUserData = JSON.parse(localStorage.getItem('userData'));
    if (!currentUserData.token) return false;
    return currentUserData.token;
}