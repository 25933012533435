import {all, takeEvery, fork, call, put, delay, take} from 'redux-saga/effects';
import {baseDataActionType, baseDataActionFn} from "./actions";
import {notification, message} from 'antd';
import * as API from './api';
import _ from "lodash";
import moment from "moment-timezone";
import {inspect} from 'util'
import {getProductList, updateOfflineStoreConfiguration, updateSiteConfiguration} from "./api";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const authString = "";
const openNotificationWithIcon = (type, header, detail) => {
    notification[type]({
        message: header,
        description: detail,
        duration: 5,
    });
};

const openStayNotificationWithIcon = (key, duration, type, header) => {
    let targetDuration = duration;
    notification[type]({
        key,
        message: header,
        duration: targetDuration,
    });
};

const closeStayNotification = (key) => {
    notification.close(key);
}


function* fetchDipBaseData(payload, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Loading DIP Base-Data.");
    try {
        let baseData = yield call(API.getDipData);

        if (!("error" in baseData)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Base-Data Loaded.");
            yield put(baseDataActionFn.basedataDipFetchSuccess(baseData));
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
            yield put(baseDataActionFn.basedataDipFetchFail());
        }

    } catch (error) {
        console.log(error);
        yield put(baseDataActionFn.basedataDipFetchFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* updateDipBaseData(payload, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Updating DIP Base-Data.");
    try {
        let baseData = yield call(API.updateDipData);
        if (!("error" in baseData)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Base-Data Updated.");
            yield put(baseDataActionFn.basedataDipUpdateSuccess(baseData));
            let updateResultData = baseData.result;
            // console.log(updateResultData);
            MySwal.fire({
                    icon: 'info',
                    html: 'Data has been updated : <br />' +
                        "Agent : " + updateResultData.agent.length + '<br />' +
                        "Airline : " + updateResultData.airline.length + '<br />' +
                        "Periods : " + updateResultData.period.length + '<br />'
                    ,
                    confirmButtonText: 'Reload',
                }
            ).then((result) => {
                window.location.reload();
            })
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot update DIP Base-Data");
            yield put(baseDataActionFn.basedataDipUpdateFail());
            // console.log(config);
        }

    } catch (error) {
        console.log(error);
        yield put(baseDataActionFn.basedataDipUpdateFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* fetchDipSalesData(data, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Loading DIP Sale-Data.");
    // console.log(data.payload)
    try {
        let baseData = yield call(API.getDipSalesData, data.payload);

        if (!("error" in baseData)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Sale-Data Loaded.");
            yield put(baseDataActionFn.basedataDipSalesFetchSuccess(baseData));
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Sale-Data");
            yield put(baseDataActionFn.basedataDipSalesFetchFail());
        }

    } catch (error) {
        console.log(error);
        yield put(baseDataActionFn.basedataDipSalesFetchFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(baseDataActionType.DIPDATA.FETCH, fetchDipBaseData),
        takeEvery(baseDataActionType.DIPUPDATE.FETCH, updateDipBaseData),
        takeEvery(baseDataActionType.DIPSALESDATA.FETCH, fetchDipSalesData),

    ]);
}
