import React, {Component} from 'react';
import {
    Col,
    Row,
    Icon,
    Checkbox,
    Radio,
    Popconfirm,
    Select,
    Divider,
    Table,
    Input,
    Modal,
    Form,
    Breadcrumb, Layout
} from 'antd';
// import Form from '../../components/uielements/form';
import _ from "lodash";
// import DatePicker from '../../components/uielements/datePicker';
// import PageHeader from '../../components/utility/pageHeader';
// import Box from '../../components/utility/box';
// import LayoutWrapper from '../../components/utility/layoutWrapper.js';
import {connect} from "react-redux";
import BaseDataTemplate from "./template.view"
import Moment from "moment";
import Helmet from "react-helmet";
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';

// import NumberFormat from "react-number-format";
// import ContainerDimensions from "react-container-dimensions";
// import "../Statement/customStatementStyle.css";
// import * as PDFGen from "../../helpers/pdfgenerator/pdfgen";

// const {TextArea} = Input;
// const FormItem = Form.Item;
// const uuidv4 = require('uuid/v4');
// const {Option, OptGroup} = Select;
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";

const {Header, Content, Footer} = Layout;

const moment = Moment;
const baseUrlGroup = "/admin/exclusion";
const dataUrl = baseUrlGroup + "/list/all";
const updateUrl = baseUrlGroup + "/save";
const createUrl = baseUrlGroup + "/create";
const lockUrl = baseUrlGroup + "/lock";
const deleteUrl = baseUrlGroup + "/delete";
const headerText = "แก้ไขรายการยกเว้น";


let todayMoment = new moment();


function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
}

class InputStateful extends Component {
    handleFocus = (event) => {
        event.target.select()
    };

    render() {
        return (<Input {...this.props} onClick={this.handleFocus}/>)
    }
}

class dbDataEditorForm extends Component {
    state = {};

    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        }
    };

    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });


    componentDidMount() {


        // console.log(this.formData);
    }

    menuItem = [
        //CREATE TABLE dummy (
        // 	`userAccountID` INT(11) NOT NULL AUTO_INCREMENT,
        // 	`username` VARCHAR(50) NOT NULL,
        // 	`email` VARCHAR(255) NULL DEFAULT NULL,
        // 	`realname` VARCHAR(255) NOT NULL,
        // 	`password` VARCHAR(255) NOT NULL,
        // 	`permission` INT(11) NULL DEFAULT '2',
        // 	`isActive` TINYINT(1) NULL DEFAULT '1'
        // )
        {key: "id", type: "hidden", label: "hidden", renderOption: {width: "150px"}, searchable: true},
        {
            key: "item_type",
            type: "flag",
            label: "ชนิดบัญชี",
            flagOption: {
                "agent": "นายหน้า/ตัวแทน/agent",
                "airline": "สายการบิน",
                "insurance": "ประกัน",
                "stakeholder": "พนง.TSSC/ผู้ดูแล",
            }
        },
        {key: "item_id", type: "string", label: "รหัส", searchable: true},
        // {key: "taxpayerIDType", type: "string", label: "ชนิดเลขประจำตัว", searchable: true},
    ];

    render() {
        return (
            <Content style={{padding: '0 50px', marginTop: 64}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>Data Management</Breadcrumb.Item>
                    <Breadcrumb.Item>Exclusion Database</Breadcrumb.Item>
                </Breadcrumb>
                <Helmet>
                    {headerText}
                </Helmet>
                <BaseDataTemplate columnDef={this.menuItem}
                                  key={"exclusionEditTable"}
                                  primaryKey={"id"}
                                  updateDataURL={dataUrl}
                                  createDataURL={createUrl}
                                  saveDataURL={updateUrl}
                                  deleteDataURL={deleteUrl}
                                  // lockDataURL={lockUrl}
                                  headerText={headerText}
                />
            </Content>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {}
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
// export default connect(mapStateToProps, {})(WrappedFormWIthSubmissionButton);
export default connect(mapStateToProps, {})(dbDataEditorForm);
//export default ;
