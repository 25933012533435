import ReactDOM from 'react-dom';
import React from 'react';
import {Layout, Menu, Breadcrumb} from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
    FileOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import Boot from './redux/boot';
import MainLayout from "./container/MainLayout";


// Styling
import logo from './logo.svg';
import './App.less';

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;


class Application extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({collapsed});
    };

    render() {
        const {collapsed} = this.state;
        return (
            <Provider store={store}>
                <MainLayout history={history} />
            </Provider>
        );
    }
}

export default Application;
