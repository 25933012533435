import Auth from './auth/reducer';
import BaseData from "./basedata/reducer";
import DipData from "./dipdata/reducer";
import SaleData from "./saledata/reducer";
import RestockData from "./restockdata/reducer";

let reduxStore = {
    Auth,
    BaseData,
    DipData,
    SaleData,
    RestockData,


    // App,
    // ThemeSwitcher,
    // LanguageSwitcher,
    // Mails,
    // Calendar,
    // Box,
    // Notes,
    // Todos,
    // Contacts,
    // Cards,
    // Chat,
    // DynamicChartComponent,
    // Ecommerce,
    // Invoices,
    // YoutubeSearch,
    // Transactions,
    // Statement,
    // Financial,
    // Report,
    // ...DevReducers
};
export default reduxStore;
