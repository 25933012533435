// import {contacts} from "./reducer";
import {createAction} from "redux-actions";
import {createActionThunk} from "redux-thunk-actions";
import {createSelector} from "reselect";
import _ from 'lodash';

function ascendingSort(contact1, contact2) {
    const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
    const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

export const restockDataActionType = {
    SALES_ITEM: {
        FETCH: "RESTOCKDATA::SALES_ITEM_FETCH",
        START: "RESTOCKDATA::SALES_ITEM_START",
        FAIL: "RESTOCKDATA::SALES_ITEM_FAIL",
        SUCCESS: "RESTOCKDATA::SALES_ITEM_SUCCESS",
        RESET: "RESTOCKDATA::SALES_ITEM_RESET",
        SELECT: "RESTOCKDATA::SALES_ITEM_SELECT",
    },

    BUY_ITEM: {
        ADD: "RESTOCKDATA::BUY_ITEM_ADD",
        REMOVE: "RESTOCKDATA::BUY_ITEM_REMOVE",
        UPDATE: "RESTOCKDATA::BUY_ITEM_UPDATE",
        FETCH: "RESTOCKDATA::BUY_ITEM_FETCH",
        START: "RESTOCKDATA::BUY_ITEM_START",
        FAIL: "RESTOCKDATA::BUY_ITEM_FAIL",
        SUCCESS: "RESTOCKDATA::BUY_ITEM_SUCCESS",
        RESET: "RESTOCKDATA::BUY_ITEM_RESET",
        SELECT: "RESTOCKDATA::BUY_ITEM_SELECT",
    },

    PENDING_ITEM: {
        ADD: "RESTOCKDATA::PENDING_ITEM_ADD",
        REMOVE: "RESTOCKDATA::PENDING_ITEM_REMOVE",
        UPDATE: "RESTOCKDATA::PENDING_ITEM_UPDATE",
        FETCH: "RESTOCKDATA::PENDING_ITEM_FETCH",
        START: "RESTOCKDATA::PENDING_ITEM_START",
        FAIL: "RESTOCKDATA::PENDING_ITEM_FAIL",
        SUCCESS: "RESTOCKDATA::PENDING_ITEM_SUCCESS",
        RESET: "RESTOCKDATA::PENDING_ITEM_RESET",
        SELECT: "RESTOCKDATA::PENDING_ITEM_SELECT",
    },

    REORDER: {
        FETCH: "RESTOCKDATA::REORDER_FETCH",
        START: "RESTOCKDATA::REORDER_START",
        FAIL: "RESTOCKDATA::REORDER_FAIL",
        SUCCESS: "RESTOCKDATA::REORDER_SUCCESS",
        RESET: "RESTOCKDATA::REORDER_RESET",
        SELECT: "RESTOCKDATA::REORDER_SELECT",
    },

    REORDERLIST: {
        FETCH: "RESTOCKDATA::REORDERLIST_FETCH",
        START: "RESTOCKDATA::REORDERLIST_START",
        FAIL: "RESTOCKDATA::REORDERLIST_FAIL",
        SUCCESS: "RESTOCKDATA::REORDERLIST_SUCCESS",
        RESET: "RESTOCKDATA::REORDERLIST_RESET",
        SELECT: "RESTOCKDATA::REORDERLIST_SELECT",
    },

    REORDERTRANSFERCONFIRM: {
        CANCEL:  "RESTOCKDATA::REORDERTRANSFERCONFIRM_CANCEL",
        START: "RESTOCKDATA::REORDERTRANSFERCONFIRM_START",
        FAIL: "RESTOCKDATA::REORDERTRANSFERCONFIRM_FAIL",
        SUCCESS: "RESTOCKDATA::REORDERTRANSFERCONFIRM_SUCCESS",
        RESET: "RESTOCKDATA::REORDERTRANSFERCONFIRM_RESET",
        SELECT: "RESTOCKDATA::REORDERTRANSFERCONFIRM_SELECT",

        CONFIRM_START: "RESTOCKDATA::REORDERTRANSFERCONFIRM_CONFIRM_START",
        CONFIRM_DONE: "RESTOCKDATA::REORDERTRANSFERCONFIRM_CONFIRM_DONE",
        CONFIRM_FAIL: "RESTOCKDATA::REORDERTRANSFERCONFIRM_CONFIRM_FAIL",
    },

    REORDERRECEIVECONFIRM: {
        CANCEL:  "RESTOCKDATA::REORDERRECEIVECONFIRM_CANCEL",
        START: "RESTOCKDATA::REORDERRECEIVECONFIRM_START",
        FAIL: "RESTOCKDATA::REORDERRECEIVECONFIRM_FAIL",
        SUCCESS: "RESTOCKDATA::REORDERRECEIVECONFIRM_SUCCESS",
        RESET: "RESTOCKDATA::REORDERRECEIVECONFIRM_RESET",
        SELECT: "RESTOCKDATA::REORDERRECEIVECONFIRM_SELECT",
        ITEM_UPDATE: "RESTOCKDATA::REORDERRECEIVECONFIRM_ITEM_UPDATE",
        CONFIRM_START: "RESTOCKDATA::REORDERRECEIVECONFIRM_CONFIRM_START",
        CONFIRM_DONE: "RESTOCKDATA::REORDERRECEIVECONFIRM_CONFIRM_DONE",
        CONFIRM_FAIL: "RESTOCKDATA::REORDERRECEIVECONFIRM_CONFIRM_FAIL",
    },

};

const isIterable = object => object != null && typeof object[Symbol.iterator] === 'function'

export const restockDataActionFn = {

    restockDataSaleItemFetch: createAction(restockDataActionType.SALES_ITEM.FETCH),
    restockDataSaleItemStart: createAction(restockDataActionType.SALES_ITEM.START),
    restockDataSaleItemFail: createAction(restockDataActionType.SALES_ITEM.FAIL),
    restockDataSaleItemSuccess: createAction(restockDataActionType.SALES_ITEM.SUCCESS),
    restockDataSaleItemReset: createAction(restockDataActionType.SALES_ITEM.RESET),
    restockDataSaleItemSelect: createAction(restockDataActionType.SALES_ITEM.SELECT),
    restockDataSaleItemSelectVerbose: createAction(restockDataActionType.SALES_ITEM.SELECT + "_VERBOSE"),

    restockDataBuyItemAdd: createActionThunk(restockDataActionType.BUY_ITEM.ADD, (payload, state) => {
        let currentBuyData = state.getState().SaleData.currentBuyData;
        let optionList = state.getState().BaseData.productList.productOptions;
        let productList = state.getState().BaseData.productList.products;
        let productListPN = state.getState().BaseData.productList.productsByPN;

        let productIndex = {};
        for (let i = 0; i < productList.length; i++) {
            productIndex[productList[i].PN] = i
        }

        // console.log(currentBuyData,optionList)
        //
        // console.log(optionList);
        let newBuyData = [...currentBuyData];
        let buyingPN = [];
        for (let newItem of payload) {
            // console.log(newItem);
            if (newItem.PN in optionList) {
                if (isIterable(optionList[newItem.PN])) {
                    console.log(newItem.PN, newItem);
                    for (let otherUnit of optionList[newItem.PN]) {
                        if (newItem.LUnit !== otherUnit.SUnit) {
                            newBuyData.push({
                                ...newItem,
                                uniqueId: newItem.PN + otherUnit.SUnit,
                                buyAmount: 0,
                                NumSPerLUnit: otherUnit.NumSPerLUnit,
                                currentUnit: otherUnit.SUnit,
                                currentUnitPrice: otherUnit.Price2 || 0,
                                warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                                totalPrice: 0,
                            });
                        } else {
                            newBuyData.push({
                                ...newItem,
                                uniqueId: newItem.PN + newItem.LUnit,
                                buyAmount: newItem.Number,
                                NumSPerLUnit: otherUnit.NumSPerLUnit,
                                currentUnit: newItem.LUnit,
                                currentUnitPrice: parseFloat(newItem.Price2),
                                warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                                totalPrice: newItem.Number * parseFloat(newItem.Price2)
                            });
                        }
                    }
                } else {
                    newBuyData.push({
                        ...newItem,
                        uniqueId: newItem.PN + newItem.LUnit,
                        buyAmount: newItem.Number,
                        NumSPerLUnit: 1,
                        currentUnit: newItem.LUnit,
                        warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                        currentUnitPrice: parseFloat(newItem.Price2),
                        totalPrice: newItem.Number * parseFloat(newItem.Price2)
                    });
                }


            } else {
                newBuyData.push({
                    ...newItem,
                    uniqueId: newItem.PN + newItem.LUnit,
                    buyAmount: newItem.Number,
                    // NumSPerLUnit: otherUnit.NumSPerLUnit,
                    currentUnit: newItem.LUnit,
                    warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                    currentUnitPrice: parseFloat(newItem.Price2),
                    totalPrice: newItem.Number * parseFloat(newItem.Price2)
                });
            }

        }

        // for (let Item of )
        let buyData = _.sortBy(_.uniqBy(newBuyData, 'uniqueId'), ['GroupName', 'Name']);
        for (let newItem of buyData) {
            buyingPN.push(newItem.PN);
        }

        let pendingData = [];
        for (let newItem of productList) {
            if (buyingPN.indexOf(newItem.PN) < 0) {
                if (newItem.PN in optionList) {
                    if (isIterable(optionList[newItem.PN])) {
                        for (let otherUnit of optionList[newItem.PN]) {
                            pendingData.push({
                                ...newItem,
                                uniqueId: newItem.PN + otherUnit.SUnit,
                                buyAmount: 0,
                                NumSPerLUnit: otherUnit.NumSPerLUnit,
                                currentUnit: otherUnit.SUnit,
                                currentUnitPrice: otherUnit.Price2 || 0,
                                warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                                totalPrice: 0,
                            });
                        }
                    } else {
                        pendingData.push({
                            ...newItem,
                            uniqueId: newItem.PN + newItem.LUnit,
                            buyAmount: 0,
                            NumSPerLUnit: 1,
                            currentUnit: newItem.LUnit,
                            warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                            currentUnitPrice: parseFloat(newItem.Price2),
                            totalPrice: 0
                        });
                    }


                } else {
                    pendingData.push({
                        ...newItem,
                        uniqueId: newItem.PN + newItem.LUnit,
                        buyAmount: 0,
                        // NumSPerLUnit: otherUnit.NumSPerLUnit,
                        currentUnit: newItem.LUnit,
                        currentUnitPrice: parseFloat(newItem.Price2),
                        warehouseStock: productListPN[newItem.PN].warehouseStock || 0,
                        totalPrice: 0
                    });
                }
            }

        }

        pendingData = _.sortBy(_.uniqBy(pendingData, 'uniqueId'), ['GroupName', 'Name']);
        return {
            buyData: buyData,
            pendingData: pendingData
        }
        // state.getState().Transactions.searchCriteria
    }),

    restockDataBuyItemRemove: createAction(restockDataActionType.SALES_ITEM.START),
    restockDataBuyItemUpdate: createActionThunk(restockDataActionType.BUY_ITEM.UPDATE, (payload, state) => {
        let targetRecord = state.getState().SaleData.currentBuyData[payload.target];
        // let targetRecord = currentBuyData
        if (targetRecord.uniqueId === payload.checker) {
            // console.log("match")
            switch (payload.mode) {
                case "amount":
                    // targetRecord.currentUnitPrice = parseFloat(targetRecord.Price2);
                    targetRecord.buyAmount = payload.value;
                    targetRecord.totalPrice = payload.value * targetRecord.currentUnitPrice;
                    break;
                case "unit":
                    // console.log(payload.newUnit);
                    targetRecord.currentUnitPrice = parseFloat(payload.newUnit.Price2);
                    targetRecord.currentUnit = payload.newUnit.SUnit;
                    // targetRecord.buyAmount = targetRecord.buyAmount;
                    targetRecord.totalPrice = targetRecord.currentUnitPrice * targetRecord.buyAmount;
                    targetRecord.uniqueId = targetRecord.PN + payload.newUnit.SUnit;
                    break;

                default:
            }


            return {data: targetRecord, target: payload.target}
        }
        // state.getState().Transactions.searchCriteria
    }),

    restockDataPendingItemUpdate: createActionThunk(restockDataActionType.PENDING_ITEM.UPDATE, (payload, state) => {
        let targetRecord = state.getState().SaleData.currentPendingData[payload.target];
        // let targetRecord = currentBuyData
        if (targetRecord.uniqueId === payload.checker) {
            // console.log("match")
            switch (payload.mode) {
                case "amount":
                    // targetRecord.currentUnitPrice = parseFloat(targetRecord.Price2);
                    targetRecord.buyAmount = payload.value;
                    targetRecord.totalPrice = payload.value * targetRecord.currentUnitPrice;
                    break;
                case "unit":
                    // console.log(payload.newUnit);
                    targetRecord.currentUnitPrice = parseFloat(payload.newUnit.Price2);
                    targetRecord.currentUnit = payload.newUnit.SUnit;
                    // targetRecord.buyAmount = targetRecord.buyAmount;
                    targetRecord.totalPrice = targetRecord.currentUnitPrice * targetRecord.buyAmount;
                    targetRecord.uniqueId = targetRecord.PN + payload.newUnit.SUnit;
                    break;

                default:
            }


            return {data: targetRecord, target: payload.target}
        }
        // state.getState().Transactions.searchCriteria
    }),

    restockDataReorderFetch: createAction(restockDataActionType.REORDER.FETCH),
    restockDataReorderStart: createAction(restockDataActionType.REORDER.START),
    restockDataReorderFail: createAction(restockDataActionType.REORDER.FAIL),
    restockDataReorderSuccess: createAction(restockDataActionType.REORDER.SUCCESS),

    restockDataReorderListFetch: createAction(restockDataActionType.REORDERLIST.FETCH),
    restockDataReorderListStart: createAction(restockDataActionType.REORDERLIST.START),
    restockDataReorderListFail: createAction(restockDataActionType.REORDERLIST.FAIL),
    restockDataReorderListSuccess: createAction(restockDataActionType.REORDERLIST.SUCCESS),

    restockDataPrepareTransferConfirmationCancel: createAction(restockDataActionType.REORDERTRANSFERCONFIRM.CANCEL),
    restockDataPrepareTransferConfirmation: createActionThunk(restockDataActionType.REORDERTRANSFERCONFIRM.START, (payload, state) => {
        // let productList = state.getState().BaseData.productList;
        let optionList = state.getState().BaseData.productList.productOptions;
        let productList = state.getState().BaseData.productList.products;
        let groupList = {};
        for (let groupRow of state.getState().BaseData.productList.groups) {
            groupList[groupRow.groupId] = groupRow.name
        }
        let productListPN = state.getState().BaseData.productList.productsByPN;
        //productList.products, productOptions, productsByPN

        let transferOrderPN = [], transferOrderByPNUnit = {};
        for (let transferRow of payload.transferOrderItem) {
            transferOrderPN.push(transferRow.PN);
            if ((transferRow.PN + transferRow.LUnit) in transferOrderByPNUnit) {
                transferOrderByPNUnit[transferRow.PN + transferRow.LUnit] += parseFloat(transferRow.fulfillAmount || "0")
            } else {
                transferOrderByPNUnit[transferRow.PN + transferRow.LUnit] = parseFloat(transferRow.fulfillAmount || "0")
            }
        }
        let restockOrderPN = [], restockOrderByPNUnit = {};
        for (let restockRow of payload.restockOrderItem) {
            restockOrderPN.push(restockRow.PN)
            restockOrderByPNUnit[restockRow.PN + restockRow.buyUnit] = parseFloat(restockRow.buyAmount || "0")
        }

        let itemListPN = _.uniq([...transferOrderPN, ...restockOrderPN]);

        let ProductTable = []
        for (let newItem of itemListPN) {
            if (newItem in optionList) {
                if (isIterable(optionList[newItem])) {
                    for (let otherUnit of optionList[newItem]) {
                        // Only with amount
                        if (
                            (((newItem + otherUnit.SUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + otherUnit.SUnit] : 0)) ||
                            ((newItem + otherUnit.SUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + otherUnit.SUnit] : 0)
                        ) {
                            ProductTable.push({
                                ...productListPN[newItem],
                                uniqueId: newItem + otherUnit.SUnit,
                                currentUnit: otherUnit.SUnit,
                                groupName:groupList[productListPN[newItem].GroupID],
                                requestAmount: ((newItem + otherUnit.SUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + otherUnit.SUnit] : 0),
                                transferAmount: ((newItem + otherUnit.SUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + otherUnit.SUnit] : 0),
                                requestValue: parseFloat(otherUnit.Price2) * ((newItem + otherUnit.SUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + otherUnit.SUnit] : 0),
                                transferValue: parseFloat(otherUnit.Price2) * ((newItem + otherUnit.SUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + otherUnit.SUnit] : 0),
                            });
                        }
                    }
                } else {
                    // Only with amount
                    if (
                        ((newItem + productListPN[newItem].LUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0) ||
                        ((newItem + productListPN[newItem].LUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0)
                    ) {
                        ProductTable.push({
                            ...productListPN[newItem],
                            uniqueId: newItem + productListPN[newItem].LUnit,
                            currentUnit: productListPN[newItem].LUnit,
                            groupName:groupList[productListPN[newItem].GroupID],
                            requestAmount: ((newItem + productListPN[newItem].LUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0),
                            transferAmount: ((newItem + productListPN[newItem].LUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0),
                            requestValue: parseFloat(productListPN[newItem].Price2) * ((newItem + productListPN[newItem].LUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0),
                            transferValue: parseFloat(productListPN[newItem].Price2) * ((newItem + productListPN[newItem].LUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0),
                        });
                    }
                }
            }
        }
        ProductTable = _.sortBy(_.uniqBy(ProductTable, 'uniqueId'), ['groupName', 'Name']);
        let requestValue = _.sumBy(ProductTable,'requestValue');
        let transferValue = _.sumBy(ProductTable,'transferValue');

        return {
            transferOrder:payload.transferOrder,
            restockOrder:payload.restockOrder,
            requestValue,
            transferValue,
            ProductTable,
        }
        // state.getState().Transactions.searchCriteria
    }),
    restockDataTransferConfirmationStart:  createAction(restockDataActionType.REORDERTRANSFERCONFIRM.CONFIRM_START),
    restockDataTransferConfirmationDone:  createAction(restockDataActionType.REORDERTRANSFERCONFIRM.CONFIRM_DONE),
    restockDataTransferConfirmationFail:  createAction(restockDataActionType.REORDERTRANSFERCONFIRM.CONFIRM_FAIL),

    restockDataPrepareReceiveConfirmationCancel: createAction(restockDataActionType.REORDERRECEIVECONFIRM.CANCEL),
    restockDataPrepareReceiveConfirmation: createActionThunk(restockDataActionType.REORDERRECEIVECONFIRM.START, (payload, state) => {
        // let productList = state.getState().BaseData.productList;
        let optionList = state.getState().BaseData.productList.productOptions;
        let productList = state.getState().BaseData.productList.products;
        let groupList = {};
        for (let groupRow of state.getState().BaseData.productList.groups) {
            groupList[groupRow.groupId] = groupRow.name
        }
        let productListPN = state.getState().BaseData.productList.productsByPN;
        //productList.products, productOptions, productsByPN

        let transferOrderPN = [], transferOrderByPNUnit = {};
        for (let transferRow of payload.transferOrderItem) {
            transferOrderPN.push(transferRow.PN);
            transferOrderByPNUnit[transferRow.PN + transferRow.LUnit] = parseFloat(transferRow.fulfillAmount || "0")
        }
        let restockOrderPN = [], restockOrderByPNUnit = {};
        for (let restockRow of payload.restockOrderItem) {
            restockOrderPN.push(restockRow.PN)
            restockOrderByPNUnit[restockRow.PN + restockRow.buyUnit] = parseFloat(restockRow.buyAmount || "0")
        }

        // console.log("transferOrderPN",transferOrderByPNUnit,Object.keys(transferOrderByPNUnit).length);
        // console.log("restockOrderPN",restockOrderByPNUnit,Object.keys(restockOrderByPNUnit).length);
        let itemListPN = _.uniq([...transferOrderPN, ...restockOrderPN]);
        // console.log("itemListPN",itemListPN);

        let ProductTable = []
        let requestAmount = 0;
        let transferAmount = 0;
        let debugCounter = 0;
        for (let newItem of itemListPN) {
            if (newItem in optionList) {
                if (isIterable(optionList[newItem])) {
                    // console.log(newItem,debugCounter++,"IN ITERABLE LIST")
                    for (let otherUnit of optionList[newItem]) {
                        // Only with amount
                        requestAmount = (newItem + otherUnit.SUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + otherUnit.SUnit] : 0;
                        transferAmount = (newItem + otherUnit.SUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + otherUnit.SUnit] : 0;
                        // console.log("TEST",requestAmount,transferAmount,newItem + otherUnit.SUnit)
                        if ( (requestAmount > 0) || (transferAmount > 0)) {
                            // console.log("TEST R",requestAmount,transferAmount,newItem + otherUnit.SUnit)
                            ProductTable.push({
                                ...productListPN[newItem],
                                uniqueId: newItem + otherUnit.SUnit,
                                currentUnit: otherUnit.SUnit,
                                currentUnitPrice: parseFloat(otherUnit.Price2),
                                groupName:groupList[productListPN[newItem].GroupID],
                                requestAmount: requestAmount,
                                transferAmount: transferAmount,
                                requestValue: parseFloat(otherUnit.Price2) * requestAmount,
                                transferValue: parseFloat(otherUnit.Price2) * transferAmount,
                                reorderAmount: (requestAmount > transferAmount) ? (requestAmount-transferAmount) : 0,
                                reorderValue: (requestAmount > transferAmount) ? parseFloat(otherUnit.Price2) *  (requestAmount-transferAmount) : 0,
                            });
                        }
                    }
                } else {
                    // Only with amount
                    // console.log(newItem,debugCounter++,"NOT IN ITERABLE LIST")
                    requestAmount = (newItem + productListPN[newItem].LUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0;
                    transferAmount = (newItem + productListPN[newItem].LUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + productListPN[newItem].LUnit] : 0;
                    if ( (requestAmount > 0)|| (transferAmount>0) ) {
                        ProductTable.push({
                            ...productListPN[newItem],
                            uniqueId: newItem + productListPN[newItem].LUnit,
                            currentUnit: productListPN[newItem].LUnit,
                            currentUnitPrice: parseFloat(productListPN[newItem].Price2),
                            groupName:groupList[productListPN[newItem].GroupID],
                            requestAmount: requestAmount,
                            transferAmount: (transferAmount),
                            requestValue: parseFloat(productListPN[newItem].Price2) * requestAmount,
                            transferValue: parseFloat(productListPN[newItem].Price2) * (transferAmount),
                            reorderAmount: (requestAmount > transferAmount) ? (requestAmount-transferAmount) : 0,
                            reorderValue: (requestAmount > transferAmount) ? parseFloat(productListPN[newItem].Price2) *  (requestAmount-transferAmount) : 0,
                        });
                    }
                }
            } else {
                // console.log(newItem,debugCounter++,"HAS NO OPTION")
                requestAmount = (newItem + productListPN[newItem].SUnit) in restockOrderByPNUnit ? restockOrderByPNUnit[newItem + productListPN[newItem].SUnit] : 0;
                transferAmount = (newItem + productListPN[newItem].SUnit) in transferOrderByPNUnit ? transferOrderByPNUnit[newItem + productListPN[newItem].SUnit] : 0;
                console.log(newItem,requestAmount,transferAmount,productListPN[newItem],(newItem + productListPN[newItem].SUnit) in restockOrderByPNUnit,(newItem + productListPN[newItem].SUnit) in transferOrderByPNUnit)
                if ( (requestAmount > 0)|| (transferAmount>0) ) {
                    ProductTable.push({
                        ...productListPN[newItem],
                        uniqueId: newItem + productListPN[newItem].SUnit,
                        currentUnit: productListPN[newItem].SUnit,
                        currentUnitPrice: parseFloat(productListPN[newItem].Price2),
                        groupName:groupList[productListPN[newItem].GroupID],
                        requestAmount: requestAmount,
                        transferAmount: (transferAmount),
                        requestValue: parseFloat(productListPN[newItem].Price2) * requestAmount,
                        transferValue: parseFloat(productListPN[newItem].Price2) * (transferAmount),
                        reorderAmount: (requestAmount > transferAmount) ? (requestAmount-transferAmount) : 0,
                        reorderValue: (requestAmount > transferAmount) ? parseFloat(productListPN[newItem].Price2) *  (requestAmount-transferAmount) : 0,
                    });
                }
            }
        }
        console.log("ProductTable",ProductTable)
        ProductTable = _.sortBy(_.uniqBy(ProductTable, 'uniqueId'), ['groupName', 'Name']);
        let requestValue = _.sumBy(ProductTable,'requestValue');
        let transferValue = _.sumBy(ProductTable,'transferValue');
        let reorderValue = _.sumBy(ProductTable,'reorderValue');

        return {
            transferOrder:payload.transferOrder,
            restockOrder:payload.restockOrder,
            requestValue,
            transferValue,
            reorderValue,
            ProductTable,
        }
        // state.getState().Transactions.searchCriteria
    }),
    restockDataPrepareReceiveItemUpdate: createActionThunk(restockDataActionType.REORDERRECEIVECONFIRM.ITEM_UPDATE, (payload, state) => {
        let recalculationData = state.getState().RestockData.currentPendingData;
        let targetRecord = recalculationData[payload.target];
        // let targetRecord = currentBuyData
        if (targetRecord.uniqueId === payload.checker) {
            // console.log("match")
            switch (payload.mode) {
                case "amount":
                    // targetRecord.currentUnitPrice = parseFloat(targetRecord.Price2);
                    targetRecord.reorderAmount = payload.value;
                    targetRecord.reorderValue = payload.value * targetRecord.currentUnitPrice;
                    break;
                case "unit":
                    // console.log(payload.newUnit);
                    targetRecord.currentUnitPrice = parseFloat(payload.newUnit.Price2);
                    targetRecord.currentUnit = payload.newUnit.SUnit;
                    // targetRecord.buyAmount = targetRecord.buyAmount;
                    targetRecord.totalPrice = targetRecord.currentUnitPrice * targetRecord.buyAmount;
                    targetRecord.uniqueId = targetRecord.PN + payload.newUnit.SUnit;
                    break;

                default:
            }

            let reorderValue = _.sumBy(recalculationData,'reorderValue');

            return {data: targetRecord, target: payload.target,reorderValue}
        }
        // state.getState().Transactions.searchCriteria
    }),
    restockDataReceiveConfirmationStart:  createAction(restockDataActionType.REORDERRECEIVECONFIRM.CONFIRM_START),
    restockDataReceiveConfirmationDone:  createAction(restockDataActionType.REORDERRECEIVECONFIRM.CONFIRM_DONE),
    restockDataReceiveConfirmationFail:  createAction(restockDataActionType.REORDERRECEIVECONFIRM.CONFIRM_FAIL),

};


export default {...restockDataActionFn};
