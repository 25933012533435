import {createSelector} from 'reselect'

import _ from "lodash";
import BigNumber from "bignumber.js";

BigNumber.set({DECIMAL_PLACES: 4})

const dipPeriodData = (state) => state.dipData.period || [];
const dipAgentData = (state) => state.dipData.agent || [];
const dipAirlineData = (state) => state.dipData.airline || [];
const dipStakeholderData = (state) => state.dipData.stakeholder || [];
const exceptionData = (state) => state.dipData.exceptionInfo || {
    agent: [],
    airline: [],
    insurance: [],
    stakeholder: []
};
const dipSalesData = (state) => state.dipSalesData;
const dipSalesDepositData = (state) => state.dipSalesDeposit;
const filteredAgent = (state) => state.filteredAgent;


export const dipPeriodGroup = createSelector(
    [dipPeriodData],
    (periodData, /*exclusion, remark*/) => {

        let periodMonth = [];
        let periodYear = [];
        let periodPeriod = [];

        for (let optionList of periodData) {
            periodMonth.push(optionList.calculated_month);
            periodYear.push(optionList.calculated_year);
            periodPeriod.push(optionList.calculated_period);
        }

        let periodGroupData = {
            month: _.uniq(periodMonth),
            year: _.reverse(_.sortBy(_.uniq(periodYear))),
            period: _.uniq(periodPeriod),
        }
        return periodGroupData

    });

export const dipAgentList = createSelector(
    [dipAgentData],
    (agentData, /*exclusion, remark*/) => {

        let agentList = [];

        if (!agentData) return agentList;

        for (let optionList of agentData) {
            agentList.push(optionList);
        }

        agentList = (_.sortBy(_.uniq(agentList), ["agent_name"]))

        return agentList
    });


export const dipSales = createSelector(
    [dipPeriodData, dipAgentData, dipAirlineData, dipSalesData, filteredAgent, dipStakeholderData, dipSalesDepositData, exceptionData],
    (periodData, agentData, airlineData, salesData, selectedAgent, dipStakeholder, salesDeposit, exceptionList) => {

        // List of available customers
        let availableAirline = [];
        let availableAgent = [];
        let airlineMap = {};
        let agentMap = {};
        let depositMap = {};

        let contractTemplate = {
            depositSales: BigNumber(0),
            primaryDepositAmount: BigNumber(0),
            primaryDepositDuty: BigNumber(0),
            primaryDepositTax: BigNumber(0),
            primaryDepositNet: BigNumber(0),
            excessDepositAmount: BigNumber(0),
            excessDepositDuty: BigNumber(0),
            excessDepositTax: BigNumber(0),
            excessDepositNet: BigNumber(0),
            primaryDepositAmount_round_diff: BigNumber(0),
            excessDepositAmount_round_diff: BigNumber(0),

            adjustSales: BigNumber(0),
            primaryRealAdjust: BigNumber(0),
            primaryDepositRecord: BigNumber(0),
            primaryAdjustAmount: BigNumber(0),
            primaryAdjustDuty: BigNumber(0),
            primaryAdjustTax: BigNumber(0),
            primaryAdjustNet: BigNumber(0),
            excessRealAdjust: BigNumber(0),
            excessDepositRecord: BigNumber(0),
            excessAdjustAmount: BigNumber(0),
            excessAdjustDuty: BigNumber(0),
            excessAdjustTax: BigNumber(0),
            excessAdjustNet: BigNumber(0),
            primaryAdjustAmount_round_diff: BigNumber(0),
            excessAdjustAmount_round_diff: BigNumber(0),
        }
        let contractData = {
            ...contractTemplate,
            primaryLayerDeposit: [],
            excessLayerDeposit: [],
            primaryLayerAdjust: [],
            excessLayerAdjust: [],
        }
        let spreadData = {
            ...contractTemplate,
            primaryLayerDeposit: [],
            excessLayerDeposit: [],
            primaryLayerAdjust: [],
            excessLayerAdjust: [],
        }

        // SETTING:
        let premiumContractRounding = BigNumber.ROUND_HALF_UP;
        let dutyContractRounding = BigNumber.ROUND_HALF_UP;
        let vatContractRounding = BigNumber.ROUND_UP;
        let premiumAgentRounding = BigNumber.ROUND_DOWN;
        let dutyAgentRounding = BigNumber.ROUND_DOWN; //
        let vatAgentRounding = BigNumber.ROUND_UP;
        let premiumStakeholderRounding = BigNumber.ROUND_DOWN;
        let dutyStakeholderRounding = BigNumber.ROUND_DOWN;
        let vatStakeholderRounding = BigNumber.ROUND_UP;

        let combinedRounding = {
            premiumContractRounding,
            dutyContractRounding,
            vatContractRounding,
            premiumAgentRounding,
            dutyAgentRounding,
            vatAgentRounding,
            premiumStakeholderRounding,
            dutyStakeholderRounding,
            vatStakeholderRounding,
        }

        for (let thisAgent of agentData) {
            availableAgent.push(thisAgent.agent_id)
            agentMap[thisAgent.agent_id] = {...thisAgent}
        }

        for (let thisAirline of airlineData) {
            availableAirline.push(thisAirline.airline_id)
            airlineMap[thisAirline.airline_id] = {...thisAirline}
        }

        for (let thisAgent of agentData) {
            depositMap[thisAgent.agent_id] = {
                primary: false,
                excess: false,
            };
            // IF there is deposit record
            // if (salesDeposit.length == 0) {
            //     for (let thisAirline of airlineData) {
            //         depositMap[thisAgent.agent_id][thisAirline.airline_id] = {
            //             primary: false,
            //             excess: false,
            //         };
            //     }
            // } else {
            //     for (let thisAirline of airlineData) {
            //         depositMap[thisAgent.agent_id][thisAirline.airline_id] = {
            //             primary: BigNumber(0),
            //             excess: BigNumber(0),
            //         };
            //     }
            // }

        }

        for (let thisDeposit of salesDeposit) {
            if (depositMap[thisDeposit.agentId]) {
                depositMap[thisDeposit.agentId].primary = BigNumber(thisDeposit.primaryAmount);
                depositMap[thisDeposit.agentId].excess = BigNumber(thisDeposit.excessAmount);
            }
        }

        availableAirline = _.sortBy(_.uniq(availableAirline));
        availableAgent = _.sortBy(_.uniq(availableAgent));
        // availableAirline.push("all");
        availableAgent.push("all");

        let depositPeriod = []; // salesData.validPeriod_deposit;
        let adjustPeriod = []; //salesData.validPeriod_adjust;
        switch (salesData.result_mode) {
            case "deposit":
                depositPeriod = salesData.validPeriod_deposit;
                adjustPeriod = salesData.validPeriod_deposit;
                break;
            case "adjust":
                depositPeriod = salesData.validPeriod_deposit;
                adjustPeriod = salesData.validPeriod_adjust;
                break;
            default:
        }
        // Prefill BigNumber 0
        let bankGuarantee = {};
        let bankGuaranteeSummary = {};
        let salesEntry = {};
        let salesEntrySummary = {};


        let agentCalculationDetailByAirline = {};
        let agentCalculationDetailByAirlineArray = {};
        let agentCalculationSummaryByAgent = {};
        let agentCalculationSummaryArray = [];

        // region Prepare stakeholder
        let vatRate = {primary: 7, excess: 7};
        let dutyRate = {primary: 0.4, excess: 0.4};
        let primaryLayer = [];
        let excessLayer = [];
        let stakeholderData = [];
        let primaryLeadStakeholderId, excessLeadStakeholderId;
        for (let eachStakeholder of dipStakeholder) {
            if ((eachStakeholder.type === "insurance") && (eachStakeholder.status === "active")) {
                stakeholderData.push({
                    id: eachStakeholder.id,
                    name: eachStakeholder.stakeholder_name_th,
                    primaryRate: BigNumber(eachStakeholder.ratePrimary),
                    isPrimary: (parseFloat(eachStakeholder.ratePrimary) !== 0),
                    isPrimaryLead: eachStakeholder.isPrimaryLead,
                    excessRate: BigNumber(eachStakeholder.rateExcess),
                    isExcess: (parseFloat(eachStakeholder.rateExcess) !== 0),
                    isExcessLead: eachStakeholder.isExcessLead,
                })
                if (parseFloat(eachStakeholder.ratePrimary) !== 0) {
                    primaryLayer.push({
                        id: eachStakeholder.id,
                        name: eachStakeholder.stakeholder_name_th,
                        rate: BigNumber(eachStakeholder.ratePrimary),
                        isLead: eachStakeholder.isPrimaryLead,
                    })
                    if (eachStakeholder.isPrimaryLead) {
                        primaryLeadStakeholderId = eachStakeholder.id;
                    }
                }
                if (parseFloat(eachStakeholder.rateExcess) !== 0) {
                    excessLayer.push({
                        id: eachStakeholder.id,
                        name: eachStakeholder.stakeholder_name_th,
                        rate: BigNumber(eachStakeholder.rateExcess),
                        isLead: eachStakeholder.isExcessLead,
                    })
                    if (eachStakeholder.isExcessLead) {
                        excessLeadStakeholderId = eachStakeholder.id;
                    }
                }
            } else if (eachStakeholder.type === "vat") {
                vatRate.primary = BigNumber(eachStakeholder.ratePrimary).dividedBy(100)
                vatRate.excess = BigNumber(eachStakeholder.rateExcess).dividedBy(100)
            } else if (eachStakeholder.type === "duty") {
                dutyRate.primary = BigNumber(eachStakeholder.ratePrimary).dividedBy(100)
                dutyRate.excess = BigNumber(eachStakeholder.rateExcess).dividedBy(100)
            }
        }
        // endregion

        // region Prepare array
        for (let thisAgentCode of availableAgent) {
            bankGuarantee[thisAgentCode] = {}
            bankGuarantee[thisAgentCode + "_list"] = []
            bankGuaranteeSummary[thisAgentCode] = new BigNumber(0);
            salesEntry[thisAgentCode] = {primary: {}, excess: {}}
            salesEntry[thisAgentCode + "_list"] = []
            salesEntry[thisAgentCode + "_deposit_list"] = []
            salesEntry[thisAgentCode + "_adjust_list"] = []
            salesEntry[thisAgentCode]["deposit"] = {}
            salesEntry[thisAgentCode]["adjust"] = {}
            salesEntrySummary[thisAgentCode] = new BigNumber(0);
            salesEntrySummary[thisAgentCode + "_deposit"] = new BigNumber(0);
            salesEntrySummary[thisAgentCode + "_adjust"] = new BigNumber(0);
            for (let thisAirlineCode of availableAirline) {
                bankGuarantee[thisAgentCode][thisAirlineCode] = new BigNumber(0);
                salesEntry[thisAgentCode]["deposit"][thisAirlineCode] = new BigNumber(0);
                salesEntry[thisAgentCode]["adjust"][thisAirlineCode] = new BigNumber(0);
            }
            bankGuarantee[thisAgentCode]["all"] = new BigNumber(0);
            salesEntry[thisAgentCode]["deposit"]["all"] = new BigNumber(0);
            salesEntry[thisAgentCode]["adjust"]["all"] = new BigNumber(0);
        }
        // endregion

        // region Parse Bank Guarantee
        for (let thisGuarantee of salesData.bank_guarantee) {
            let thisBnkg = parseFloat(thisGuarantee.bnkg_amount)

            thisGuarantee.key = thisGuarantee.airline_id + thisGuarantee.agent_id + thisGuarantee.submittime + "guar";
            // console.log(thisGuarantee.agent_id,thisGuarantee.airline_id,bankGuarantee[thisGuarantee.agent_id][thisGuarantee.airline_id]);
            // Save Record
            bankGuarantee[thisGuarantee.agent_id + "_list"].push(thisGuarantee);
            bankGuarantee["all_list"].push(thisGuarantee)
            // Calculate Amount
            bankGuarantee[thisGuarantee.agent_id][thisGuarantee.airline_id] = bankGuarantee[thisGuarantee.agent_id][thisGuarantee.airline_id].plus(thisBnkg);
            bankGuaranteeSummary[thisGuarantee.agent_id] = bankGuaranteeSummary[thisGuarantee.agent_id].plus(thisBnkg);
            bankGuarantee["all"][thisGuarantee.airline_id] = bankGuarantee["all"][thisGuarantee.airline_id].plus(thisBnkg);
            bankGuaranteeSummary["all"] = bankGuaranteeSummary["all"].plus(thisBnkg);

        }
        // endregion

        // region Parse and populate sales entry
        salesEntry["weird_list"] = [];
        for (let thisSales of salesData.sale_entry) {
            let thisNetSale = parseFloat(thisSales.net_sale)
            thisSales.key = thisSales.airline_id + thisSales.agent_id + thisSales.net_sale + thisSales.submittime;
            // Save Record

            salesEntry["all_list"].push(thisSales);
            // Calculate Amount
            if (depositPeriod.indexOf(thisSales.period_month) >= 0) {
                salesEntry[thisSales.agent_id + "_deposit_list"].push(thisSales);
                salesEntrySummary[thisSales.agent_id + "_deposit"] = salesEntrySummary[thisSales.agent_id + "_deposit"].plus(thisNetSale);
                salesEntrySummary["all_deposit"] = salesEntrySummary["all_deposit"].plus(thisNetSale);
                salesEntry[thisSales.agent_id]["deposit"][thisSales.airline_id] = salesEntry[thisSales.agent_id]["deposit"][thisSales.airline_id].plus(thisNetSale);
                salesEntry["all"]["deposit"][thisSales.airline_id] = salesEntry["all"]["deposit"][thisSales.airline_id].plus(thisNetSale);
                salesEntry["all"]["deposit"]["all"] = salesEntry["all"]["deposit"]["all"].plus(thisNetSale);
            }

            if (adjustPeriod.indexOf(thisSales.period_month) >= 0) {
                salesEntry[thisSales.agent_id + "_adjust_list"].push(thisSales);
                salesEntrySummary[thisSales.agent_id + "_adjust"] = salesEntrySummary[thisSales.agent_id + "_adjust"].plus(thisNetSale);
                salesEntrySummary["all_adjust"] = salesEntrySummary["all_adjust"].plus(thisNetSale);
                salesEntry[thisSales.agent_id]["adjust"][thisSales.airline_id] = salesEntry[thisSales.agent_id]["adjust"][thisSales.airline_id].plus(thisNetSale);
                salesEntry["all"]["adjust"][thisSales.airline_id] = salesEntry["all"]["adjust"][thisSales.airline_id].plus(thisNetSale);
                salesEntry["all"]["adjust"]["all"] = salesEntry["all"]["adjust"]["all"].plus(thisNetSale);
            }

            if (thisNetSale == 99999999.99) {
                salesEntry["weird_list"].push(thisSales);
            }


            salesEntrySummary[thisSales.agent_id] = salesEntrySummary[thisSales.agent_id].plus(thisNetSale);
            salesEntrySummary["all"] = salesEntrySummary["all"].plus(thisNetSale);

        }
        // endregion
        let allAgentDisplayArray = [];
        let allAgentDisplaySummary = {
            agent_id: "SUM",
            agent_name: "TOTAL",
            sales_deposit: BigNumber(0),
            primary_deposit: BigNumber(0),
            primary_deposit_duty: BigNumber(0),
            primary_deposit_tax: BigNumber(0),
            primary_deposit_net: BigNumber(0),
            excess_deposit: BigNumber(0),
            excess_deposit_duty: BigNumber(0),
            excess_deposit_tax: BigNumber(0),
            excess_deposit_net: BigNumber(0),

            sales_adjust: BigNumber(0),
            primary_adjust: BigNumber(0),
            primary_deposit_record: BigNumber(0),
            primary_real_adjust: BigNumber(0),
            primary_adjust_duty: BigNumber(0),
            primary_adjust_tax: BigNumber(0),
            primary_adjust_net: BigNumber(0),
            excess_adjust: BigNumber(0),
            excess_deposit_record: BigNumber(0),
            excess_real_adjust: BigNumber(0),
            excess_adjust_duty: BigNumber(0),
            excess_adjust_tax: BigNumber(0),
            excess_adjust_net: BigNumber(0),
        }

        // region Per-agent raw-summation
        for (let thisAgent of availableAgent) {
            agentCalculationDetailByAirline[thisAgent] = {};
            agentCalculationDetailByAirlineArray[thisAgent] = [];



            let thisAgentRecord = {
                agent_id: thisAgent,
                // agent_name: agentMap[thisAgent].agent_name,
                airline_id: "all",
                key: thisAgent + "_summary",
                agent_name: (thisAgent in agentMap) ? agentMap[thisAgent].agent_name : "-",
                sales_deposit: BigNumber(0),
                sales_adjust: BigNumber(0),
                primary_deposit: BigNumber(0),
                excess_deposit: BigNumber(0),
                primary_base_adjust: BigNumber(0),
                primary_adjust: BigNumber(0),
                excess_adjust: BigNumber(0),
                excess_base_adjust: BigNumber(0),
                bank_guarantee: BigNumber(0),
                bank_guarantee_used: BigNumber(0),
                bank_guarantee_discount: BigNumber(0),
                primaryDeposit: depositMap[thisAgent] ? depositMap[thisAgent].primary || BigNumber(0) : BigNumber(0),
                excessDeposit: depositMap[thisAgent] ? depositMap[thisAgent].excess || BigNumber(0) : BigNumber(0),
            };


            for (let thisAirline of availableAirline) {
                let bankGuaranteeMultiplied = bankGuarantee[thisAgent][thisAirline].multipliedBy(8)
                let bankGuaranteeBase;

                if (salesEntry[thisAgent]["adjust"][thisAirline].gte(bankGuaranteeMultiplied) || (thisAirline == "IATA")) {
                    bankGuaranteeBase = bankGuaranteeMultiplied
                } else {
                    bankGuaranteeBase = salesEntry[thisAgent]["adjust"][thisAirline]
                }

                // region perAgentAirline Calculation
                let bankGuaranteeDiscount = bankGuaranteeBase.multipliedBy(0.0007);

                let primary_deposit_before_round = salesEntry[thisAgent]["deposit"][thisAirline].multipliedBy(0.0007);
                let primary_deposit = primary_deposit_before_round.dp(0, premiumAgentRounding);
                let primary_deposit_round_diff = primary_deposit.minus(primary_deposit_before_round);

                let excess_deposit_before_round = salesEntry[thisAgent]["deposit"][thisAirline].multipliedBy(0.0004);
                let excess_deposit = excess_deposit_before_round.dp(0, premiumAgentRounding);
                let excess_deposit_round_diff = excess_deposit.minus(excess_deposit_before_round);

                let primary_adjust_before_round = salesEntry[thisAgent]["adjust"][thisAirline].multipliedBy(0.0007).minus(bankGuaranteeDiscount);
                let primary_adjust = primary_adjust_before_round.dp(0, premiumAgentRounding);
                let primary_adjust_round_diff = primary_adjust.minus(primary_adjust_before_round);

                let excess_adjust_before_round = salesEntry[thisAgent]["adjust"][thisAirline].multipliedBy(0.0004);
                let excess_adjust = excess_adjust_before_round.dp(0, premiumAgentRounding);
                let excess_adjust_round_diff = excess_adjust.minus(excess_adjust_before_round);

                let thisAirlineRecord = {
                    agent_id: thisAgent,
                    airline_id: thisAirline,
                    key: thisAgent + thisAirline + "airlineRecord",
                    sales_deposit: salesEntry[thisAgent]["deposit"][thisAirline],
                    sales_adjust: salesEntry[thisAgent]["adjust"][thisAirline],
                    primary_deposit,
                    excess_deposit,
                    primary_adjust,
                    excess_adjust,
                    bank_guarantee: bankGuarantee[thisAgent][thisAirline],
                    bank_guarantee_used: bankGuaranteeBase,
                    bank_guarantee_discount: bankGuaranteeDiscount,
                }

                agentCalculationDetailByAirline[thisAgent][thisAirline] = thisAirlineRecord;
                agentCalculationDetailByAirlineArray[thisAgent].push(thisAirlineRecord);
                // endregion

                // update this agent summary

                thisAgentRecord.sales_deposit = thisAgentRecord.sales_deposit.plus(salesEntry[thisAgent]["deposit"][thisAirline]);
                thisAgentRecord.sales_adjust = thisAgentRecord.sales_adjust.plus(salesEntry[thisAgent]["adjust"][thisAirline]);
                thisAgentRecord.primary_deposit = thisAgentRecord.primary_deposit.plus(primary_deposit);


                thisAgentRecord.excess_deposit = thisAgentRecord.excess_deposit.plus(excess_deposit);

                thisAgentRecord.primary_adjust = thisAgentRecord.primary_adjust.plus(primary_adjust);
                thisAgentRecord.excess_adjust = thisAgentRecord.excess_adjust.plus(excess_adjust);

                // thisAgentRecord.primary_deposit_round_diff = thisAgentRecord.primary_deposit.plus(primary_deposit_round_diff);
                // thisAgentRecord.excess_deposit_round_diff = thisAgentRecord.excess_deposit.plus(excess_deposit_round_diff);
                // thisAgentRecord.primary_adjust_round_diff = thisAgentRecord.primary_adjust.plus(primary_adjust_round_diff);
                // thisAgentRecord.excess_adjust_round_diff = thisAgentRecord.excess_adjust.plus(excess_adjust_round_diff);

                thisAgentRecord.bank_guarantee = thisAgentRecord.bank_guarantee.plus(bankGuarantee[thisAgent][thisAirline]);
                thisAgentRecord.bank_guarantee_used = thisAgentRecord.bank_guarantee_used.plus(bankGuaranteeBase);
                thisAgentRecord.bank_guarantee_discount = thisAgentRecord.bank_guarantee_discount.plus(bankGuaranteeDiscount);

                thisAgentRecord.primary_deposit_actual = thisAgentRecord.primary_deposit.plus(primary_deposit);
                thisAgentRecord.excess_deposit_actual = thisAgentRecord.excess_deposit.plus(excess_deposit);

            }
            /// Adjustment for deposit
            thisAgentRecord.primary_deposit = BigNumber.maximum(thisAgentRecord.primary_deposit, 7000);
            thisAgentRecord.primary_deposit_duty = thisAgentRecord.primary_deposit.multipliedBy(dutyRate.primary).dp(0, dutyAgentRounding);
            thisAgentRecord.primary_deposit_tax = thisAgentRecord.primary_deposit.plus(thisAgentRecord.primary_deposit_duty).multipliedBy(vatRate.primary).dp(2, vatAgentRounding);
            thisAgentRecord.primary_deposit_net = thisAgentRecord.primary_deposit.plus(thisAgentRecord.primary_deposit_duty).plus(thisAgentRecord.primary_deposit_tax);
            thisAgentRecord.excess_deposit_duty = thisAgentRecord.excess_deposit.multipliedBy(dutyRate.excess).dp(0, dutyAgentRounding);
            thisAgentRecord.excess_deposit_tax = thisAgentRecord.excess_deposit.plus(thisAgentRecord.excess_deposit_duty).multipliedBy(vatRate.excess).dp(2, vatAgentRounding);
            thisAgentRecord.excess_deposit_net = thisAgentRecord.excess_deposit.plus(thisAgentRecord.excess_deposit_duty).plus(thisAgentRecord.excess_deposit_tax);

            thisAgentRecord.primary_adjust = BigNumber.maximum(thisAgentRecord.primary_adjust, 7000);

            thisAgentRecord.primary_deposit_record = BigNumber(0);
            thisAgentRecord.excess_deposit_record = BigNumber(0);

            if (thisAgent in depositMap) {

                if (depositMap[thisAgent].primary) {
                    thisAgentRecord.primary_deposit_record = depositMap[thisAgent].primary
                }
                if (depositMap[thisAgent].excess) {
                    thisAgentRecord.excess_deposit_record = depositMap[thisAgent].excess
                }
                thisAgentRecord.primary_real_adjust = thisAgentRecord.primary_adjust.minus(thisAgentRecord.primary_deposit_record)
                thisAgentRecord.excess_real_adjust = thisAgentRecord.excess_adjust.minus(thisAgentRecord.excess_deposit_record)

            } else {
                // console.log("out",thisAgent)
                thisAgentRecord.primary_real_adjust = thisAgentRecord.primary_adjust
                thisAgentRecord.excess_real_adjust = thisAgentRecord.excess_adjust
            }

            thisAgentRecord.primary_adjust_duty = BigNumber.maximum(thisAgentRecord.primary_real_adjust.multipliedBy(dutyRate.primary).dp(0, dutyAgentRounding),0);
            thisAgentRecord.primary_adjust_tax = thisAgentRecord.primary_real_adjust.plus(thisAgentRecord.primary_adjust_duty).multipliedBy(vatRate.primary).dp(2, vatAgentRounding);
            thisAgentRecord.primary_adjust_net = thisAgentRecord.primary_real_adjust.plus(thisAgentRecord.primary_adjust_duty).plus(thisAgentRecord.primary_adjust_tax);
            thisAgentRecord.excess_adjust_duty = BigNumber.maximum(thisAgentRecord.excess_real_adjust.multipliedBy(dutyRate.excess).dp(0, dutyAgentRounding),0);
            thisAgentRecord.excess_adjust_tax = thisAgentRecord.excess_real_adjust.plus(thisAgentRecord.excess_adjust_duty).multipliedBy(vatRate.excess).dp(2, vatAgentRounding);
            thisAgentRecord.excess_adjust_net = thisAgentRecord.excess_real_adjust.plus(thisAgentRecord.excess_adjust_duty).plus(thisAgentRecord.excess_adjust_tax);



            if (exceptionList && (exceptionList.agent.indexOf(thisAgent) < 0)) {
                allAgentDisplaySummary.sales_deposit = allAgentDisplaySummary.sales_deposit.plus(thisAgentRecord.sales_deposit);
                allAgentDisplaySummary.primary_deposit = allAgentDisplaySummary.primary_deposit.plus(thisAgentRecord.primary_deposit);
                allAgentDisplaySummary.primary_deposit_duty = allAgentDisplaySummary.primary_deposit_duty.plus(thisAgentRecord.primary_deposit_duty);
                allAgentDisplaySummary.primary_deposit_tax = allAgentDisplaySummary.primary_deposit_tax.plus(thisAgentRecord.primary_deposit_tax);
                allAgentDisplaySummary.primary_deposit_net = allAgentDisplaySummary.primary_deposit_net.plus(thisAgentRecord.primary_deposit_net);
                allAgentDisplaySummary.excess_deposit = allAgentDisplaySummary.excess_deposit.plus(thisAgentRecord.excess_deposit);
                allAgentDisplaySummary.excess_deposit_duty = allAgentDisplaySummary.excess_deposit_duty.plus(thisAgentRecord.excess_deposit_duty);
                allAgentDisplaySummary.excess_deposit_tax = allAgentDisplaySummary.excess_deposit_tax.plus(thisAgentRecord.excess_deposit_tax);
                allAgentDisplaySummary.excess_deposit_net = allAgentDisplaySummary.excess_deposit_net.plus(thisAgentRecord.excess_deposit_net);

                allAgentDisplaySummary.sales_adjust = allAgentDisplaySummary.sales_adjust.plus(thisAgentRecord.sales_adjust);
                allAgentDisplaySummary.primary_adjust = allAgentDisplaySummary.primary_adjust.plus(thisAgentRecord.primary_adjust);
                allAgentDisplaySummary.primary_real_adjust = allAgentDisplaySummary.primary_real_adjust.plus(thisAgentRecord.primary_real_adjust);
                allAgentDisplaySummary.primary_adjust_duty = allAgentDisplaySummary.primary_adjust_duty.plus(thisAgentRecord.primary_adjust_duty);
                allAgentDisplaySummary.primary_adjust_tax = allAgentDisplaySummary.primary_adjust_tax.plus(thisAgentRecord.primary_adjust_tax);
                allAgentDisplaySummary.primary_adjust_net = allAgentDisplaySummary.primary_adjust_net.plus(thisAgentRecord.primary_adjust_net);
                allAgentDisplaySummary.excess_adjust = allAgentDisplaySummary.excess_adjust.plus(thisAgentRecord.excess_adjust);
                allAgentDisplaySummary.excess_real_adjust = allAgentDisplaySummary.excess_real_adjust.plus(thisAgentRecord.excess_real_adjust);
                allAgentDisplaySummary.excess_adjust_duty = allAgentDisplaySummary.excess_adjust_duty.plus(thisAgentRecord.excess_adjust_duty);
                allAgentDisplaySummary.excess_adjust_tax = allAgentDisplaySummary.excess_adjust_tax.plus(thisAgentRecord.excess_adjust_tax);
                allAgentDisplaySummary.excess_adjust_net = allAgentDisplaySummary.excess_adjust_net.plus(thisAgentRecord.excess_adjust_net);


                let agentStakeholderSharesData = {
                    ...contractTemplate,
                    primaryLayerDeposit: [],
                    excessLayerDeposit: [],
                    primaryLayerAdjust: [],
                    excessLayerAdjust: [],
                }

                allAgentDisplayArray.push(thisAgentRecord);

            } else {
                // console.log("Exception Case :: ",thisAgent.agent_name)
            }
            agentCalculationSummaryArray.push(thisAgentRecord);

            /// REMARK :: WE GOT DEPOSIT and BASIS-ADJUST AT THIS POINT
        }
        // endregion

        // region Loop through agent to create raw Primary and Excess
        for (let index = 0; index < agentCalculationSummaryArray.length; index++) {
            let thisAgentData = agentCalculationSummaryArray[index];

            if (exceptionList.agent.indexOf(thisAgentData.agent_id) >= 0) {
                // console.log("Skipping", thisAgentData.agent_id)
                continue;
            }

            contractData.depositSales = contractData.depositSales.plus(thisAgentData.sales_deposit);
            contractData.primaryDepositAmount = contractData.primaryDepositAmount.plus(thisAgentData.primary_deposit);
            contractData.excessDepositAmount = contractData.excessDepositAmount.plus(thisAgentData.excess_deposit);
            contractData.primaryDepositAmount_round_diff = contractData.primaryDepositAmount.plus(thisAgentData.primary_deposit_round_diff);
            contractData.excessDepositAmount_round_diff = contractData.excessDepositAmount_round_diff.plus(thisAgentData.excess_deposit_round_diff);

            contractData.adjustSales = contractData.adjustSales.plus(thisAgentData.sales_adjust);
            contractData.primaryRealAdjust = contractData.primaryRealAdjust.plus(thisAgentData.primary_real_adjust);
            contractData.primaryDepositRecord = contractData.primaryDepositRecord.plus(thisAgentData.primary_deposit_record);
            contractData.primaryAdjustAmount = contractData.primaryAdjustAmount.plus(thisAgentData.primary_adjust);
            contractData.excessRealAdjust = contractData.excessRealAdjust.plus(thisAgentData.excess_real_adjust);
            contractData.excessDepositRecord = contractData.excessDepositRecord.plus(thisAgentData.excess_deposit_record);
            contractData.excessAdjustAmount = contractData.excessAdjustAmount.plus(thisAgentData.excess_adjust);
            contractData.primaryAdjustAmount_round_diff = contractData.primaryAdjustAmount.plus(thisAgentData.primary_adjust_round_diff);
            contractData.excessAdjustAmount_round_diff = contractData.excessAdjustAmount_round_diff.plus(thisAgentData.excess_adjust_round_diff);
        }
        // calculate contract data from non rounding
        contractData.primaryDepositDuty = contractData.primaryDepositAmount.multipliedBy(dutyRate.primary).dp(0, dutyContractRounding);
        contractData.primaryDepositTax = contractData.primaryDepositAmount.plus(contractData.primaryDepositDuty).multipliedBy(vatRate.primary);
        contractData.primaryDepositNet = contractData.primaryDepositAmount.plus(contractData.primaryDepositDuty).plus(contractData.primaryDepositTax);
        contractData.excessDepositDuty = contractData.excessDepositAmount.multipliedBy(dutyRate.excess).dp(0, dutyContractRounding);
        contractData.excessDepositTax = contractData.excessDepositAmount.plus(contractData.excessDepositDuty).multipliedBy(vatRate.excess);
        contractData.excessDepositNet = contractData.excessDepositAmount.plus(contractData.excessDepositDuty).plus(contractData.excessDepositTax);
        contractData.primaryAdjustDuty = BigNumber.maximum(contractData.primaryRealAdjust.multipliedBy(dutyRate.primary).dp(0, dutyContractRounding),0);
        contractData.primaryAdjustTax = contractData.primaryRealAdjust.plus(contractData.primaryAdjustDuty).multipliedBy(vatRate.primary);
        contractData.primaryAdjustNet = contractData.primaryRealAdjust.plus(contractData.primaryAdjustDuty).plus(contractData.primaryAdjustTax);
        contractData.excessAdjustDuty = BigNumber.maximum(contractData.excessRealAdjust.multipliedBy(dutyRate.excess).dp(0, dutyContractRounding),0);
        contractData.excessAdjustTax = contractData.excessRealAdjust.plus(contractData.excessAdjustDuty).multipliedBy(vatRate.excess);
        contractData.excessAdjustNet = contractData.excessRealAdjust.plus(contractData.excessAdjustDuty).plus(contractData.excessAdjustTax);
        // endregion

        let contractDataDiff = {
            agent_id: "MAIN_DIFF",
            agent_name: "Main Contract Difference",
            sales_deposit: contractData.depositSales.minus(allAgentDisplaySummary.sales_deposit),
            primary_deposit: contractData.primaryDepositAmount.minus(allAgentDisplaySummary.primary_deposit),
            primary_deposit_duty: contractData.primaryDepositDuty.minus(allAgentDisplaySummary.primary_deposit_duty),
            primary_deposit_tax: contractData.primaryDepositTax.minus(allAgentDisplaySummary.primary_deposit_tax),
            primary_deposit_net: contractData.primaryDepositNet.minus(allAgentDisplaySummary.primary_deposit_net),
            excess_deposit: contractData.excessDepositAmount.minus(allAgentDisplaySummary.excess_deposit),
            excess_deposit_duty: contractData.excessDepositDuty.minus(allAgentDisplaySummary.excess_deposit_duty),
            excess_deposit_tax: contractData.excessDepositTax.minus(allAgentDisplaySummary.excess_deposit_tax),
            excess_deposit_net: contractData.excessDepositNet.minus(allAgentDisplaySummary.excess_deposit_net),

            sales_adjust: contractData.adjustSales.minus(allAgentDisplaySummary.sales_adjust),
            primary_adjust: contractData.primaryRealAdjust.minus(allAgentDisplaySummary.primary_real_adjust),
            primary_adjust_duty: contractData.primaryAdjustDuty.minus(allAgentDisplaySummary.primary_adjust_duty),
            primary_adjust_tax: contractData.primaryAdjustTax.minus(allAgentDisplaySummary.primary_adjust_tax),
            primary_adjust_net: contractData.primaryAdjustNet.minus(allAgentDisplaySummary.primary_adjust_net),
            excess_adjust: contractData.excessRealAdjust.minus(allAgentDisplaySummary.excess_real_adjust),
            excess_adjust_duty: contractData.excessAdjustDuty.minus(allAgentDisplaySummary.excess_adjust_duty),
            excess_adjust_tax: contractData.excessAdjustTax.minus(allAgentDisplaySummary.excess_adjust_tax),
            excess_adjust_net: contractData.excessAdjustNet.minus(allAgentDisplaySummary.excess_adjust_net),
        }

        // region calculate lead-padding then calculate stake holder data
        // region precalculate-diff
        let primaryContractDepositSummation = BigNumber(0);
        let excessContractDepositSummation = BigNumber(0);
        let primaryDutyDepositSummation = BigNumber(0);
        let excessDutyDepositSummation = BigNumber(0);
        let primaryContractAdjustSummation = BigNumber(0);
        let excessContractAdjustSummation = BigNumber(0);
        let primaryDutyAdjustSummation = BigNumber(0);
        let excessDutyAdjustSummation = BigNumber(0);
        for (let primary of primaryLayer) {
            let depSum = contractData.primaryDepositAmount.multipliedBy(primary.rate).dividedBy(100).dp(0, premiumStakeholderRounding)
            let adjSum = contractData.primaryRealAdjust.multipliedBy(primary.rate).dividedBy(100).dp(0, premiumStakeholderRounding)
            let depDuty = depSum.multipliedBy(dutyRate.primary).dp(0, dutyStakeholderRounding)
            let adjDuty = adjSum.multipliedBy(dutyRate.primary).dp(0, dutyStakeholderRounding)
            // console.log("PrimaryCalc",depSum.toFixed(2),adjSum.toFixed(2),depDuty.toFixed(2),adjDuty.toFixed(2))
            primaryContractDepositSummation = primaryContractDepositSummation.plus(depSum)
            primaryDutyDepositSummation = primaryDutyDepositSummation.plus(BigNumber.maximum(depDuty,0))
            primaryContractAdjustSummation = primaryContractAdjustSummation.plus(adjSum)
            primaryDutyAdjustSummation = primaryDutyAdjustSummation.plus(BigNumber.maximum(adjDuty,0))
        }
        for (let excess of excessLayer) {
            let depSum = contractData.excessDepositAmount.multipliedBy(excess.rate).dividedBy(100).dp(0, premiumStakeholderRounding)
            let adjSum = contractData.excessRealAdjust.multipliedBy(excess.rate).dividedBy(100).dp(0, premiumStakeholderRounding)
            let depDuty = depSum.multipliedBy(dutyRate.excess).dp(0, dutyStakeholderRounding)
            let adjDuty = adjSum.multipliedBy(dutyRate.excess).dp(0, dutyStakeholderRounding)
            // console.log("ExcessCalc",depSum.toFixed(2),adjSum.toFixed(2),depDuty.toFixed(2),adjDuty.toFixed(2))
            excessContractDepositSummation = excessContractDepositSummation.plus(depSum)
            excessDutyDepositSummation = excessDutyDepositSummation.plus(BigNumber.maximum(depDuty,0))
            excessContractAdjustSummation = excessContractAdjustSummation.plus(adjSum)
            excessDutyAdjustSummation = excessDutyAdjustSummation.plus(BigNumber.maximum(adjDuty,0))
        }


        let primaryContractDepositDiff = contractData.primaryDepositAmount.minus(primaryContractDepositSummation);
        let excessContractDepositDiff = contractData.excessDepositAmount.minus(excessContractDepositSummation);
        let primaryDutyDepositDiff = contractData.primaryDepositDuty.minus(primaryDutyDepositSummation);
        let excessDutyDepositDiff = contractData.excessDepositDuty.minus(excessDutyDepositSummation);
        let primaryContractAdjustDiff = contractData.primaryRealAdjust.minus(primaryContractAdjustSummation);
        let excessContractAdjustDiff = contractData.excessRealAdjust.minus(excessContractAdjustSummation);
        let primaryDutyAdjustDiff = contractData.primaryAdjustDuty.minus(primaryDutyAdjustSummation);
        let excessDutyAdjustDiff = contractData.excessAdjustDuty.minus(excessDutyAdjustSummation);
        // console.log("PrimaryDuty",contractData.primaryAdjustDuty.toFixed(2),primaryDutyAdjustSummation.toFixed(2),primaryDutyAdjustDiff.toFixed(2));
        console.log("PrimaryAdjustDuty",contractData.primaryAdjustDuty.toFixed(2),primaryDutyAdjustSummation.toFixed(2),primaryDutyAdjustDiff.toFixed(2));
        console.log("ExcessAdjustDuty",contractData.excessAdjustDuty.toFixed(2),excessDutyAdjustSummation.toFixed(2),excessDutyAdjustDiff.toFixed(2));
        // console.log(primaryDutyAdjustSummation.toFixed(2),excessDutyAdjustSummation.toFixed(2))
        // console.log("PDepositDiff",primaryContractDepositSummation,primaryContractDepositDiff,"EDepositDiff",excessContractDepositSummation,excessContractDepositDiff);
        // console.log("PDepositDiff",primaryDutyDepositSummation.toFixed(2),primaryDutyDepositDiff.toFixed(2),"EDepositDiff",excessDutyDepositSummation.toFixed(2),excessDutyDepositDiff.toFixed(2));
        // endregion

        for (let eachStakeholder of stakeholderData) {
            let deposit, duty, tax, net;
            let adjust, adjustDuty, adjustTax, adjustNet;
            // Primary Layer
            if (eachStakeholder.isPrimary) {
                deposit = contractData.primaryDepositAmount.multipliedBy(eachStakeholder.primaryRate).dividedBy(100).dp(0, premiumStakeholderRounding);
                adjust = contractData.primaryRealAdjust.multipliedBy(eachStakeholder.primaryRate).dividedBy(100).dp(0, premiumStakeholderRounding);
                if (eachStakeholder.isPrimaryLead) {
                    deposit = deposit.plus(primaryContractDepositDiff)
                    adjust = adjust.plus(primaryContractAdjustDiff)
                }
                duty = deposit.multipliedBy(dutyRate.primary).dp(0, dutyStakeholderRounding);
                adjustDuty = adjust.multipliedBy(dutyRate.primary).dp(0, dutyStakeholderRounding);
                if (eachStakeholder.isPrimaryLead) {
                    duty = duty.plus(primaryDutyDepositDiff)
                    adjustDuty = adjustDuty.plus(primaryDutyAdjustDiff)
                }
                tax = deposit.plus(duty).multipliedBy(vatRate.primary);
                net = deposit.plus(duty).plus(tax);
                adjustTax = adjust.plus(adjustDuty).multipliedBy(vatRate.primary);
                adjustNet = adjust.plus(adjustDuty).plus(adjustTax);
                contractData.primaryLayerDeposit.push({
                    ...eachStakeholder, deposit, duty, tax, net
                })
                contractData.primaryLayerAdjust.push({
                    ...eachStakeholder, adjust: adjust, duty: adjustDuty, tax: adjustTax, net: adjustNet
                })
            }

            // Excess Layer
            if (eachStakeholder.isExcess) {
                deposit = contractData.excessDepositAmount.multipliedBy(eachStakeholder.excessRate).dividedBy(100).dp(0, premiumStakeholderRounding);
                adjust = contractData.excessRealAdjust.multipliedBy(eachStakeholder.excessRate).dividedBy(100).dp(0, premiumStakeholderRounding);
                if (eachStakeholder.isExcessLead) {
                    deposit = deposit.plus(excessContractDepositDiff)
                    adjust = adjust.plus(excessContractAdjustDiff)
                }
                duty = deposit.multipliedBy(dutyRate.excess).dp(0, dutyStakeholderRounding)
                adjustDuty = adjust.multipliedBy(dutyRate.excess).dp(0, dutyStakeholderRounding)
                if (eachStakeholder.isExcessLead) {
                    duty = duty.plus(excessDutyDepositDiff)
                    adjustDuty = adjustDuty.plus(excessDutyAdjustDiff)
                }
                tax = deposit.plus(duty).multipliedBy(vatRate.excess);
                net = deposit.plus(duty).plus(tax);
                adjustTax = adjust.plus(adjustDuty).multipliedBy(vatRate.excess);
                adjustNet = adjust.plus(adjustDuty).plus(adjustTax);
                contractData.excessLayerDeposit.push({
                    ...eachStakeholder, deposit, duty, tax, net
                })
                contractData.excessLayerAdjust.push({
                    ...eachStakeholder, adjust: adjust, duty: adjustDuty, tax: adjustTax, net: adjustNet
                })
            }
        }

        //endregion

        // TODO : CALCULATION PER AGENT/STAKE-HOLDER
        contractData.primaryLayerDeposit.forEach((item) => {
            item.calcDeposit = BigNumber(0);
            item.calcDuty = BigNumber(0);
            item.calcTax = BigNumber(0);
            item.calcNet = BigNumber(0);
            item.agentList = [];
            for (let thisAgent of allAgentDisplayArray) {
                let thisDeposit = thisAgent.primary_deposit.multipliedBy(item.primaryRate).dividedBy(100).dp(0, premiumStakeholderRounding)
                let thisDuty = thisDeposit.multipliedBy(dutyRate.primary).dp(0, dutyStakeholderRounding);
                let thisTax = thisDeposit.plus(thisDuty).multipliedBy(vatRate.primary);
                let thisNet = thisDeposit.plus(thisDuty).plus(thisTax);
                item.calcDeposit = item.calcDeposit.plus(thisDeposit);
                item.calcDuty = item.calcDuty.plus(thisDuty);
                item.calcTax = item.calcTax.plus(thisTax);
                item.calcNet = item.calcNet.plus(thisNet);
                item.agentList.push({
                    agent_id: thisAgent.agent_id,
                    agent_name: thisAgent.agent_name,
                    deposit: thisDeposit,
                    duty: thisDuty,
                    tax: thisTax,
                    net: thisNet,
                })
            }
            item.depositCarry = item.deposit.minus(item.calcDeposit);
            item.dutyCarry = item.duty.minus(item.calcDuty);
            item.taxCarry = item.tax.minus(item.calcTax);
            item.netCarry = item.net.minus(item.calcNet);
        })
        contractData.excessLayerDeposit.forEach((item) => {
            item.calcDeposit = BigNumber(0);
            item.calcDuty = BigNumber(0);
            item.calcTax = BigNumber(0);
            item.calcNet = BigNumber(0);
            item.agentList = [];
            for (let thisAgent of allAgentDisplayArray) {
                let thisDeposit = thisAgent.excess_deposit.multipliedBy(item.excessRate).dividedBy(100).dp(0, premiumStakeholderRounding)
                let thisDuty = thisDeposit.multipliedBy(dutyRate.excess).dp(0, dutyStakeholderRounding);
                let thisTax = thisDeposit.plus(thisDuty).multipliedBy(vatRate.excess);
                let thisNet = thisDeposit.plus(thisDuty).plus(thisTax);
                item.calcDeposit = item.calcDeposit.plus(thisDeposit);
                item.calcDuty = item.calcDuty.plus(thisDuty);
                item.calcTax = item.calcTax.plus(thisTax);
                item.calcNet = item.calcNet.plus(thisNet);
                item.agentList.push({
                    agent_id: thisAgent.agent_id,
                    agent_name: thisAgent.agent_name,
                    deposit: thisDeposit,
                    duty: thisDuty,
                    tax: thisTax,
                    net: thisNet,
                })
            }
            item.depositCarry = item.deposit.minus(item.calcDeposit);
            item.dutyCarry = item.duty.minus(item.calcDuty);
            item.taxCarry = item.tax.minus(item.calcTax);
            item.netCarry = item.net.minus(item.calcNet);
        })

        contractData.primaryLayerAdjust.forEach((item) => {
            item.calcAdjust = BigNumber(0);
            item.calcDuty = BigNumber(0);
            item.calcTax = BigNumber(0);
            item.calcNet = BigNumber(0);
            item.agentList = [];
            for (let thisAgent of allAgentDisplayArray) {
                let thisAdjust = thisAgent.primary_real_adjust.multipliedBy(item.primaryRate).dividedBy(100).dp(0, premiumStakeholderRounding)
                let thisDuty = BigNumber.maximum(thisAdjust.multipliedBy(dutyRate.primary).dp(0, dutyStakeholderRounding),0);
                let thisTax = thisAdjust.plus(thisDuty).multipliedBy(vatRate.primary);
                let thisNet = thisAdjust.plus(thisDuty).plus(thisTax);
                item.calcAdjust = item.calcAdjust.plus(thisAdjust);
                item.calcDuty = item.calcDuty.plus(thisDuty);
                item.calcTax = item.calcTax.plus(thisTax);
                item.calcNet = item.calcNet.plus(thisNet);
                item.agentList.push({
                    agent_id: thisAgent.agent_id,
                    agent_name: thisAgent.agent_name,
                    adjust: thisAdjust,
                    duty: thisDuty,
                    tax: thisTax,
                    net: thisNet,
                })
            }
            item.adjustCarry = item.adjust.minus(item.calcAdjust);
            item.dutyCarry = item.duty.minus(item.calcDuty);
            item.taxCarry = item.tax.minus(item.calcTax);
            item.netCarry = item.net.minus(item.calcNet);
        })
        contractData.excessLayerAdjust.forEach((item) => {
            item.calcAdjust = BigNumber(0);
            item.calcDuty = BigNumber(0);
            item.calcTax = BigNumber(0);
            item.calcNet = BigNumber(0);
            item.agentList = [];
            for (let thisAgent of allAgentDisplayArray) {
                let thisAdjust = thisAgent.excess_real_adjust.multipliedBy(item.excessRate).dividedBy(100).dp(0, premiumStakeholderRounding)
                let thisDuty = BigNumber.maximum(thisAdjust.multipliedBy(dutyRate.excess).dp(0, dutyStakeholderRounding),0);
                let thisTax = thisAdjust.plus(thisDuty).multipliedBy(vatRate.excess);
                let thisNet = thisAdjust.plus(thisDuty).plus(thisTax);
                item.calcAdjust = item.calcAdjust.plus(thisAdjust);
                item.calcDuty = item.calcDuty.plus(thisDuty);
                item.calcTax = item.calcTax.plus(thisTax);
                item.calcNet = item.calcNet.plus(thisNet);
                item.agentList.push({
                    agent_id: thisAgent.agent_id,
                    agent_name: thisAgent.agent_name,
                    adjust: thisAdjust,
                    duty: thisDuty,
                    tax: thisTax,
                    net: thisNet,
                })
            }
            item.adjustCarry = item.adjust.minus(item.calcAdjust);
            item.dutyCarry = item.duty.minus(item.calcDuty);
            item.taxCarry = item.tax.minus(item.calcTax);
            item.netCarry = item.net.minus(item.calcNet);
        })

        // Third loop calculate
        for (let index = 0; index < agentCalculationSummaryArray.length; index++) {
            /// Calculate agent stakeholder
            let thisAgentRecord = agentCalculationSummaryArray[index];
            thisAgentRecord.stakeholderInfo = {};
            thisAgentRecord.stakeholderArray = {primary: [], excess: []};

            for (let eachStakeholder of stakeholderData) {
                if (eachStakeholder.isPrimary) {
                    let actualDeposit = thisAgentRecord.primary_deposit.multipliedBy(eachStakeholder.rate).dividedBy(100)
                    let requestDeposit = actualDeposit.decimalPlaces(0, premiumStakeholderRounding);
                    let depositOverpaid = requestDeposit.isGreaterThan(actualDeposit) ? requestDeposit.minus(actualDeposit) : BigNumber(0);
                    let depositUnderpaid = requestDeposit.isLessThan(actualDeposit) ? requestDeposit.minus(actualDeposit) : BigNumber(0);
                    let depositDuty = BigNumber.maximum(requestDeposit.multipliedBy(dutyRate.primary).decimalPlaces(0, dutyStakeholderRounding), 0);
                    let depositVat = requestDeposit.plus(depositDuty).multipliedBy(vatRate.primary).decimalPlaces(2, vatStakeholderRounding);
                    let depositNet = requestDeposit.plus(depositDuty).plus(depositVat);
                    let actualAdjust = thisAgentRecord.primary_adjust.multipliedBy(eachStakeholder.rate).dividedBy(100)
                    let requestAdjust = actualAdjust.decimalPlaces(0, premiumStakeholderRounding);
                    let adjustOverpaid = requestAdjust.isGreaterThan(actualAdjust) ? requestAdjust.minus(actualAdjust) : BigNumber(0);
                    let adjustUnderpaid = requestAdjust.isLessThan(actualAdjust) ? requestAdjust.minus(actualAdjust) : BigNumber(0);
                    let adjustDuty = BigNumber.maximum(requestDeposit.multipliedBy(dutyRate.adjust).decimalPlaces(0, dutyStakeholderRounding), 0);
                    let adjustVat = requestAdjust.plus(adjustDuty).multipliedBy(vatRate.adjust).decimalPlaces(2, vatStakeholderRounding);
                    let adjustNet = requestAdjust.plus(adjustDuty).plus(adjustVat);
                }
            }
            // Primary Layer Loop
            // for (let eachStakeholder of primaryLayer) {
            //     let actualDeposit = thisAgentRecord.primary_deposit.multipliedBy(eachStakeholder.rate).dividedBy(100)
            //     let requestDeposit = actualDeposit.decimalPlaces(0, premiumStakeholderRounding);
            //     let depositOverpaid = requestDeposit.isGreaterThan(actualDeposit) ? requestDeposit.minus(actualDeposit) : BigNumber(0);
            //     let depositUnderpaid = requestDeposit.isLessThan(actualDeposit) ? requestDeposit.minus(actualDeposit) : BigNumber(0);
            //     let depositDuty = BigNumber.maximum(requestDeposit.multipliedBy(dutyRate.primary).decimalPlaces(0, dutyStakeholderRounding), 0);
            //     let depositVat = requestDeposit.plus(depositDuty).multipliedBy(vatRate.primary).decimalPlaces(2, vatStakeholderRounding);
            //     let depositNet = requestDeposit.plus(depositDuty).plus(depositVat);
            //     let actualAdjust = thisAgentRecord.primary_adjust.multipliedBy(eachStakeholder.rate).dividedBy(100)
            //     let requestAdjust = actualAdjust.decimalPlaces(0, premiumStakeholderRounding);
            //     let adjustOverpaid = requestAdjust.isGreaterThan(actualAdjust) ? requestAdjust.minus(actualAdjust) : BigNumber(0);
            //     let adjustUnderpaid = requestAdjust.isLessThan(actualAdjust) ? requestAdjust.minus(actualAdjust) : BigNumber(0);
            //     let adjustDuty = BigNumber.maximum(requestDeposit.multipliedBy(dutyRate.adjust).decimalPlaces(0, dutyStakeholderRounding), 0);
            //     let adjustVat = requestAdjust.plus(adjustDuty).multipliedBy(vatRate.adjust).decimalPlaces(2, vatStakeholderRounding);
            //     let adjustNet = requestAdjust.plus(adjustDuty).plus(adjustVat);
            //     thisAgentRecord.stakeholderArray.primary.push({
            //         ...eachStakeholder,
            //         key: eachStakeholder.id + "_pL",
            //         deposit: requestDeposit,
            //         actualDeposit,
            //         depositOverpaid,
            //         depositUnderpaid,
            //         depositDuty,
            //         depositVat,
            //         depositNet,
            //         adjust: requestAdjust,
            //         actualAdjust,
            //         adjustOverpaid,
            //         adjustUnderpaid,
            //         adjustDuty,
            //         adjustVat,
            //         adjustNet
            //     })
            // }
            // Excess Layer Loop
            // for (let eachStakeholder of excessLayer) {
            //     let actualDeposit = thisAgentRecord.excess_deposit.multipliedBy(eachStakeholder.rate).dividedBy(100)
            //     let requestDeposit = actualDeposit.decimalPlaces(0, BigNumber.ROUND_UP);
            //     let depositOverpaid = requestDeposit.isGreaterThan(actualDeposit) ? requestDeposit.minus(actualDeposit) : BigNumber(0);
            //     let depositUnderpaid = requestDeposit.isLessThan(actualDeposit) ? requestDeposit.minus(actualDeposit) : BigNumber(0);
            //     let depositDuty = BigNumber.maximum(requestDeposit.multipliedBy(dutyRate.excess).decimalPlaces(0, BigNumber.ROUND_UP), 0);
            //     let depositVat = requestDeposit.plus(depositDuty).multipliedBy(vatRate.excess).decimalPlaces(2, BigNumber.ROUND_UP);
            //     let depositNet = requestDeposit.plus(depositDuty).plus(depositVat);
            //     let actualAdjust = thisAgentRecord.excess_adjust.multipliedBy(eachStakeholder.rate).dividedBy(100)
            //     let requestAdjust = actualAdjust.decimalPlaces(0, BigNumber.ROUND_UP);
            //     let adjustOverpaid = requestAdjust.isGreaterThan(actualAdjust) ? requestAdjust.minus(actualAdjust) : BigNumber(0);
            //     let adjustUnderpaid = requestAdjust.isLessThan(actualAdjust) ? requestAdjust.minus(actualAdjust) : BigNumber(0);
            //     let adjustDuty = BigNumber.maximum(requestDeposit.multipliedBy(dutyRate.adjust).decimalPlaces(0, BigNumber.ROUND_UP), 0);
            //     let adjustVat = requestAdjust.plus(adjustDuty).multipliedBy(vatRate.adjust).decimalPlaces(2, BigNumber.ROUND_UP);
            //     let adjustNet = requestAdjust.plus(adjustDuty).plus(adjustVat);
            //     thisAgentRecord.stakeholderArray.excess.push({
            //         ...eachStakeholder,
            //         key: eachStakeholder.id + "_eL",
            //         deposit: requestDeposit,
            //         actualDeposit,
            //         depositOverpaid,
            //         depositUnderpaid,
            //         depositDuty,
            //         depositVat,
            //         depositNet,
            //         adjust: requestAdjust,
            //         actualAdjust,
            //         adjustOverpaid,
            //         adjustUnderpaid,
            //         adjustDuty,
            //         adjustVat,
            //         adjustNet
            //     })
            // }

            agentCalculationSummaryArray[index] = thisAgentRecord;
        }

        // Populate summary by agent
        for (let thisAgentRecord of agentCalculationSummaryArray) {
            agentCalculationSummaryByAgent[thisAgentRecord.agent_id] = thisAgentRecord;
        }


        // console.log(agentCalculationSummaryByAgent["all"]);

        return {
            bankGuarantee,
            bankGuaranteeSummary,
            salesEntry,
            salesEntrySummary,
            availableAirline,
            availableAgent,
            agentCalculationDetailByAirline,
            agentCalculationDetailByAirlineArray,
            agentCalculationSummaryByAgent,
            agentCalculationSummaryArray,
            allAgentDisplayArray,
            allAgentDisplaySummary,
            primaryLayer,
            excessLayer,
            contractData,
            contractDataDiff,
            depositMap,
        }

    });
