import fetch from 'isomorphic-fetch';
import {checkStatus} from "../../utility/fetch_preprocessor";
import stringify from 'json-stringify-safe';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// const serverPrefix = "http://localhost:3000";
const serverPrefix = "";
const authString = "";


export function getDipData() {
    // const q = encodeURIComponent(data);
    // console.log("Suggesting Text : ",text);
    return fetch(serverPrefix + `/dip/getCurrentDipData`,
        {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            }
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function getDipSalesData(selection) {
    // const q = encodeURIComponent(data);
    // console.log("Suggesting Text : ",text);
    console.log(selection);
    return fetch(serverPrefix + `/dip/getRealtimeSalesData`,
        {
            mode: 'cors',
            method: 'post',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
            body:stringify({
                depositPeriod:selection.depositString,
                adjustPeriod:selection.adjustString,
                periodType:selection.periodType,
                periodYear:selection.periodYear,
            }),
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function updateDipData() {
    return  fetch(`/dip/updateDipData`,
        {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function updateDipInfoData(updateData) {
    return  fetch(`/dip/updateDipInfoData`,
        {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
            body:stringify(updateData),

        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function updateDipDepositData(updateData) {
    return  fetch(`/dip/updateDipDepositData`,
        {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
            body:stringify(updateData),

        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}

export function resetStakeholderData() {
    return  fetch(`/dip/resetStakeholderData`,
        {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
}


