import React from 'react';
import ReactDOM from 'react-dom';

import {Layout, Menu,Button} from "antd";
import {
    DesktopOutlined, BarChartOutlined, FileOutlined,
    PieChartOutlined, TeamOutlined, ShopOutlined, ProfileOutlined,
    SettingOutlined, UserOutlined, UserAddOutlined, UsergroupAddOutlined,
    LogoutOutlined
} from "@ant-design/icons";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {baseDataActionFn} from "../../redux/basedata/actions";

import {permissionParser,permissionChecker} from "../../utility/permissionHelper"
import {authenticatorActionFn} from "../../redux/auth/actions";
const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

const defaultPermission = ["ADMIN", "THAISTAR", "LOGIN"];

class userMenu extends React.Component {
    state = {selectedMenu: "baseStore"}

    render() {
        const currentLocalUser = this.props.auth;
        const currentPermission = permissionParser(currentLocalUser);
        const {history} = this.props;

        const menuItem = [
            {
                key: "dataManagement",
                name: "Data/Function Management",
                icon: <UserOutlined/>,
                permission: defaultPermission,
                children: [
                    // {
                    //     key: "Self-service",
                    //     name: "Accounts",
                    //     icon: <UserOutlined/>,
                    //     permission: defaultPermission,
                    //     url: "/account/myaccount"
                    // },
                    // {
                    //     key: "userAccount",
                    //     name: "แก้ไขข้อมูลส่วนบุคคล",
                    //     icon: <UserOutlined/>,
                    //     permission: defaultPermission,
                    //     url: "/account/userprofile"
                    // },
                    // {
                    //     key: "userDocument",
                    //     name: "นำส่ง/เรียกดูเอกสาร",
                    //     icon: <UserOutlined/>,
                    //     permission: defaultPermission,
                    //     url: "/account/document"
                    // },
                    {
                        key: "exclusionList",
                        name: "แก้ไขรายการยกเว้น",
                        icon: <UserOutlined/>,
                        permission: ['ADMIN'],
                        url: "/data/exclusion"
                    },
                    // {
                    //     key: "UserPermission",
                    //     url: "/account/permission",
                    //     name: "Account Permissions",
                    //     permission: defaultPermission,
                    //     icon: <UserAddOutlined />,
                    // },
                    // {
                    //     key: "UserGroup",
                    //     url: "/account/group",
                    //     name: "Groups",
                    //     permission: defaultPermission,
                    //     icon: <TeamOutlined/>,
                    // },
                    // {
                    //     key: "GroupPermission",
                    //     url: "/account/group_permission",
                    //     name: "Group Permissions",
                    //     permission: defaultPermission,
                    //     icon: <UsergroupAddOutlined />,
                    // },
                    //
                    // {
                    //     key: "PermissionList",
                    //     url: "/account/permission_list",
                    //     name: "Permission List",
                    //     permission: defaultPermission,
                    //     icon: <ProfileOutlined/>,
                    // },
                ]
            },
        ]
        // console.log(this.props)

        return <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline"
                     key={"userManagementApi"}
                     style={{marginTop: -4}}
                     selectedKeys={this.props.baseData.currentMenu}
                     onSelect={(data) => {
                         // console.log(data.key);
                         this.props.basedataSetCurrentMenu(data.key)
                     }}>
            {
                menuItem.map(menuRow => {
                    // console.log("menu permission", menuRow.permission);
                    // console.log("current permission", currentPermission);
                    // console.log("menu permission check", menuRow.permission.some(r => currentPermission.includes(r)));
                    if (permissionChecker(currentPermission,menuRow.permission)) {
                        if (menuRow.children) {
                            return <SubMenu key={menuRow.key} icon={menuRow.icon} title={menuRow.name}>
                                {menuRow.children.map(subMenuRow => {
                                    if (permissionChecker(currentPermission,subMenuRow.permission)) {
                                        if (subMenuRow.children) {
                                            return <SubMenu key={menuRow.key+subMenuRow.key} icon={subMenuRow.icon}
                                                            title={subMenuRow.name}>
                                                {subMenuRow.children.map(subSubMenuRow => {
                                                   if (permissionChecker(currentPermission,subSubMenuRow.permission)) {
                                                        return <Menu.Item key={menuRow.key+subMenuRow.key+subSubMenuRow.key}
                                                                          icon={subSubMenuRow.icon}>
                                                            <NavLink to={subSubMenuRow.url}>
                                                                {subSubMenuRow.name}
                                                            </NavLink>
                                                        </Menu.Item>
                                                    }
                                                    return <React.Fragment key={menuRow.key+subMenuRow.key+"SEP"}></React.Fragment>
                                                })}
                                            </SubMenu>
                                        } else {
                                            return <Menu.Item key={subMenuRow.key} icon={subMenuRow.icon}>
                                                <NavLink to={subMenuRow.url}>
                                                    {subMenuRow.name}
                                                </NavLink>
                                            </Menu.Item>
                                        }
                                    }
                                    return null

                                    // return <Menu.Item key={subMenuRow.key} icon={subMenuRow.icon}>
                                    //     <NavLink to={subMenuRow.url}>
                                    //         {subMenuRow.name}
                                    //     </NavLink>
                                    // </Menu.Item>
                                })}
                            </SubMenu>
                        } else {
                            return <Menu.Item key={menuRow.key} icon={menuRow.icon}>
                                <NavLink to={menuRow.url}>
                                    {menuRow.name}
                                </NavLink>
                            </Menu.Item>
                        }
                    }
                    return null
                })
            }
        </Menu>
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        baseData: state.BaseData,
        auth: state.Auth,
        // statement: state.Statement,

    }
}

let userMenuWithRouter = withRouter(userMenu)
export default connect(mapStateToProps, {...baseDataActionFn,
    ...authenticatorActionFn})(userMenuWithRouter);
