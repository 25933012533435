import React, {Component} from 'react';
import {
    Col, Row, Icon, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, Form, Breadcrumb, Layout,
    Button,
} from 'antd';
import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import Helmet from "react-helmet";
import ReactQuill,{Quill} from "react-quill";
import 'react-quill/dist/quill.snow.css';
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';

// const {TextArea} = Input;
// const FormItem = Form.Item;
// const uuidv4 = require('uuid/v4');
// const {Option, OptGroup} = Select;
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";

const {Header, Content, Footer} = Layout;

const moment = Moment;

let todayMoment = new moment();


class Announcement extends Component {
    state = {
        mode: "view",
        htmlData: "",
    };

    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        }
    };

    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });


    componentDidMount() {


        // console.log(this.formData);
    }


    render() {
        return (
            <Content style={{padding: '0 50px', marginTop: 64}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>ข่าวประชาสัมพันธ์ทั่วไป</Breadcrumb.Item>
                </Breadcrumb>
                <Helmet>
                    ประกาศข่าวสารทั่วไป
                </Helmet>
                {(this.state.mode === "view") &&
                <>
                    {this.state.htmlData}
                    <Button onClick={() => {
                        this.setState({mode: "edit"})
                    }}>Edit</Button>
                </>
                }
                {(this.state.mode === "edit") &&
                <>
                    <ReactQuill theme="snow" value={this.state.htmlData} onChange={(x) => {
                        console.log(x)
                    }}/>
                    <Button onClick={() => {
                        this.setState({mode: "view"})
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        this.setState({mode: "view"})
                    }}>Save</Button>
                </>
                }

            </Content>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {}
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
// export default connect(mapStateToProps, {})(WrappedFormWIthSubmissionButton);
export default connect(mapStateToProps, {})(Announcement);
//export default ;
