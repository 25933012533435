import React from 'react';
import ReactDOM from 'react-dom';
import {
    Layout, Col, Row, Tabs, Table, Button, Input, Card,
    Icon, Box, Radio, Form, Select, Checkbox, Breadcrumb,
    DatePicker, Typography, Affix, Statistic
    // Menu, Breadcrumb, Highlight,
} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {authenticatorActionFn} from "../../redux/auth/actions";

import update from "immutability-helper"
import _ from "lodash";
import moment from 'moment-timezone';



const agGridTheme = 'ag-theme-fresh';
const validator = require('validator');
const passwordLength = {min:5,max:32};

// moment.tz.setDefault("Asia/Bangkok");
moment.tz.setDefault("UTC"); /// This page use server UTC

const {TabPane} = Tabs;
const {Option} = Select;
const {Header, Content, Footer} = Layout;
const {RangePicker} = DatePicker;
const {Text} = Typography;



class LoginForm extends React.Component {

    state = {
        currentForm: "signin",

        signin: {
            username: "",
            password: "",
        },

        signup: {
            username: "",
            password: "",
            passwordConfirm: "",
            context: "",
        }


    };

    constructor(props) {
        super(props);
        this.loginContainer = React.createRef();
    }

    componentDidMount() {
        this.props.checkAuthorization();
    }

    render() {

        require("./login.css");

        const onFinish = (values) => {
            console.log('Received values of form: ', values);
        };

        const formItemLayout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        }

        const checkSignupForm = () => {
            const signUpValidator = validator.isEmail(this.state.signup.email)
            console.log("Validation Run",signUpValidator)
            return signUpValidator
        }

        const validateEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        return (
            <div id="login-form-container"
                 style={{
                     align: "center", flexDirection: "column",
                     display: "flex", margin: "-20px 0 50px",
                     height: "100vh",
                     justifyContent: "center",
                     alignItems: "center"
                 }}>
                <div className={(this.state.currentForm === "signup") ? "container right-panel-active" : "container"}
                     id="login-form" ref={this.loginContainer}>
                    <div className="form-container sign-up-container">
                        <Form className="login-form" {...formItemLayout}>
                            <h1>Sign up</h1>
                            <h3>Your email address will be your username.</h3>
                            <br/>
                            {/*<Input type="text" placeholder="Username or Email"/>*/}
                            <Input type="text" value={this.state.signup.username} placeholder="Email"
                                   className={validator.isEmail(this.state.signup.username) ? "":"fail"}
                                    // className="fail"
                                   onChange={(e) => {
                                       this.setState({signup: update(this.state.signup, {username: {$set: e.target.value}})})
                                   }}/>
                            <Input type="password" value={this.state.signup.password} placeholder="Password"
                                   className={validator.isLength(this.state.signup.password,passwordLength) ? "":"fail"}
                                   onChange={(e) => {
                                       this.setState({signup: update(this.state.signup, {password: {$set: e.target.value}})})
                                   }}/>
                            <Input type="password" value={this.state.signup.passwordConfirm} placeholder="Password-Confirm"
                                   className={
                                       (validator.equals(this.state.signup.password,this.state.signup.passwordConfirm) &&
                                       validator.isLength(this.state.signup.password,passwordLength))? "":"fail"}
                                   onChange={(e) => {
                                       this.setState({signup: update(this.state.signup, {passwordConfirm: {$set: e.target.value}})})
                                   }}/>
                            <Input type="text" value={this.state.signup.context} placeholder="Context (รหัสสาขา)"
                                   onChange={(e) => {
                                       this.setState({signup: update(this.state.signup, {context: {$set: e.target.value}})})
                                   }}/>
                            <br/>
                            <button
                                onClick={() => {
                                    if (this.state.signup.passwordConfirm === this.state.signup.password) {
                                        this.props.signup(this.state.signup)
                                    }
                                }}
                                disabled={!(
                                    (validator.equals(this.state.signup.password,this.state.signup.passwordConfirm)) &&
                                    (validator.isLength(this.state.signup.password,passwordLength)) &&
                                    (validator.isEmail(this.state.signup.username))
                                )}
                            >Sign Up
                            </button>
                        </Form>
                    </div>
                    <div className="form-container sign-in-container">
                        <Form className="login-form" {...formItemLayout}>
                            <h1>Sign in</h1>
                            <h3>Please sign-in using your username/password</h3>
                            <br/>
                            <Input type="text" placeholder="Username or Email"
                                   value={this.state.signin.username}
                                   onChange={(e) => {
                                       this.setState({signin: update(this.state.signin, {username: {$set: e.target.value}})})
                                   }}/>
                            <Input type="password" placeholder="Password"
                                   value={this.state.signin.password}
                                   onChange={(e) => {
                                       this.setState({signin: update(this.state.signin, {password: {$set: e.target.value}})})
                                   }}/>
                            <br/>

                            <button
                                onClick={() => {this.props.login(this.state.signin)}}
                                disabled={!(
                                    (validator.isLength(this.state.signin.password,passwordLength)) &&
                                    (!validator.isEmpty(this.state.signin.username))
                                )}
                            >Sign In
                            </button>
                            <br/>
                            <button type={"link"} style={{border:0,borderRadius:0,padding:0,backgroundColor:"#FFF",color:"#d7d7d7"}}>Forgot your password?</button>
                        </Form>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <button className="ghost" id="signIn" onClick={() => {
                                    this.setState({currentForm: "signin"})
                                }}>Sign In
                                </button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Hello, Friend!</h1>
                                <p>Enter your personal details and start journey with us</p>
                                <button className="ghost" id="signUp" onClick={() => {
                                    this.setState({currentForm: "signup"})
                                }}>Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {
        baseData: state.BaseData,
        auth: state.Auth
        // statement: state.Statement,

    }
}

export default connect(mapStateToProps, {...authenticatorActionFn})(LoginForm);
