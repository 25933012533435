import {dipDataActionFn, dipDataActionType as dipdata} from "./actions";
import {createSelector} from 'reselect'
import moment from 'moment';
import _ from 'lodash';
import update from 'immutability-helper';
// import {reportActionType as reportAction} from "../reports/actions";

let today = new Date();
let currentDMY = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
let todayMoment = new moment();


const initial = {
    dipData: {
        agent: [],
        airline: [],
        period: [],
        stakeholder: [],
        exceptionData: [],
        agentInfo:{},
        airlineInfo:{},
        stakeholderInfo:{},
        exceptionInfo: {
            agent: [],
            airline: [],
            insurance: [],
            stakeholder: []
        }
    },
    dipSalesData: {
        bank_guarantee: [],
        sale_tariff: [],
        sale_entry: [],
        validPeriod_adjust: [],
        validPeriod_deposit: [],
        result_mode: "deposit",
        year: today.getFullYear()
    },
    dipSalesDeposit: [],
    filteredAgent: "all",
    dataLoaded: {
        productList: "NONE",
        siteList: "NONE",
        storeList: "NONE",
        currentSite: "",
    },
    currentMenu: "baseStore"
};

export default function dipdataReducer(state = initial, action) {

    switch (action.type) {


        case dipdata.SET_CURRENT_MENU:
            return update(state, {
                currentMenu: {$set: action.payload},
            })

        case dipdata.DIPDATA.SUCCESS:
            // console.log("debug",action.payload);
            return update(state, {
                dipData: {$set: action.payload.result},
            });

        case dipdata.DIPUPDATE.SUCCESS:
            // console.log("debug",action.payload);
            return update(state, {
                dipData: {$set: action.payload.result},
            });

        case dipdata.DIPINFOUPDATE.SUCCESS:
            console.log("debug", action.payload);
            return update(state, {
                dipData: {
                    [action.payload.item_type + "Info"]: {
                        [action.payload.item_id]: {[action.payload.info_type]: {$set: action.payload.info_text}}
                    }
                },
            });

        case dipdata.DIPSALESDATA.SUCCESS:
            // console.log("debug",action.payload);
            return update(state, {
                dipSalesData: {$set: action.payload.data},
                dipSalesDeposit: {$set: action.payload.depositRecord}
            })

        case dipdata.SET_FILTERED_AGENT:
            // console.log("debug",action.payload);
            return update(state, {
                filteredAgent: {$set: action.payload},
            });

        default:
            return state;

    }

}
