import React from 'react';
import ReactDOM from 'react-dom';
import { Result, Button } from 'antd';

export default class Error404 extends React.Component {
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary">Back Home</Button>}
            />
        );
    }
}
