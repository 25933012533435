// import {contacts} from "./reducer";
import {createAction} from "redux-actions";
import {createActionThunk} from "redux-thunk-actions";
import {createSelector} from "reselect";
import _ from 'lodash';

function ascendingSort(contact1, contact2) {
    const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
    const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

export const saleDataActionType = {
    SALES_ITEM: {
        FETCH: "SALEDATA::SALES_ITEM_FETCH",
        START: "SALEDATA::SALES_ITEM_START",
        FAIL: "SALEDATA::SALES_ITEM_FAIL",
        SUCCESS: "SALEDATA::SALES_ITEM_SUCCESS",
        RESET: "SALEDATA::SALES_ITEM_RESET",
        SELECT: "SALEDATA::SALES_ITEM_SELECT",
    },

    BUY_ITEM: {
        ADD: "SALEDATA::BUY_ITEM_ADD",
        REMOVE: "SALEDATA::BUY_ITEM_REMOVE",
        UPDATE: "SALEDATA::BUY_ITEM_UPDATE",
        FETCH: "SALEDATA::BUY_ITEM_FETCH",
        START: "SALEDATA::BUY_ITEM_START",
        FAIL: "SALEDATA::BUY_ITEM_FAIL",
        SUCCESS: "SALEDATA::BUY_ITEM_SUCCESS",
        RESET: "SALEDATA::BUY_ITEM_RESET",
        SELECT: "SALEDATA::BUY_ITEM_SELECT",
    },

    PENDING_ITEM: {
        ADD: "SALEDATA::PENDING_ITEM_ADD",
        REMOVE: "SALEDATA::PENDING_ITEM_REMOVE",
        UPDATE: "SALEDATA::PENDING_ITEM_UPDATE",
        FETCH: "SALEDATA::PENDING_ITEM_FETCH",
        START: "SALEDATA::PENDING_ITEM_START",
        FAIL: "SALEDATA::PENDING_ITEM_FAIL",
        SUCCESS: "SALEDATA::PENDING_ITEM_SUCCESS",
        RESET: "SALEDATA::PENDING_ITEM_RESET",
        SELECT: "SALEDATA::PENDING_ITEM_SELECT",
    },

    REORDER: {
        FETCH: "SALEDATA::REORDER_FETCH",
        START: "SALEDATA::REORDER_START",
        FAIL: "SALEDATA::REORDER_FAIL",
        SUCCESS: "SALEDATA::REORDER_SUCCESS",
        RESET: "SALEDATA::REORDER_RESET",
        SELECT: "SALEDATA::REORDER_SELECT",
    },

};

const isIterable = object => object != null && typeof object[Symbol.iterator] === 'function'

export const saleDataActionFn = {

    saleDataSaleItemFetch: createAction(saleDataActionType.SALES_ITEM.FETCH),
    saleDataSaleItemStart: createAction(saleDataActionType.SALES_ITEM.START),
    saleDataSaleItemFail: createAction(saleDataActionType.SALES_ITEM.FAIL),
    saleDataSaleItemSuccess: createAction(saleDataActionType.SALES_ITEM.SUCCESS),
    saleDataSaleItemReset: createAction(saleDataActionType.SALES_ITEM.RESET),
    saleDataSaleItemSelect: createAction(saleDataActionType.SALES_ITEM.SELECT),
    saleDataSaleItemSelectVerbose: createAction(saleDataActionType.SALES_ITEM.SELECT + "_VERBOSE"),

    saleDataBuyItemAdd: createActionThunk(saleDataActionType.BUY_ITEM.ADD, (payload, state) => {
        let currentBuyData = state.getState().SaleData.currentBuyData;
        let optionList = state.getState().BaseData.productList.productOptions;
        let productList = state.getState().BaseData.productList.products;
        let productListPN = state.getState().BaseData.productList.productsByPN;

        let productIndex = {};
        for (let i = 0; i< productList.length; i++) {
            productIndex[productList[i].PN] = i
        }

        // console.log(currentBuyData,optionList)
        //
        // console.log(optionList);
        let newBuyData = [...currentBuyData];
        let buyingPN = [];
        for (let newItem of payload) {
            // console.log(newItem);
            if (newItem.PN in optionList) {
                if (isIterable(optionList[newItem.PN])) {
                    console.log(newItem.PN,newItem);
                    for (let otherUnit of optionList[newItem.PN]) {
                        if (newItem.LUnit !== otherUnit.SUnit) {
                            newBuyData.push({
                                ...newItem,
                                uniqueId: newItem.PN + otherUnit.SUnit,
                                buyAmount: 0,
                                NumSPerLUnit: otherUnit.NumSPerLUnit,
                                currentUnit: otherUnit.SUnit,
                                currentUnitPrice: otherUnit.Price2 || 0,
                                warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                                totalPrice: 0,
                            });
                        } else {
                            newBuyData.push({
                                ...newItem,
                                uniqueId: newItem.PN + newItem.LUnit,
                                buyAmount: newItem.Number,
                                NumSPerLUnit: otherUnit.NumSPerLUnit,
                                currentUnit: newItem.LUnit,
                                currentUnitPrice: parseFloat(newItem.Price2),
                                warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                                totalPrice: newItem.Number * parseFloat(newItem.Price2)
                            });
                        }
                    }
                } else {
                    newBuyData.push({
                        ...newItem,
                        uniqueId: newItem.PN + newItem.LUnit,
                        buyAmount: newItem.Number,
                        NumSPerLUnit: 1,
                        currentUnit: newItem.LUnit,
                        warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                        currentUnitPrice: parseFloat(newItem.Price2),
                        totalPrice: newItem.Number * parseFloat(newItem.Price2)
                    });
                }


            } else {
                newBuyData.push({
                    ...newItem,
                    uniqueId: newItem.PN + newItem.LUnit,
                    buyAmount: newItem.Number,
                    // NumSPerLUnit: otherUnit.NumSPerLUnit,
                    currentUnit: newItem.LUnit,
                    warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                    currentUnitPrice: parseFloat(newItem.Price2),
                    totalPrice: newItem.Number * parseFloat(newItem.Price2)
                });
            }

        }

        // for (let Item of )
        let buyData = _.sortBy(_.uniqBy(newBuyData, 'uniqueId'), ['GroupName', 'Name']);
        for (let newItem of buyData) {
            buyingPN.push(newItem.PN);
        }

        let pendingData = [];
        for (let newItem of productList) {
            if (buyingPN.indexOf(newItem.PN) < 0) {
                if (newItem.PN in optionList) {
                    if (isIterable(optionList[newItem.PN])) {
                        for (let otherUnit of optionList[newItem.PN]) {
                            pendingData.push({
                                ...newItem,
                                uniqueId: newItem.PN + otherUnit.SUnit,
                                buyAmount: 0,
                                NumSPerLUnit: otherUnit.NumSPerLUnit,
                                currentUnit: otherUnit.SUnit,
                                currentUnitPrice: otherUnit.Price2 || 0,
                                warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                                totalPrice: 0,
                            });
                        }
                    } else {
                        pendingData.push({
                            ...newItem,
                            uniqueId: newItem.PN + newItem.LUnit,
                            buyAmount: 0,
                            NumSPerLUnit: 1,
                            currentUnit: newItem.LUnit,
                            warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                            currentUnitPrice: parseFloat(newItem.Price2),
                            totalPrice: 0
                        });
                    }


                } else {
                    pendingData.push({
                        ...newItem,
                        uniqueId: newItem.PN + newItem.LUnit,
                        buyAmount: 0,
                        // NumSPerLUnit: otherUnit.NumSPerLUnit,
                        currentUnit: newItem.LUnit,
                        currentUnitPrice: parseFloat(newItem.Price2),
                        warehouseStock:productListPN[newItem.PN].warehouseStock || 0,
                        totalPrice: 0
                    });
                }
            }

        }

        pendingData = _.sortBy(_.uniqBy(pendingData, 'uniqueId'), ['GroupName', 'Name']);
        return {
            buyData: buyData,
            pendingData: pendingData
        }
        // state.getState().Transactions.searchCriteria
    }),

    saleDataBuyItemRemove: createAction(saleDataActionType.SALES_ITEM.START),
    saleDataBuyItemUpdate: createActionThunk(saleDataActionType.BUY_ITEM.UPDATE, (payload, state) => {
        let targetRecord = state.getState().SaleData.currentBuyData[payload.target];
        // let targetRecord = currentBuyData
        if (targetRecord.uniqueId === payload.checker) {
            // console.log("match")
            switch (payload.mode) {
                case "amount":
                    // targetRecord.currentUnitPrice = parseFloat(targetRecord.Price2);
                    targetRecord.buyAmount = payload.value;
                    targetRecord.totalPrice = payload.value * targetRecord.currentUnitPrice;
                    break;
                case "unit":
                    // console.log(payload.newUnit);
                    targetRecord.currentUnitPrice = parseFloat(payload.newUnit.Price2);
                    targetRecord.currentUnit = payload.newUnit.SUnit;
                    // targetRecord.buyAmount = targetRecord.buyAmount;
                    targetRecord.totalPrice = targetRecord.currentUnitPrice * targetRecord.buyAmount;
                    targetRecord.uniqueId = targetRecord.PN + payload.newUnit.SUnit;
                    break;

                default:
            }


            return {data: targetRecord, target: payload.target}
        }
        // state.getState().Transactions.searchCriteria
    }),

    saleDataPendingItemUpdate: createActionThunk(saleDataActionType.PENDING_ITEM.UPDATE, (payload, state) => {
        let targetRecord = state.getState().SaleData.currentPendingData[payload.target];
        // let targetRecord = currentBuyData
        if (targetRecord.uniqueId === payload.checker) {
            // console.log("match")
            switch (payload.mode) {
                case "amount":
                    // targetRecord.currentUnitPrice = parseFloat(targetRecord.Price2);
                    targetRecord.buyAmount = payload.value;
                    targetRecord.totalPrice = payload.value * targetRecord.currentUnitPrice;
                    break;
                case "unit":
                    // console.log(payload.newUnit);
                    targetRecord.currentUnitPrice = parseFloat(payload.newUnit.Price2);
                    targetRecord.currentUnit = payload.newUnit.SUnit;
                    // targetRecord.buyAmount = targetRecord.buyAmount;
                    targetRecord.totalPrice = targetRecord.currentUnitPrice * targetRecord.buyAmount;
                    targetRecord.uniqueId = targetRecord.PN + payload.newUnit.SUnit;
                    break;

                default:
            }


            return {data: targetRecord, target: payload.target}
        }
        // state.getState().Transactions.searchCriteria
    }),

    saleDataReorderFetch: createAction(saleDataActionType.REORDER.FETCH),
    saleDataReorderStart: createAction(saleDataActionType.REORDER.START),
    saleDataReorderFail: createAction(saleDataActionType.REORDER.FAIL),
    saleDataReorderSuccess: createAction(saleDataActionType.REORDER.SUCCESS),

};


export default {...saleDataActionFn};
