import React, {Component} from 'react';
import _ from "lodash";
import Moment from "moment";
import THBText from 'thai-baht-text'
import {saveAs} from "file-saver";
import pdfMake from 'pdfmake/build/pdfmake';
import {BigNumber} from 'bignumber.js';
import {notification, message} from 'antd';
import {v4 as uuidv4} from 'uuid';

BigNumber.set({DECIMAL_PLACES: 20, ROUNDING_MODE: 4});

message.config({
    top: 20,
    duration: 2,
    maxCount: 1,
});

const openNotificationWithIcon = (type, header, detail) => {
    notification[type]({
        message: header,
        description: detail,
        duration: 2,
    });
};

const debugMode = true;

const openNotificationWithIconShort = (type, header, detail) => {
    message[type](
        header + " / " + detail,
        3
    );
};

function IDhyphenate(n) {
    return n.replace(/\D/g, "").replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, "$1-$2-$3-$4-$5");
}


function mmToInch(x) {
    return x / 254;
}

function mmToPoint(x) {
    // return x;
    return 2.835 * x;
}

const countSara = str => Array.from(str)
    .filter(letter => '็่้๊๋์ัิีึืุู'.includes(letter)).length;

const thmonth = {
    "01": "มกราคม", "02": "กุมภาพันธ์", "03": "มีนาคม",
    "04": "เมษายน", "05": "พฤษภาคม", "06": "มิถุนายน",
    "07": "กรกฎาคม", "08": "สิงหาคม", "09": "กันยายน",
    "10": "ตุลาคม", "11": "พฤศจิกายน", "12": "ธันวาคม"
};
const thmonthabbr = {
    "01": "ม.ค.", "02": "ก.พ.", "03": "มี.ค.",
    "04": "เม.ย.", "05": "พ.ค.", "06": "มิ.ย.",
    "07": "ก.ค.", "08": "ส.ค.", "09": "ก.ย.",
    "10": "ต.ค.", "11": "พ.ย.", "12": "ธ.ค."
};

const innerRowBorder = [true, false, true, false];
const sideBorder = [true, false, true, false];
const allBorder = [true, true, true, true];
const topSideBorder = [true, true, true, false];
const bottomSideBorder = [true, false, true, true];
const centerAlign = {alignment: "center"};

// pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    Noto: {
        // normal: 'https://fonts.gstatic.com/s/notosans/v14/o-0IIpQlx3QUlC5A4PNr6zRAW_0.woff2',
        // bold: 'https://fonts.gstatic.com/s/notosans/v14/o-0NIpQlx3QUlC5A4PNjXhFVatyB1Wk.woff2',
        // italics: 'https://fonts.gstatic.com/s/notosans/v14/o-0OIpQlx3QUlC5A4PNr4ARMQ_m87A.woff2',
        // bolditalics: 'https://fonts.gstatic.com/s/notosans/v14/o-0TIpQlx3QUlC5A4PNr4Az5ZuyNzW1aPQ.woff2'
        // normal: "https://cdn.jsdelivr.net/npm/notosans-fontface@1.2.2/fonts/NotoSans-Regular.ttf",
        normal: "https://cdn.lazywasabi.net/fonts/ChulabhornLikit/ChulabhornLikitText-Regular.woff",
        bold: "https://cdn.jsdelivr.net/npm/notosans-fontface@1.2.2/fonts/NotoSans-Bold.ttf",
        italics: "https://cdn.jsdelivr.net/npm/notosans-fontface@1.2.2/fonts/NotoSans-Italic.ttf",
        bolditalics: "https://cdn.jsdelivr.net/npm/notosans-fontface@1.2.2/fonts/NotoSans-BoldItalic.ttf",

    }
};
pdfMake.tableLayouts = {
    ...pdfMake.tableLayouts,
    outerBorder: {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 2;
            }
            return 0;
        },
        vLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body[0].length) {
                return 2;
            }
            return 0
        },
        hLineColor: function (i) {
            return 'black';
        },
        paddingLeft: function () {
            return 8;
        },
        paddingRight: function () {
            return 8;
        },
        paddingBottom: function () {
            return 5
        }
    },
    borderless: {
        hLineWidth: function () {
            return 0;
        },
        vLineWidth: function () {
            return 0;
        },
        hLineColor: function () {
            return 0;
        },
        paddingLeft: function () {
            return 0;
        },
        paddingRight: function () {
            return 0;
        }
    },
};

// (debugMode) && console.log (pdfMake);
const moment = Moment;
const authString = "";
// const uuidv4 = require('uuid/v4');
let todayMoment = new moment();

export function generateRestockItemList(data, branchName, documentMode, callback) {
    documentMode = documentMode || "order";
    // (debugMode) && console.log (data);

    // if (!(("chequeDate" in data) && ("paidAmount" in data) && ("payeeName" in data))) return {error: "Incomplete Data"};

    let CoverFullDate = moment().format("llll");
    let CoverDate = moment().format("DD/MM/YYYY");
    let marginValue = 10, paddingValue = 5;
    let tableLayout = {
        hLineWidth: function (i, node) {
            return 1;
        },
        vLineWidth: function (i, node) {
            return 1;
        },
        hLineColor: function (i, node) {
            return 'gray';
        },
        vLineColor: function (i, node) {
            return 'gray';
        },
        paddingLeft: function (i, node) {
            return paddingValue;
        },
        paddingRight: function (i, node) {
            return paddingValue;
        },
        paddingTop: function (i, node) {
            return paddingValue;
        },
        paddingBottom: function (i, node) {
            return paddingValue;
        },
        marginLeft: function (i, node) {
            return marginValue;
        },
        marginRight: function (i, node) {
            return marginValue;
        },
        marginTop: function (i, node) {
            return marginValue;
        },
        marginBottom: function (i, node) {
            return marginValue;
        },
    };

    let orderData = data.order;
    let orderItem = data.orderItem;

    const tableFontSize = 8;
    const tableWidth = (documentMode === "order") ? [80, 50, 180, 50, 50, 50, 50] : [80, 50, 140, 50, 50, 50, 50, 40];
    let orderItemRowList = [];
    let orderItemHeaderRowList = [
        [
            {
                text: "เลขที่ใบสั่งซื้อ",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "center",
            },
            {
                text: "รหัส",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "center",
            },
            {
                text: "ชื่อ",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "left",
            },
            {
                text: "หน่วย",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "center",
            },
            {
                text: "ราคา/หน่วย",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "right",
            },
            {
                text: "ราคารวม",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "right",
            },
            {
                text: "จำนวนซื้อ",
                fontSize: tableFontSize,
                border: [false, false, false, false],
                alignment: "right",
            },
            ...(documentMode === "order"
                    ? []
                    : [{
                        text: "จำนวนได้รับ",
                        fontSize: tableFontSize,
                        border: [false, false, false, false],
                        alignment: "right",
                    }]
            )

        ]
    ];
    for (let orderItemRow of orderItem) {
        orderItemRowList.push(
            [
                {
                    text: orderItemRow.orderNumber,
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "center",
                },
                {
                    text: orderItemRow.productCode,
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "center",
                },
                {
                    text: orderItemRow.name,
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "left",
                },
                {
                    text: orderItemRow.buyUnit,
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "center",
                },
                {
                    text: parseFloat(orderItemRow.buyPrice).toFixed(2),
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "right",
                },
                {
                    text: parseFloat(orderItemRow.totalPrice).toFixed(2),
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "right",
                },
                {
                    text: parseFloat(orderItemRow.buyAmount).toFixed(0),
                    fontSize: tableFontSize,
                    border: [false, false, false, false],
                    alignment: "right",
                },
                ...(documentMode === "order"
                        ? []
                        : [{
                            text: parseFloat(orderItemRow.fulfillAmount).toFixed(0),
                            fontSize: tableFontSize,
                            border: [false, false, false, false],
                            alignment: "right",
                        }]
                )
            ])
    }
    // console.log(orderItemRowList);
    let orderItemContent = [
        {
            table: {
                widths: tableWidth,
                body: orderItemRowList,
                heights: 5,
            },

            // absolutePosition: {x: mmToPoint(chequeConfig[sourceBank].acx), y: mmToPoint(chequeConfig[sourceBank].acy)},
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            // layout: tableLayout,
            style: 'lightHorizontalLines',
            layout: {
                fillColor: function (i, node) {
                    return (i % 2 === 0) ? '#EEEEEE' : null;
                }
            }
        },
    ];


    var docDefinition = {
        info: {
            title: "ใบสั่งเติมสินค้าเลขที่ " + orderData[0].orderNumber + " - " + branchName,
            author: "TA Reorder System",
            subject: orderData[0].orderNumber
        },
        content: orderItemContent,
        defaultStyle: {
            font: "Noto",
            fontSize: 12
        },
        // a string or { width: number, height: number }
        pageSize: 'A4',
        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [5, 75, 10, 40],
        header: function (currentPage, pageCount, pageSize) {
            // you can apply any logic and return any valid pdfmake element
            return [
                {
                    text: "ใบสั่งเติมสินค้าเลขที่ " + orderData[0].orderNumber,
                    alignment: "center",
                    margin: [0, 10, 0, 0],
                },
                {
                    text: "สาขา " + branchName,
                    alignment: "center",
                },
                {
                    table: {
                        widths: tableWidth,
                        body: orderItemHeaderRowList
                    },
                    // absolutePosition: {x: mmToPoint(chequeConfig[sourceBank].acx), y: mmToPoint(chequeConfig[sourceBank].acy)},
                    margin: [0, 0, 0, 0],
                    padding: [0, 0, 0, 0],
                    layout: tableLayout,
                    style: 'lightHorizontalLines'
                },
            ]
        },
        footer: function (currentPage, pageCount) {
            return [
                // { text: "  ทั้งหมด " + orderData[0].totalItem +" รายการ / วันที่สั่ง "+moment(orderData[0].orderDate).format("YYYY-MM-DD") , alignment: 'left'},
                {text: currentPage.toString() + ' of ' + pageCount + "  ", alignment: 'right', marginRight: 10},
            ]
        },
        styles: {
            tableExample: {
                margin: [0, 5, 0, 15]
            }
        },
    };

    // console.log(branchName);
    //
    // // let targetPDF = pdfMake.createPdf(docDefinition);
    // // targetPDF.download("")
    pdfMake.createPdf(docDefinition).open()
}

/*
export function generateWithholdingTaxDocument(data, callback) {

    const incomeTypeText = {
        "1": "1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ  ตามมาตรา 40 (1)",
        "2": "2. ค่าธรรมเนียม  ค่านายหน้า  ฯลฯ  ตามมาตรา 40 (2)",
        "3": "3. ค่าแห่งลิขสิทธิ์  ฯลฯ  ตามมาตรา 40 (3)",
        "4.1": "4. (ก) ค่าดอกเบี้ย ฯลฯ  ตามมาตรา 40 (4) (ก)",
        "4.2": "   (ข) เงินปันผล ส่วนแบ่งของกำไร ฯลฯ ตามมาตรา 40 (4) (ข)",
        "4.2.1": "     (1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจากกำไรสุทธิของกิจการ\n         ที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้",
        "4.2.1.1": "       (1.1) อัตราร้อยละ 30 ของกำไรสุทธิ",
        "4.2.1.2": "       (1.2) อัตราร้อยละ 25 ของกำไรสุทธิ",
        "4.2.1.3": "       (1.3) อัตราร้อยละ 20 ของกำไรสุทธิ",
        "4.2.1.4": "       (1.4) อัตราอื่น ๆ ( ระบุ )",
        "4.2.2": "     (2) กิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคลซึ่ง ผู้รับเงินปันผลไม่ได้รับเครดิตภาษี",
        "4.2.3": "     (3) กำไรเฉพาะส่วนที่ได้รับยกเว้นไม่ต้องนำมารวมคำนวณภาษีเงินได้นิติบุคคล\n     ซึ่งผู้รับเงินปันผลไม่ได้รับเครดิตภาษี",
        // "     (2.1) กำไรสุทธิของกิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคล\n" +
        // "     (2.2) เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำมารวม\n" +
        // "	     คำนวณเป็นรายได้เพื่อเสียภาษีนิติบุคคล\n" +
        // "     (2.3) กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี\n" +
        // "     	ก่อนรอบระยะเวลาบัญชีปัจจุบัน\n" +
        // "     (2.4) กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method)\n" +
        // "     (2.5) อัตราอื่น ๆ ( ระบุ )",
        "5":
            "5. การจ่ายเงินได้ที่ต้องหักภาษี ณ. ที่จ่าย ตามคำสั่งกรมสรรพากรที่ออกตาม มาตรา 3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย รางวัลในการประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะ ค่าบริการ ค่าขนส่ง ค่าจ้างทำของ ค่าจ้างโฆษณา ค่าเช่า ค่าเบี้ยประกันวินาศภัย ฯลฯ",
        "6": "6.อื่นๆ(ระบุ)"
    };

    let incomeTypeTextSortKey = [
        "1", "2", "3",
        "4.1", "4.2",
        "4.2.1", "4.2.1.1", "4.2.1.2", "4.2.1.3", "4.2.1.4",
        "4.2.2", "4.2.3",
        "5", "6"];

    let incomeArray = [[
        {
            text: 'ประเภทเงินได้พึงประเมินจ่าย',
            fontSize: 13,
            alignment: 'center'
        },
        {
            text: 'วัน/เดือน\nหรือปีภาษีที่จ่าย',
            fontSize: 13,
            alignment: 'center'
        },
        {
            text: 'จำนวนเงินที่จ่าย',
            fontSize: 13,
            alignment: 'center'
        },
        {
            text: 'ภาษีที่หัก\nและนำส่งไว้',
            fontSize: 13,
            alignment: 'center'
        }
    ]];

    console.log(data.whtItem);
    let taxTypeFont = 13;
    let taxTypeMargin = [0, -2, 0, -2];
    let incomeTypeExtra = "";
    _.each(incomeTypeTextSortKey, (key) => {
        incomeTypeExtra = "";
        if ((key === "6")
            && (key in data.whtItem)
            && ("titleList" in data.whtItem[key])
            && (data.whtItem[key].titleList.length > 0))
            incomeTypeExtra = "   (" + data.whtItem[key].titleList.join(",") + ")";


        incomeArray.push([
            {
                text: incomeTypeText[key] + incomeTypeExtra,
                "preserveLeadingSpaces": true,
                fontSize: taxTypeFont,
                margin: taxTypeMargin,
                border: [true, false, true, false],
            },
            {
                text: (key in data.whtItem) ? (moment(data.whtDoc.documentDate).format('DD') + " "
                    + thmonthabbr[moment(data.whtDoc.documentDate).format('MM')] + " "
                    + (parseInt(moment(data.whtDoc.documentDate).format('YYYY')) + 543)) : "",
                border: [true, false, true, false],
                margin: taxTypeMargin,
                fontSize: taxTypeFont,
                alignment: "center",
            },
            {
                text: (key in data.whtItem) ? numberWithCommas(data.whtItem[key].baseAmount) : "",
                border: [true, false, true, false],
                margin: taxTypeMargin,
                alignment: "right",
                fontSize: taxTypeFont,
            },
            {
                text: (key in data.whtItem) ? numberWithCommas(data.whtItem[key].withheldAmount) : "",
                border: [true, false, true, false],
                margin: taxTypeMargin,
                alignment: "right",
                fontSize: taxTypeFont,
            },
        ])
    });

    incomeArray.push([
        {
            text: "รวมเงินที่จ่ายและภาษีที่หักนำส่ง",
            "preserveLeadingSpaces": true,
            fontSize: 13,
            border: [true, true, true, true],
            colSpan: 2,
            alignment: "right"

        },
        {},
        {
            text: numberWithCommas(data.whtDoc.baseAmount),
            border: [true, true, true, true],
            alignment: "right"
        },
        {
            text: numberWithCommas(data.whtDoc.withheldAmount),
            border: [true, true, true, true],
            alignment: "right"
        },
    ]);
    incomeArray.push([
        {
            text:
                [
                    {
                        text: "รวมภาษีที่หักนำส่ง (ตัวอักษร) : ",
                        "preserveLeadingSpaces": true,
                        fontSize: 13,

                    },
                    {
                        text: THBText(data.whtDoc.withheldAmount),
                        bold: true,
                        fontSize: 13,
                    }
                ],
            border: [true, true, true, true],
            colSpan: 4,
            alignment: "right"
        },
        {},
        {},
        {},

    ]);

    const styleList = {
        header: {
            fontSize: 22,
            bold: true,
        },
        centerHeader: {
            fontSize: 18,
            bold: true,
            alignment: 'center'
        },

    };

    // console.log(data);

    let header1 = [
        {
            layout: 'noBorders', // optional
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                widths: [400, '*'],
                body: [
                    [
                        {
                            text: "ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)\nฉบับที่ 2 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)\n",
                            // text: "ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)",
                            fontSize: 11
                        },
                        {
                            text: (data.whtDoc.locked || false) ? "-- VOIDED --" : "",
                            alignment: "right",
                            fontSize: 15,
                            bold: true,
                            color: "#FF0000"
                        }
                    ]
                ]
            }
        },
    ]
    // let header1 = {
    //     text: "ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)\nฉบับที่ 2 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)\n",
    //     // text: "ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)",
    //     fontSize: 10
    // };
    let header2 = [
        {
            layout: 'noBorders', // optional
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                widths: [400, '*'],
                body: [
                    [
                        {
                            text: "ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)\nฉบับที่ 2 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)\n",
                            // text: "ฉบับที่ 1 (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)",
                            fontSize: 11
                        },
                        {
                            text: (data.whtDoc.locked || false) ? "--VOIDED--" : "",
                            alignment: "right",
                            fontSize: 15,
                            bold: true,
                            color: "#FF0000"
                        }
                    ]
                ]
            }
        },
    ];
    let content = [
        {
            layout: 'noBorders', // optional
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                widths: [200, '*', '*'],
                body: [
                    [
                        {
                            text: 'หนังสือรับรองการหักภาษี ณ ที่จ่าย',
                            bold: true,
                            fontSize: 15
                        },
                        {
                            text: '(ตามมาตรา 50 ทวิ แห่งประมวลรัษฏากร)',
                            fontSize: 12,
                            alignment: "center",
                            verticalAlign: "bottom"
                        },
                        {
                            text: [
                                {
                                    text: "เลขที่ ",
                                },
                                {
                                    text: data.documentNo,
                                    bold: true
                                },
                            ],
                            alignment: "right",
                            fontSize: 15,
                        }
                    ]
                ]
            }
        },
        {
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                layout: {
                    defaultBorder: false,
                },
                widths: [300, '*'],
                body: [
                    [
                        {
                            border: [true, true, false, false],
                            text: 'ผู้มีหน้าที่หักภาษี ณ ที่จ่าย',
                            bold: true,
                            fontSize: 14,
                        },
                        {
                            border: [false, true, true, false],
                            text: "",
                        }
                    ],
                    [
                        {
                            text: [
                                {

                                    text: "ชื่อ : "
                                },
                                {
                                    text: data.withhold.name,
                                    bold: true
                                }
                            ],
                            border: [true, false, false, false],
                        },
                        {
                            text: [
                                {
                                    text: "เลขประจำตัวผู้เสียภาษีอากร : ",
                                },
                                {
                                    text: data.withhold.id,
                                    bold: true
                                },
                            ],
                            alignment: "right",
                            fontSize: 14,
                            border: [false, false, true, false],
                        }
                    ],
                    [{
                        text: [
                            {text: "ที่อยู่ : "},
                            {
                                text: data.withhold.address,
                                bold: true
                            },
                        ],
                        border: [true, false, true, true],
                        colSpan: 2
                    }, {}]
                ]
            }
        },
        {
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                layout: {
                    defaultBorder: false,
                },
                widths: [300, '*'],
                body: [
                    [
                        {
                            border: [true, false, false, false],
                            text: 'ผู้ถูกหักภาษี ณ ที่จ่าย',
                            bold: true,
                            fontSize: 14,
                        },
                        {
                            border: [false, false, true, false],
                            text: "",
                        }
                    ],
                    [
                        {
                            text: [
                                {

                                    text: "ชื่อ : "
                                },
                                {
                                    text: (data.withheld.namePrefix ? data.withheld.namePrefix + " " : "") + data.withheld.name,
                                    bold: true
                                }
                            ],
                            border: [true, false, false, false],
                        },
                        {
                            text: [
                                {
                                    text: "เลขประจำตัวผู้เสียภาษีอากร : ",
                                },
                                {
                                    text: data.withheld.id,
                                    bold: true
                                },
                            ],
                            alignment: "right",
                            fontSize: 14,
                            border: [false, false, true, false],
                        }
                    ],
                    [{
                        text: [
                            {text: "ที่อยู่ : "},
                            {
                                text: data.withheld.address,
                                bold: true
                            },
                        ],
                        border: [true, false, true, true],
                        colSpan: 2
                    }, {}]
                ]
            }
        },
        {
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                layout: {
                    defaultBorder: false,
                },
                widths: [50, 70, 50, "*", "*", "*", "*"],
                body: [
                    [
                        {
                            border: [true, false, false, true],
                            text: 'ลำดับที่ ',
                            bold: true,
                            fontSize: 14,
                        },
                        {
                            border: [false, false, false, true],
                            table: {
                                widths: [50],
                                body: [
                                    [' ']
                                ]
                            },
                        },
                        {
                            border: [true, false, false, true],
                            text: "ในแบบ",
                            fontSize: 14,
                            bold: true
                        },
                        {
                            border: [false, false, false, true],
                            text: [
                                (data.whtDoc.documentType == "ภ.ง.ด.1ก") ? "[x] ภ.ง.ด.1ก\n" : "[ ] ภ.ง.ด.1ก\n",
                                (data.whtDoc.documentType == "ภ.ง.ด.2ก") ? "[x] ภ.ง.ด.2ก" : "[ ] ภ.ง.ด.2ก",
                            ],

                            fontSize: 14,
                        },
                        {
                            border: [false, false, false, true],
                            text: [
                                (data.whtDoc.documentType == "ภ.ง.ด.1ก พิเศษ") ? "[x] ภ.ง.ด.1ก พิเศษ\n" : "[ ] ภ.ง.ด.1ก พิเศษ\n",
                                (data.whtDoc.documentType == "ภ.ง.ด.3ก") ? "[x] ภ.ง.ด.3ก" : "[ ] ภ.ง.ด.3ก",
                            ],

                            fontSize: 14,
                        },
                        {
                            border: [false, false, false, true],
                            text: [
                                (data.whtDoc.documentType == "ภ.ง.ด.2") ? "[x] ภ.ง.ด.2\n" : "[ ] ภ.ง.ด.2\n",
                                (data.whtDoc.documentType == "ภ.ง.ด.53") ? "[x] ภ.ง.ด.53" : "[ ] ภ.ง.ด.53",
                            ],

                            fontSize: 14,
                        },
                        {
                            border: [false, false, true, true],
                            text: (data.whtDoc.documentType == "ภ.ง.ด.3") ? "[x] ภ.ง.ด.3\n" : "[ ] ภ.ง.ด.3\n",
                            fontSize: 14,
                        },

                    ]
                ]
            }
        },
        {
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                heights: 4,
                widths: ['*', 80, 80, 80],
                body: [
                    ...incomeArray
                ],
                layout: {
                    defaultBorder: false,
                    paddingLeft: function (i, node) {
                        return 0;
                    },
                    paddingRight: function (i, node) {
                        return 0;
                    },
                    paddingTop: function (i, node) {
                        return 0;
                    },
                    paddingBottom: function (i, node) {
                        return 0;
                    },
                }

            }
        },
        {
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                widths: ['*'],
                body: [
                    [
                        {
                            text: "[ ] เงินสะสมจ่ายเข้ากองทุนสำรองเลี้ยงชีพ ใบอนุญาตเลขที่ ________________________ จำนวนเงิน _______ บาท",
                            border: [true, true, true, false],
                            fontSize: 12
                        }
                    ],
                    [
                        {
                            text: "[ ] เงินสมทบจ่ายเข้ากองทุนประกันสังคม จำนวนเงิน _______ บาท",
                            border: [true, false, true, false],
                            fontSize: 12
                        }
                    ],
                    [
                        {
                            text: [
                                {text: "เลขที่บัญชีนายจ้าง"},
                                {text: "____________", bold: true},
                                {text: "เลขที่บัตรประกันสังคมของผู้ถูกหักภาษี ณ ที่จ่าย"},
                                {text: "____________", bold: true},
                            ],
                            border: [true, false, true, true],
                            fontSize: 12,
                            alignment: "center"
                        }
                    ]
                ]
            }
        },
        {
            table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                widths: [80, 200, 200, "*"],
                body: [
                    [
                        {
                            text: "ผู้จ่ายเงิน\n[x] หัก ณ ที่จ่าย",
                            border: [true, false, true, true],
                            rowSpan: 2
                        },
                        {
                            text: "คำเตือน\n" +
                                "ผู้มีหน้าที่ออกหนังสือรับรองการหักภาษี ณ. ที่จ่าย ฝ่าฝืนไม่ปฏิบัติตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร ต้องรับโทษทางอาญา ตามมาตรา 35 แห่งประมวลรัษฎากร\n",
                            border: [true, false, true, true],
                            fontSize: 12,
                            rowSpan: 2
                        },
                        {
                            text: "ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้น ถูกต้องตรงกับความจริงทุกประการ",
                            colSpan: 2,
                            border: [true, false, true, false],
                            fontSize: 12
                        },
                        {},

                    ],
                    [{}, {}, {
                        text: [
                            {
                                text: "\nลงชื่อ _________________ ผู้มีหน้าที่หักภาษี ณ ที่จ่าย\n"
                            },
                            {
                                text: moment(data.whtDoc.documentDate).format('DD') + " / "
                                    + thmonth[moment(data.whtDoc.documentDate).format('MM')] + " / "
                                    + (parseInt(moment(data.whtDoc.documentDate).format('YYYY')) + 543),
                                bold: true
                            },
                            {text: " วัน เดือน ปี ที่ออกหนังสือรับรองฯ\n"}
                        ],
                        fontSize: 12,
                        border: [true, false, true, true],
                    }, {
                        text: "\nประทับตรา\nนิติบุคคล",
                        fontSize: 10,
                        border: [true, true, true, true],
                        alignment: "center"
                    }
                    ]
                ]
            }
        },
    ];
    var docDefinition = {
        content: [
            _.cloneDeep(header1),
            ...(_.cloneDeep(content)),
            _.cloneDeep(header2),
            ...(_.cloneDeep(content)),
        ],
        defaultStyle: {
            font: "THSarabunNew",
            fontSize: 14
        },
        styles: styleList,
        // a string or { width: number, height: number }
        pageSize: 'A4',
        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [15, 15, 15, 15],
    };

    // let targetPDF = pdfMake.createPdf(docDefinition);
    // targetPDF.download("")
    pdfMake.createPdf(docDefinition).open()
}

export function generateStatementCover(data, callback) {

    // (debugMode) && console.log (data);

    // if (!(("chequeDate" in data) && ("paidAmount" in data) && ("payeeName" in data))) return {error: "Incomplete Data"};

    let CoverFullDate = moment().format("llll");
    let CoverDate = moment().format("DD/MM/YYYY");
    let marginValue = 10, paddingValue = 5;
    let tableLayout = {
        hLineWidth: function (i, node) {
            return 1;
        },
        vLineWidth: function (i, node) {
            return 1;
        },
        hLineColor: function (i, node) {
            return 'gray';
        },
        vLineColor: function (i, node) {
            return 'gray';
        },
        paddingLeft: function (i, node) {
            return paddingValue;
        },
        paddingRight: function (i, node) {
            return paddingValue;
        },
        paddingTop: function (i, node) {
            return paddingValue;
        },
        paddingBottom: function (i, node) {
            return paddingValue;
        },
        marginLeft: function (i, node) {
            return marginValue;
        },
        marginRight: function (i, node) {
            return marginValue;
        },
        marginTop: function (i, node) {
            return marginValue;
        },
        marginBottom: function (i, node) {
            return marginValue;
        },
    };

    // requestDiscount: "236.55"
    // requestDuty: "14.00"
    // requestNetPremium: "1747.66"
    // requestPremium: "1935.00"
    // requestTotal: "1984.21"
    // requestVat: "35.21"
    let StatementCoverContent = [
        {
            style: 'chequeBlockTable',
            table: {
                widths: [60, '*', 60],
                body: [
                    [
                        {text: "", border: [false, false, false, false],},
                        {
                            table: {
                                widths: ['*', 135],
                                body: [
                                    [{
                                        image: window.AppSettings.logo,
                                        width: 50,
                                        alignment: "center",
                                        colSpan: 2,
                                        border: [false, false, false, false]
                                    }, {}],
                                    [{
                                        text: window.AppSettings.invoice.englishName,
                                        fontSize: 24,
                                        bold: true,
                                        border: [false, false, false, false],
                                        alignment: "center",
                                        colSpan: 2
                                    }, {}],
                                    [{
                                        text: 'ใบวางบิล',
                                        fontSize: 20,
                                        border: [false, false, false, false],
                                        alignment: "center",
                                        colSpan: 2
                                    }, {}],
                                    [{
                                        text: ['วางบิลที่\n',
                                            {text: data.title, bold: true}],
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "left"
                                    }, {
                                        text: ['วันที่วางบิล\n', {text: CoverDate, bold: true}],
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }],
                                    [{
                                        text: ['ตาม Statement No.\n', {
                                            text: data.statementNumber, bold: true,
                                            fontSize: 18, decoration: 'underline'
                                        }],
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "center",
                                        colSpan: 2
                                    }, {}],
                                    [{
                                        text: '(รอบ Statement : ' + data.statementMonth + ")",
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "center",
                                        colSpan: 2
                                    }, {}
                                    ],
                                    [
                                        {text: " ", border: [false, false, false, false]},
                                        {text: " ", border: [false, false, false, false]}
                                    ],
                                    [{
                                        text: 'วันที่ออก Statement',
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }, {
                                        text: moment(data.createdAt).format("DD/MM/YYYY"),
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }],
                                    [{
                                        text: 'ผู้ออก Statement',
                                        fontSize: 16,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }, {
                                        text: data.creator,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }],
                                    [{
                                        text: 'จำนวนเงิน',
                                        fontSize: 14,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }, {
                                        text: numberWithCommas(data.requestNetPremium) + " บาท",
                                        bold: true,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }],
                                    [{
                                        text: 'วันที่นัดชำระ',
                                        fontSize: 14,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }, {
                                        table: {
                                            widths: ['*'],
                                            heights: 1,
                                            body: [
                                                [{text: "   ", border: [false, false, false, true], fontSize: 8,}]
                                            ]
                                        }
                                        , border: [false, false, false, false]
                                    }],
                                    [{
                                        text: ['ผู้รับเอกสาร'],
                                        fontSize: 14,
                                        border: [false, false, false, false],
                                        alignment: "right"
                                    }, {
                                        table: {
                                            widths: ['*'],
                                            heights: 1,
                                            body: [
                                                [{text: "   ", border: [false, false, false, true], fontSize: 8,}]
                                            ]
                                        }
                                        , border: [false, false, false, false]
                                    }],
                                    [{
                                        text: ['ใบวางบิลพิมพ์โดย ', JSON.parse(localStorage.getItem('userData')).user.realname, " เมื่อ ", CoverFullDate],
                                        fontSize: 9,
                                        border: [false, false, false, false],
                                        alignment: "left",

                                    }, {
                                        text: [data.uuid],
                                        fontSize: 9,
                                        border: [false, false, false, false],
                                        alignment: "right",

                                    }
                                    ],
                                ],
                            },
                            margin: [0, 0, 0, 0],
                            padding: [0, 0, 0, 0],
                            layout: tableLayout

                        },
                        {text: "", border: [false, false, false, false],}
                    ]
                ]
            },

            // absolutePosition: {x: mmToPoint(chequeConfig[sourceBank].acx), y: mmToPoint(chequeConfig[sourceBank].acy)},
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            layout: tableLayout
        },
    ];

    // let dateCellSize = 7;
    // let dateFontSize = 21;
    //
    // let chequeConfig = {};
    // let sourceBank = data.bankCode;
    // if (!(sourceBank in chequeSettings)) {
    //     openNotificationWithIconShort("error","Cheque Template not found","Please add cheque config in general_config.js");
    //     return 1;
    // }
    // // MM of location
    // let yAdjust = 0;
    // let xAdjust = 0;
    // chequeConfig[sourceBank] = {
    //     acwidth: chequeSettings[sourceBank].acwidth,
    //     acx: xAdjust + chequeSettings[sourceBank].acx,
    //     acy: yAdjust + chequeSettings[sourceBank].acy,
    //     acfontsize: chequeSettings[sourceBank].acfontsize,
    //     bearerwidth: chequeSettings[sourceBank].bearerwidth,
    //     bearerx: xAdjust + chequeSettings[sourceBank].bearerx,
    //     bearery: yAdjust + chequeSettings[sourceBank].bearery,
    //     payeewidth: chequeSettings[sourceBank].payeewidth,
    //     payeex: xAdjust + chequeSettings[sourceBank].payeex ,
    //     payeey: yAdjust + chequeSettings[sourceBank].payeey,
    //     payeefontsize: chequeSettings[sourceBank].payeefontsize,
    //     amttextwidth: chequeSettings[sourceBank].amttextwidth,
    //     amttextx: xAdjust + chequeSettings[sourceBank].amttextx,
    //     amttexty: yAdjust + chequeSettings[sourceBank].amttexty,
    //     amttextfontsize: chequeSettings[sourceBank].amttextfontsize,
    //     amtwidth: chequeSettings[sourceBank].amtwidth,
    //     amtx: xAdjust + chequeSettings[sourceBank].amtx,
    //     amty: yAdjust + chequeSettings[sourceBank].amty,
    //     amtfontsize: chequeSettings[sourceBank].amtfontsize,
    //     dateCellsize: chequeSettings[sourceBank].dateCellsize,
    //     dateCellfontsize: chequeSettings[sourceBank].dateCellfontsize,
    //     dateCellx: xAdjust + chequeSettings[sourceBank].dateCellx,
    //     dateCelly: yAdjust + chequeSettings[sourceBank].dateCelly,
    // }
    //
    //
    // (debugMode) && console.log (THBText(data.paidAmount));
    // let chequeContent = [
    //     {
    //         style: 'chequeBlockTable',
    //         table: {
    //             widths: [mmToPoint(chequeConfig[sourceBank].acwidth)],
    //             body: [
    //                 [{
    //                     text: 'A/C PAYEE ONLY',
    //                     fontSize: chequeConfig[sourceBank].acfontsize,
    //                     border: [false, true, false, true],
    //                     alignment: "center"
    //                 }]
    //             ],
    //         },
    //         absolutePosition: {x: mmToPoint(chequeConfig[sourceBank].acx), y: mmToPoint(chequeConfig[sourceBank].acy)},
    //         margin: [0, 0, 0, 0],
    //         padding: [0, 0, 0, 0],
    //         layout: tableLayout
    //     },
    //     {
    //         text: '/////',
    //         absolutePosition: {
    //             x: mmToPoint(chequeConfig[sourceBank].bearerx),
    //             y: mmToPoint(chequeConfig[sourceBank].bearery)
    //         },
    //         fontSize: 20
    //     },
    //     {
    //         style: 'chequeBlockTable',
    //         table: {
    //             widths: [mmToPoint(chequeConfig[sourceBank].payeewidth)],
    //             body: [
    //                 [{
    //                     text: data.payeeName,
    //                     fontSize: chequeConfig[sourceBank].payeefontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "left"
    //                 }]
    //             ],
    //         },
    //         absolutePosition: {
    //             x: mmToPoint(chequeConfig[sourceBank].payeex),
    //             y: mmToPoint(chequeConfig[sourceBank].payeey)
    //         },
    //         margin: [0, 0, 0, 0],
    //         padding: [0, 0, 0, 0],
    //         layout: tableLayout
    //     },
    //     {
    //         style: 'chequeBlockTable',
    //         table: {
    //             widths: [mmToPoint(chequeConfig[sourceBank].amttextwidth)],
    //             body: [
    //                 [{
    //                     text: '==(' + THBText(data.paidAmount) + ")==",
    //                     fontSize: chequeConfig[sourceBank].amttextfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }]
    //             ],
    //         },
    //         absolutePosition: {
    //             x: mmToPoint(chequeConfig[sourceBank].amttextx),
    //             y: mmToPoint(chequeConfig[sourceBank].amttexty)
    //         },
    //         margin: [0, 0, 0, 0],
    //         padding: [0, 0, 0, 0],
    //         layout: tableLayout
    //     },
    //     {
    //         style: 'chequeBlockTable',
    //         table: {
    //             widths: [mmToPoint(chequeConfig[sourceBank].amtwidth)],
    //             body: [
    //                 [{
    //                     text: '=' + numberWithCommas(((typeof data.paidAmount == "string")
    //                         ? numberWithCommas(data.paidAmount)
    //                         : numberWithCommas(data.paidAmount.toFixed(2)))) + "=",
    //                     fontSize: chequeConfig[sourceBank].amtfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center",
    //                     bold: true,
    //                 }]
    //             ],
    //         },
    //         absolutePosition: {
    //             x: mmToPoint(chequeConfig[sourceBank].amtx),
    //             y: mmToPoint(chequeConfig[sourceBank].amty)
    //         },
    //         margin: [0, 0, 0, 0],
    //         padding: [0, 0, 0, 0],
    //         layout: tableLayout
    //     },
    //     {
    //         style: 'chequeBlockTable',
    //         table: {
    //             widths: [mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize), mmToPoint(chequeConfig[sourceBank].dateCellsize)],
    //             body: [
    //                 [{
    //                     text: paymentDate[0],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[1],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[2],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[3],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[4],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[5],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[6],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }, {
    //                     text: paymentDate[7],
    //                     fontSize: chequeConfig[sourceBank].dateCellfontsize,
    //                     border: [false, false, false, false],
    //                     alignment: "center"
    //                 }]
    //             ],
    //         },
    //         absolutePosition: {
    //             x: mmToPoint(chequeConfig[sourceBank].dateCellx),
    //             y: mmToPoint(chequeConfig[sourceBank].dateCelly)
    //         },
    //         margin: [0, 0, 0, 0],
    //         padding: [0, 0, 0, 0],
    //         layout: tableLayout
    //     },
    //
    //
    // ]
    var docDefinition = {
        content: StatementCoverContent,
        defaultStyle: {
            font: "THSarabunNew",
            fontSize: 16
        },
        // a string or { width: number, height: number }
        pageSize: 'A4',
        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [0, 50, 0, 0],
    };
    //
    // // let targetPDF = pdfMake.createPdf(docDefinition);
    // // targetPDF.download("")
    pdfMake.createPdf(docDefinition).open()
}

export function generateCheque(data, cashiercheque, callback) {

    if (cashiercheque === undefined) {
        cashiercheque = false;
    }
    // (debugMode) && console.log (data);

    if (!(("chequeDate" in data) && ("paidAmount" in data) && ("payeeName" in data))) return {error: "Incomplete Data"};

    let paymentDate = moment(data.chequeDate).format("DDMMYYYY");
    let tableLayout = {
        hLineWidth: function (i, node) {
            return 1;
        },
        vLineWidth: function (i, node) {
            return 1;
        },
        hLineColor: function (i, node) {
            return 'gray';
        },
        vLineColor: function (i, node) {
            return 'gray';
        },
        paddingLeft: function (i, node) {
            return 0;
        },
        paddingRight: function (i, node) {
            return 0;
        },
        paddingTop: function (i, node) {
            return 0;
        },
        paddingBottom: function (i, node) {
            return 0;
        },
        marginLeft: function (i, node) {
            return 0;
        },
        marginRight: function (i, node) {
            return 0;
        },
        marginTop: function (i, node) {
            return 0;
        },
        marginBottom: function (i, node) {
            return 0;
        },
    };
    let dateCellSize = 7;
    let dateFontSize = 21;

    let chequeConfig = {};
    let sourceBank = data.bankCode;

    if (sourceBank in chequeSettings) {
        // MM of location
        let yAdjust = 0;
        let xAdjust = 0;

        let thisCheque = {...chequeSettings[sourceBank]};
        // thisCheque.acwidth = thisCheque.acwidth;
        thisCheque.acx = xAdjust + thisCheque.acx;
        thisCheque.acy = yAdjust + thisCheque.acy;
        // thisCheque.acfontsize = thisCheque.acfontsize;
        // thisCheque.bearerwidth = thisCheque.bearerwidth;
        thisCheque.bearerx = xAdjust + thisCheque.bearerx;
        thisCheque.bearery = yAdjust + thisCheque.bearery;
        // thisCheque.payeewidth = thisCheque.payeewidth;
        thisCheque.payeex = xAdjust + thisCheque.payeex;
        thisCheque.payeey = yAdjust + thisCheque.payeey;
        // thisCheque.payeefontsize = thisCheque.payeefontsize;
        // thisCheque.amttextwidth = thisCheque.amttextwidth;
        thisCheque.amttextx = xAdjust + thisCheque.amttextx;
        thisCheque.amttexty = yAdjust + thisCheque.amttexty;
        // thisCheque.amttextfontsize = thisCheque.amttextfontsize;
        // thisCheque.amtwidth = thisCheque.amtwidth;
        thisCheque.amtx = xAdjust + thisCheque.amtx;
        thisCheque.amty = yAdjust + thisCheque.amty;
        // thisCheque.amtfontsize = thisCheque.amtfontsize;
        // thisCheque.dateCellsize = thisCheque.dateCellsize;
        // thisCheque.dateCellfontsize = thisCheque.dateCellfontsize;
        thisCheque.dateCellx = xAdjust + thisCheque.dateCellx;
        thisCheque.dateCelly = yAdjust + thisCheque.dateCelly;


        (debugMode) && console.log(THBText(data.paidAmount));
        let chequeContent = [
            ...(cashiercheque
                ? []
                : [
                    {
                        style: 'chequeBlockTable',
                        table: {
                            widths: [mmToPoint(thisCheque.acwidth)],
                            body: [
                                [{
                                    text: 'A/C PAYEE ONLY',
                                    fontSize: thisCheque.acfontsize,
                                    border: [false, true, false, true],
                                    alignment: "center"
                                }]
                            ],
                        },
                        absolutePosition: {
                            x: mmToPoint(thisCheque.acx),
                            y: mmToPoint(thisCheque.acy)
                        },
                        margin: [0, 0, 0, 0],
                        padding: [0, 0, 0, 0],
                        layout: tableLayout
                    },
                    {
                        text: '/////',
                        absolutePosition: {
                            x: mmToPoint(thisCheque.bearerx),
                            y: mmToPoint(thisCheque.bearery)
                        },
                        fontSize: 20
                    }]),
            {
                style: 'chequeBlockTable',
                table: {
                    widths: [mmToPoint(thisCheque.payeewidth)],
                    body: [
                        [{
                            text: data.payeeName,
                            fontSize: thisCheque.payeefontsize,
                            border: [false, false, false, false],
                            alignment: "left"
                        }]
                    ],
                },
                absolutePosition: {
                    x: mmToPoint(thisCheque.payeex),
                    y: mmToPoint(thisCheque.payeey)
                },
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                layout: tableLayout
            },
            {
                style: 'chequeBlockTable',
                table: {
                    widths: [mmToPoint(thisCheque.amttextwidth)],
                    body: [
                        [{
                            text: '==(' + THBText(data.paidAmount) + ")==",
                            fontSize: thisCheque.amttextfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }]
                    ],
                },
                absolutePosition: {
                    x: mmToPoint(thisCheque.amttextx),
                    y: mmToPoint(thisCheque.amttexty)
                },
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                layout: tableLayout
            },
            {
                style: 'chequeBlockTable',
                table: {
                    widths: [mmToPoint(thisCheque.amtwidth)],
                    body: [
                        [{
                            text: '=' + numberWithCommas(((typeof data.paidAmount == "string")
                                ? numberWithCommas(data.paidAmount)
                                : numberWithCommas(data.paidAmount.toFixed(2)))) + "=",
                            fontSize: thisCheque.amtfontsize,
                            border: [false, false, false, false],
                            alignment: "center",
                            bold: true,
                        }]
                    ],
                },
                absolutePosition: {
                    x: mmToPoint(thisCheque.amtx),
                    y: mmToPoint(thisCheque.amty)
                },
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                layout: tableLayout
            },
            {
                style: 'chequeBlockTable',
                table: {
                    widths: [mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize), mmToPoint(thisCheque.dateCellsize)],
                    body: [
                        [{
                            text: paymentDate[0],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[1],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[2],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[3],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[4],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[5],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[6],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }, {
                            text: paymentDate[7],
                            fontSize: thisCheque.dateCellfontsize,
                            border: [false, false, false, false],
                            alignment: "center"
                        }]
                    ],
                },
                absolutePosition: {
                    x: mmToPoint(thisCheque.dateCellx),
                    y: mmToPoint(thisCheque.dateCelly)
                },
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                layout: tableLayout
            },


        ];
        var docDefinition = {
            content: chequeContent,
            defaultStyle: {
                font: "THSarabunNew",
                fontSize: 18
            },
            // a string or { width: number, height: number }
            pageSize: 'A4',
            // by default we use portrait, you can change it to landscape if you wish
            pageOrientation: 'portrait',
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [0, 0, 0, 0],
        };

        // let targetPDF = pdfMake.createPdf(docDefinition);
        // targetPDF.download("")
        pdfMake.createPdf(docDefinition).open()
    } else {
        openNotificationWithIconShort("error", "Cheque Template for " + sourceBank + " not found", "Please add cheque config in general_config.js");
        return 1;
    }

}

export function generateChequeDebugPattern(data, callback) {

    let tableLayout = {
        hLineWidth: function (i, node) {
            return 1;
        },
        vLineWidth: function (i, node) {
            return 1;
        },
        hLineColor: function (i, node) {
            return 'gray';
        },
        vLineColor: function (i, node) {
            return 'gray';
        },
        paddingLeft: function (i, node) {
            return 0;
        },
        paddingRight: function (i, node) {
            return 0;
        },
        paddingTop: function (i, node) {
            return 0;
        },
        paddingBottom: function (i, node) {
            return 0;
        },
        marginLeft: function (i, node) {
            return 0;
        },
        marginRight: function (i, node) {
            return 0;
        },
        marginTop: function (i, node) {
            return 0;
        },
        marginBottom: function (i, node) {
            return 0;
        },
    };
    let x, y;
    let chequeContent = [];

    for (let i = 0; i < 42; i++) {

        for (let j = 0; j < 20; j++) {

            x = (5 * i);
            y = (5 * j);
            chequeContent.push({
                style: 'chequeBlockTable',
                table: {
                    widths: [mmToPoint(5)],
                    body: [
                        [{
                            text: "" + x + '.' + y,
                            fontSize: 6,
                            border: [false, false, false, false],
                            alignment: "left"
                        }]
                    ],
                },
                absolutePosition: {
                    x: mmToPoint(x),
                    y: mmToPoint(y)
                },
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                layout: tableLayout
            },)
        }
        // code goes here
    }

    var docDefinition = {
        content: chequeContent,
        defaultStyle: {
            font: "THSarabunNew",
            fontSize: 18
        },
        // a string or { width: number, height: number }
        pageSize: 'A4',
        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [0, 0, 0, 0],
    };

    // let targetPDF = pdfMake.createPdf(docDefinition);
    // targetPDF.download("")
    pdfMake.createPdf(docDefinition).open()
}
*/
