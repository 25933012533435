export const recaptchaSitekey = "6LfAjGQcAAAAAITV2AZBt9Dkipl1HBaBVuiD0iZm";
export const recaptchaTestkey ="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
export const projectName = {
    "DCB": ["DCB","Deposit for Customs Broker"],
    "CDI": ["CDI","Container Deposit Insurance"],
    "DIP": ["DIP","Default Insurance Program"],
    "ADMIN": ["ADMIN","TSSC e-Admin"],
    "OTHER": ["TSSC e-Services","TSSC e-Services"],
};
export const enabledModule = [
    // "CDI",
    // "DCB",
    "DIP",
]

export const defaultModule = "DIP";
