import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
// import contactSagas from './contacts/saga';
// import invoicesSagas from './invoice/saga';
// import transactionSagas from './transaction/saga';
// import statementSagas from './statement/saga';
// import financialSagas from './financial/saga';
// import reportSagas from './reports/saga';
import basedataSagas from './basedata/saga';
import dipdataSagas from './dipdata/saga';
import saledataSagas from './saledata/saga';
import restockdataSagas from './restockdata/saga';
//

// import mailSagas from './mail/saga';
// import notesSagas from './notes/saga';
// import todosSagas from './todos/saga';
// import ecommerceSaga from './ecommerce/saga';
// import cardsSagas from './card/saga';
// import chatSagas from './chat/sagas';
// import youtubeSearchSagas from './youtubeSearch/sagas';
// import devSagas from '../customApp/redux/sagas';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        // // contactSagas(),
        // // mailSagas(),
        // // notesSagas(),
        // // todosSagas(),
        // // ecommerceSaga(),
        // // cardsSagas(),
        // invoicesSagas(),
        // // chatSagas(),
        // // youtubeSearchSagas(),
        // // devSagas(),
        // transactionSagas(),
        // statementSagas(),
        // financialSagas(),
        // reportSagas(),
        basedataSagas(),
        dipdataSagas(),
        saledataSagas(),
        restockdataSagas(),
    ]);
}
