import fetch from 'isomorphic-fetch';
import {checkStatus} from "../../utility/fetch_preprocessor";
import stringify from 'json-stringify-safe';
import moment from "moment";
// const serverPrefix = "http://localhost:3000";
const serverPrefix = "/reorder";
const authString = "";

export function getSalesDetail(param) {
    // this.props.getAttachmentFile(x.uuid);
    // const serverPrefix = (window.location.port == "4567") ? window.AppSettings.siteServerTest : window.AppSettings.siteServer;
    let dateSetting;
    if (!(param.selectedDate)) {
        dateSetting = [moment().subtract(4, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')]
    } else {
        dateSetting = param.selectedDate;
    }

    return fetch(serverPrefix + `/getSalesDetail/` + param.branchNumber,
        {
            mode: 'cors',
            method: 'CHECKOUT',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({branchNumber: param.branchNumber, startDate: dateSetting[0], endDate: dateSetting[1]})
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
};

export function saveReorderData(param) {
    return fetch(serverPrefix + `/newReorder`,
        {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('userData')).token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param)
        })
        .then(checkStatus)
        .then(res => res.json())
        .catch(err => console.log(err));
};
