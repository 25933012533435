import { store, history } from '../redux/store';

export function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        if (response.status === 401) {
            localStorage.removeItem('userData');
            window.location.href = "/"
        } else {
            let error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}
