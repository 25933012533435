import {all, takeEvery, fork, call, put} from 'redux-saga/effects';
import {authenticatorActionFn, authenticatorActionType} from "./actions";
import {notification, message} from 'antd';
// import {customerAgentListSelector} from "./selector";
import * as API from './api';
import {createAction} from "redux-actions";
// import {saveAs} from "file-saver";
// import {transactionActionFn, transactionActionType} from "../transaction/actions";
// import {statementActionFn} from "../statement/actions";
// import * as PDFGen from "../../helpers/pdfgenerator/pdfgen";
import _ from "lodash";
import {history} from "../store";

message.config({
    top: 20,
    duration: 3,
    maxCount: 1,
});


const authString = "";

const openNotificationWithIcon = (type, header, detail) => {
    notification[type]({
        message: header,
        description: detail,
    });
};

const openNotificationWithIconShort = (type, header, detail) => {
    if ((detail || null) !== null) {
        message[type](header + " / " + detail, 3, null);
    } else {
        message[type](header, 3, null);
    }

};

const openNotificationWithCallback = (type, text, callback) => {
    message[type](text, 3, callback);
};

function* signin(action) {
    //console.log(action.payload);
    console.log("Login Request");
    // yield put(authenticatorActionFn.loginSuccess());

    try {
        if ("token" in action.payload) {
            checkTokenValidity();
        } else {
            const loginResult = yield call(API.login, action.payload);
            // console.log(loginResult);
            if (("success" in loginResult) && loginResult.success) {
                yield put(authenticatorActionFn.loginSuccess(loginResult));
                localStorage.setItem('userData', JSON.stringify(loginResult));
                openNotificationWithCallback('success', "Logged in, now redirecting to Dashboard.", history.push("/offlineStore/configuration"));

            } else {
                openNotificationWithCallback('error', "Login failed : " + loginResult.message);
            }
        }

    }
    catch (err) {
        // console.log(err[message]);
        openNotificationWithCallback('error', "Cannot login : " + err.message);
    }
}

function* signup(action) {
    //console.log(action.payload);
    try {

        const signupResult = yield call(API.signup, action.payload);
        // console.log(signupResult);
        if ("user" in signupResult) {
            console.log(signupResult.user);
            openNotificationWithCallback('success', "Account has been created.", history.push("/"));
            // yield put(financialActionFn.getFinancialBankListComplete(suggestions.data));
        } else {
            console.log(signupResult.error);
            //yield put(financialActionFn.successFinancialAgentListSuggest([]));
        }
    }
    catch (error) {
        openNotificationWithCallback('error', "Cannot create account.");
    }
}

function* checkTokenValidity(action) {
    //console.log(action.payload);
    try {
        if (localStorage.getItem('userData') !== null) {

            let userData = JSON.parse(localStorage.getItem('userData'));
            const loginResult = yield call(API.checktoken, userData);
            // console.log(loginResult);
            if (("success" in loginResult) && loginResult.success) {
                yield put(authenticatorActionFn.loginSuccess(loginResult));
                localStorage.setItem('userData', JSON.stringify(loginResult));
                if (history.location.pathname === "/") {
                    openNotificationWithIconShort('success', "Already logged in, now redirecting to Dashboard.");
                    history.push("/store");
                }
                ;

            } else {
                if (history.location.pathname !== "/") {
                    openNotificationWithCallback('error', "Token not found or expired : Redirect to login" + (loginResult.message||""));
                    history.push("/");
                }
            }
        } else {

        }
    }
    catch (err) {
        // console.log(err[message]);
        openNotificationWithCallback('error', "Cannot login : " + err.message);
    }
}

function* signout(action) {
    yield put(authenticatorActionFn.logoutSuccess());
    localStorage.removeItem('userData');
    openNotificationWithCallback('success', "Logged out! -> Redirecting to login-page.", history.push("/"));

}

export default function* rootSaga() {
    yield all([
        takeEvery(authenticatorActionType.AUTHENTICATOR.CHECK_AUTHORIZATION, checkTokenValidity),
        takeEvery(authenticatorActionType.AUTHENTICATOR.SIGNUP_REQUEST, signup),
        takeEvery(authenticatorActionType.AUTHENTICATOR.LOGIN_REQUEST, signin),
        takeEvery(authenticatorActionType.AUTHENTICATOR.LOGOUT, signout),
    ]);
}

