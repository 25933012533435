import {createAction} from "redux-actions";
import {createActionThunk} from "redux-thunk-actions";
import {createSelector} from "reselect";
import _ from "lodash";

/*

// import {contacts} from "./reducer";

import {transactionActionType} from "../transaction/actions";
import {statementActionType} from "../statement/actions";

import {Decimal} from 'decimal.js';

Decimal.set({precision: 20, rounding: 4});

function ascendingSort(contact1, contact2) {
    const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
    const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

export default {...financialActionFn};

 */

export const authenticatorActionType = {
  AUTHENTICATOR: {
      CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
      LOGIN_REQUEST: 'LOGIN_REQUEST',
      LOGIN_SUCCESS: 'LOGIN_SUCCESS',
      LOGIN_ERROR: 'LOGIN_ERROR',
      LOGOUT: 'LOGOUT',
      LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
      SIGNUP_REQUEST: 'SIGNUP_REQUEST',
      SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
      SIGNUP_ERROR: 'SIGNUP_ERROR',
  }
};

export const authenticatorActionFn = {

    checkAuthorization: createAction(authenticatorActionType.AUTHENTICATOR.CHECK_AUTHORIZATION),
    login: createAction(authenticatorActionType.AUTHENTICATOR.LOGIN_REQUEST),
    loginSuccess: createAction(authenticatorActionType.AUTHENTICATOR.LOGIN_SUCCESS),
    logout: createAction(authenticatorActionType.AUTHENTICATOR.LOGOUT),
    logoutSuccess: createAction(authenticatorActionType.AUTHENTICATOR.LOGOUT_SUCCESS),
    signup: createAction(authenticatorActionType.AUTHENTICATOR.SIGNUP_REQUEST),

};

export default {...authenticatorActionFn};
