import {all, takeEvery, fork, call, put, delay,take} from 'redux-saga/effects';
import {dipDataActionType, dipDataActionFn} from "./actions";
import {notification, message} from 'antd';
import * as API from './api';
import _ from "lodash";
import moment from "moment-timezone";
import {inspect} from 'util'
import {getProductList, updateOfflineStoreConfiguration, updateSiteConfiguration} from "./api";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);

const authString = "";
const openNotificationWithIcon = (type, header, detail) => {
    notification[type]({
        message: header,
        description: detail,
        duration: 5,
    });
};

const openStayNotificationWithIcon = (key, duration, type, header) => {
    let targetDuration = duration;
    notification[type]({
        key,
        message: header,
        duration: targetDuration,
    });
};

const closeStayNotification = (key) => {
    notification.close(key);
}


function* fetchDipdata(payload, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Loading DIP Base-Data.");
    try {
        let dipdata = yield call(API.getDipData);

        if (!("error" in dipdata)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Base-Data Loaded.");
            yield put(dipDataActionFn.dipdataFetchSuccess(dipdata));
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
            yield put(dipDataActionFn.dipdataFetchFail());
        }

    } catch (error) {
        console.log(error);
        yield put(dipDataActionFn.dipdataFetchFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* updateDipdata(payload, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Updating DIP Base-Data.");
    try {
        let dipdata = yield call(API.updateDipData);
        if (!("error" in dipdata)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Base-Data Updated.");
            yield put(dipDataActionFn.dipdataUpdateSuccess(dipdata));
            let updateResultData = dipdata.result;
            // console.log(updateResultData);
            MySwal.fire({
                    icon: 'info',
                    html: 'Data has been updated : <br />' +
                        "Agent : " + updateResultData.agent.length + '<br />' +
                        "Airline : " + updateResultData.airline.length + '<br />' +
                        "Periods : " + updateResultData.period.length + '<br />'
                    ,
                    confirmButtonText: 'Reload',
                }
            ).then((result) => {
                window.location.reload();
            })
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot update DIP Base-Data");
            yield put(dipDataActionFn.dipdataUpdateFail());
            // console.log(config);
        }

    } catch (error) {
        console.log(error);
        yield put(dipDataActionFn.dipdataUpdateFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* updateDipInfoData(data, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Updating DIP Addition Information.");
    try {
        let dipdata = yield call(API.updateDipInfoData,data.payload);
        if (!("error" in dipdata)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Base-Data Updated.");
            yield put(dipDataActionFn.dipdataInfoupdateSuccess(dipdata));
            let updateResultData = dipdata.result;
            // console.log(updateResultData);
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot update DIP Base-Data");
            yield put(dipDataActionFn.dipdataInfoupdateFail());
            // console.log(config);
        }

    } catch (error) {
        console.log(error);
        yield put(dipDataActionFn.dipdataUpdateFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* updateDipDepositData(data, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Updating DIP Deposit.");
    try {
        let dipdata = yield call(API.updateDipDepositData,data.payload);
        if (!("error" in dipdata)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Deposit Updated.");
            yield put(dipDataActionFn.dipdataDepositupdateSuccess(dipdata));
            let updateResultData = dipdata.result;
            // console.log(updateResultData);
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot update DIP Deposit");
            yield put(dipDataActionFn.dipdataDepositupdateFail());
            // console.log(config);
        }

    } catch (error) {
        console.log(error);
        yield put(dipDataActionFn.dipdataUpdateFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* fetchDipSalesData(data, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Loading DIP Sale-Data.");
    // console.log(data.payload)
    try {
        let dipdata = yield call(API.getDipSalesData,data.payload);

        if (!("error" in dipdata)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Sale-Data Loaded.");
            yield put(dipDataActionFn.dipdataSalesFetchSuccess(dipdata));
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Sale-Data");
            yield put(dipDataActionFn.dipdataSalesFetchFail());
        }

    } catch (error) {
        console.log(error);
        yield put(dipDataActionFn.dipdataSalesFetchFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot load DIP Base-Data");
    }
}

function* resetStakeholder(payload, dispatch) {
    openStayNotificationWithIcon("dipbase", 0, "info", "Resetting DIP Stakeholder-Data.");
    try {
        let dipdata = yield call(API.resetStakeholderData);
        if (!("error" in dipdata)) {
            openStayNotificationWithIcon("dipbase", 3, "success", "DIP Stakeholder-Data reset.");
            yield put(dipDataActionFn.dipdataStakeholderResetSuccess(dipdata));
            let updateResultData = dipdata.result;
            // console.log(updateResultData);
            MySwal.fire(
                'Done!',
                'Data has been reset',
                'success'
            )
        } else {
            openStayNotificationWithIcon("dipbase", 3, "error", "Cannot reset DIP DIP Stakeholder-Data");
            yield put(dipDataActionFn.dipdataStakeholderResetFail());
            // console.log(config);
        }

    } catch (error) {
        console.log(error);
        yield put(dipDataActionFn.dipdataStakeholderResetFail());
        openStayNotificationWithIcon("dipbase", 3, "error", "Cannot reset DIP DIP Stakeholder-Data");
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(dipDataActionType.DIPDATA.FETCH, fetchDipdata),
        takeEvery(dipDataActionType.DIPUPDATE.FETCH, updateDipdata),
        takeEvery(dipDataActionType.DIPINFOUPDATE.FETCH, updateDipInfoData),
        takeEvery(dipDataActionType.DIPDEPOSITUPDATE.FETCH, updateDipDepositData),
        takeEvery(dipDataActionType.DIPSTAKEHOLDER_RESET.FETCH, resetStakeholder),
        takeEvery(dipDataActionType.DIPSALESDATA.FETCH, fetchDipSalesData),
    ]);
}
