import {saleDataActionFn, saleDataActionType} from "./actions";
import {createSelector} from 'reselect'
import moment from 'moment';
import _ from 'lodash';
import update from 'immutability-helper';
// import {reportActionType as reportAction} from "../reports/actions";

let today = new Date();
let currentDMY = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
let todayMoment = new moment();


const initial = {
    currentBranch: "",
    currentSaleData: [],
    currentSaleDataSelection: [],
    currentDataRange:{startDate:moment(),endDate:moment()},
    currentBuyData: [],
    currentBuyDataSelection: [],
    currentPendingData: [],
    currentPendingDataSelection: [],
    orderData: [],
    buyRevision:0,
    pendingRevision:0,
};

export default function saleDataReducer(state = initial, action) {
    let currentSelectedStatement = [];
    let siteUpdateList = {};
    let siteListByKey = {};
    let storeListByKey = {};

    switch (action.type) {

        case saleDataActionType.SALES_ITEM.SUCCESS:
            return update(state,{
                currentSaleData: {$set:action.payload.saleData},
                currentBranch: {$set:action.payload.branchNumber},
                currentDataRange: {$set:action.payload.dataRange}
            });

        case saleDataActionType.SALES_ITEM.RESET:
            return update(state,{
                currentSaleData: {$set:[]},
                currentPendingData: {$set:[]},
                currentBuyData: {$set:[]},
                currentBranch: {$set:""},
            });

        case saleDataActionType.SALES_ITEM.SELECT:
            // console.log(action.payload);
            return update(state,{
                currentSaleDataSelection: {$set:action.payload},
            });

        case saleDataActionType.REORDER.SUCCESS:
            return update(state,{
                currentSaleData: {$set:[]},
                currentPendingData: {$set:[]},
                currentBuyData: {$set:[]},
                currentBranch: {$set:""},
            });


        case saleDataActionType.BUY_ITEM.ADD + "_SUCCEEDED" :
            return update(state,{
                currentBuyData: {$set:action.payload.buyData},
                currentPendingData : {$set:action.payload.pendingData},
                buyRevision: {$set:state.buyRevision + 1},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        case saleDataActionType.BUY_ITEM.UPDATE + "_SUCCEEDED" :
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentBuyData: {[action.payload.target]:{$set:action.payload.data}},
                buyRevision: {$set:state.buyRevision + 1}
            });

        case saleDataActionType.PENDING_ITEM.UPDATE + "_SUCCEEDED" :
            // console.log(action.payload.target,action.payload.data)
            return update(state,{
                currentPendingData: {[action.payload.target]:{$set:action.payload.data}},
                pendingRevision: {$set:state.pendingRevision + 1}
            });

        default:
            return state;

    }

}
