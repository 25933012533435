import React, {Component} from 'react';
import {
    Col, Row, Icon, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, Form, Breadcrumb, Layout,
    Button, InputNumber, Cascader, AutoComplete, DatePicker, Upload
} from 'antd';
import {UploadOutlined} from "@ant-design/icons";
import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import Helmet from "react-helmet";
// import validThaiID from 'thai-id-validator';
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';
import {update} from 'immutability-helper';
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

// const {TextArea} = Input;
// const FormItem = Form.Item;
// const uuidv4 = require('uuid/v4');
// const {Option, OptGroup} = Select;
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";

const {Header, Content, Footer} = Layout;

const moment = Moment;
const {Option} = Select;

let todayMoment = new moment();

function ThaiNationalID (id) {
    if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) {
        return false
    }
    let i; let sum = 0
    for ((i = 0), (sum = 0); i < 12; i++) {
        sum += parseInt(id.charAt(i)) * (13 - i)
    }
    const check = (11 - sum % 11) % 10
    if (check === parseInt(id.charAt(12))) {
        return true
    }
    return false
}

class Announcement extends Component {
    state = {
        mode: "view",
        htmlData: "",
        fileList: [],
        signup:{
            recaptcha:"",

        }
    };


    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });


    componentDidMount() {
        // console.log(this.formData);
    }

    permitUploaderProps = {
        beforeUpload: (file) => {
            let files = [];
            this.setState({
                fileList: [...files]
            })
            return false;
        },
    }

    formSubmitter = event => {
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key
        formData.append("file", this.state.fileList[0].originFileObj);

        // axios
        //     .post("http://api.foo.com/bar", formData)
        //     .then(res => {
        //         console.log("res", res);
        //     })
        //     .catch(err => {
        //         console.log("err", err);
        //     });
    };

    render() {
        let formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 14},
            }
        };

        let tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        let prefixSelector = (
            <Form.Item name="prefix" noStyle>
                <Select style={{width: 110}}>
                    <Option value="+661">+66 (01)</Option>
                    <Option value="+662">+66 (02)</Option>
                    <Option value="+663">+66 (03)</Option>
                    <Option value="+664">+66 (04)</Option>
                    <Option value="+665">+66 (05)</Option>
                    <Option value="+666">+66 (06)</Option>
                    <Option value="+667">+66 (07)</Option>
                    <Option value="+668">+66 (08)</Option>
                    <Option value="+669">+66 (09)</Option>

                </Select>
            </Form.Item>
        );

        let suffixSelector = (
            <Form.Item name="suffix" noStyle>
                <Select style={{width: 70}}>
                    <Option value="USD">$</Option>
                    <Option value="CNY">¥</Option>
                </Select>
            </Form.Item>
        );

        let permitUploaderProp = {
            beforeUpload: (file) => {
                let files = [];
                this.setState({
                    fileList: [...files]
                })
                return false;
            },
        }

        const reCAPTCHAHandle = (value) => {
            // console.log("Captcha value:", value);
            this.setState({signup: update(this.state.signup, {recaptcha: {$set: value}})})
        }

        return (
            <Content style={{padding: '0 50px', marginTop: 64}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>{this.state.mode === "edit" ? "สมัครสมาชิก" : "แก้ไขข้อมูลสมาชิก"}</Breadcrumb.Item>
                </Breadcrumb>
                <Helmet>
                    {this.state.mode === "edit" ? "สมัครสมาชิก" : "แก้ไขข้อมูลสมาชิก"}
                </Helmet>
                <Form
                    {...formItemLayout}
                    name="register"
                    initialValues={{
                        prefix: '+662',
                    }}
                    scrollToFirstError
                >
                    <Form.Item
                        name="companyRegisteredNumber"
                        label="เลขทะเบียนนิติบุคคล 13 หลัก"
                        rules={[
                            {
                                required: true,
                                min: 13,
                                max: 13,
                                message: 'กรุณาระบุเลขทะเบียนนิติบุคคล',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (ThaiNationalID(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('เลขทะเบียนนิติบุคคลไม่ถูกต้อง'));
                                },
                            }),
                        ]}
                    >
                        <Input placeholder={"เลขทะเบียนนิติบุคคล 13 หลัก (ไม่ต้องใส่ เครื่องหมาย '-') (จะถูกใช้เป็น Username สำหรับการเข้าใช้งาน)"}/>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input
                            placeholder={"E-mail จะถูกใช้สำหรับการส่งข้อมูลข่าวสาร / แจ้งเตือนการชำระเงิน-วันหมดอายุ"}/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="company_name_th"
                        label="ชื่อบริษัท (ภาษาไทย)"
                        rules={[{
                            required: true,
                            message: 'กรุณากรอกชื่อบริษัท สำหรับการใช้ออกเอกสารประกัน (มีคำนำหน้า และ ลงท้าย)',
                            whitespace: true
                        }]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="company_name_en"
                        label="ชื่อบริษัท (ภาษาอังกฤษ)"
                        rules={[{required: false, whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="company_permit_number"
                        label="เลขที่ใบอนุญาต"
                        rules={[{required: true, message: 'เลขที่ใบอนุญาต', whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item name="company_permit_range" label="วันที่ของใบอนุญาต" style={{marginBottom: 0}}>
                        <Form.Item
                            style={{display: 'inline-block', width: '150px'}}
                        >
                            <DatePicker name="company_permit_range_start" />
                        </Form.Item>
                        <span style={{
                            display: 'inline-block',
                            width: '24px',
                            lineHeight: '32px',
                            textAlign: 'center'
                        }}> - </span>
                        <Form.Item style={{display: 'inline-block', width: '150px'}}>
                            <DatePicker name="company_permit_range_end" />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        name="upload"
                        label="อัพโหลด"
                        valuePropName="fileList"

                    >
                        <Upload name="permit_document"
                                maxCount={3}
                                {...this.permitUploaderProps}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="main_contact"
                        label="ชื่อผู้ติดต่อ"
                        rules={[{required: false, whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="main_contact_number"
                        label="หมายเลขโทรศัพท์ (ผู้ติดต่อ)"
                        rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Input addonBefore={prefixSelector} style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        name="association"
                        label="สมาคมที่สังกัด"
                        // rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Select>
                            <Select.Option disabled>กรุณาเลือกสมาคมที่สังกัด</Select.Option>
                            <Select.Option>TACBA : Thai Authorized Customs Brokers Association</Select.Option>
                            <Select.Option>CTAT : The Customs Broker and Transportation Association of Thailand</Select.Option>
                            <Select.Option>ECA : THE THAI ELECTRONIC CUSTOMS BROKERS ASSOCIATION</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="association_number"
                        label="เลขที่สมาชิกสมาคม"
                        rules={[{required: false, whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="package"
                        label="แพคเกจการใช้บริการ"
                        // rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Select>
                            <Select.Option disabled>กรุณาระบุทุนประกันที่ต้องการ</Select.Option>
                            <Select.Option>ทุนประกัน/เงินมัดจำ = 1,000,000 บาท :: อัตราค่าเบี้ย 1,000 บาท </Select.Option>
                            <Select.Option>ทุนประกัน/เงินมัดจำ = 2,000,000 บาท :: อัตราค่าเบี้ย 2,000 บาท </Select.Option>
                            <Select.Option>ทุนประกัน/เงินมัดจำ = 5,000,000 บาท :: อัตราค่าเบี้ย 5,000 บาท </Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item label="Captcha" extra="We must make sure that your are a human.">
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="captcha"
                                    noStyle
                                    rules={[{required: true, message: 'Please input the captcha you got!'}]}
                                >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LfAjGQcAAAAAITV2AZBt9Dkipl1HBaBVuiD0iZm"
                                        // size={'invisible'}
                                        onChange={reCAPTCHAHandle}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                            },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            I have read the <Button type="link">agreement</Button>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {}
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
// export default connect(mapStateToProps, {})(WrappedFormWIthSubmissionButton);
export default connect(mapStateToProps, {})(Announcement);
//export default ;
