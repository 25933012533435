import React, {Component} from 'react';
import {
    Col, Row, Icon, Checkbox, Radio, Popconfirm, Select,
    Divider, Table, Input, Modal, Form, Breadcrumb, Layout,
    Button, InputNumber, Cascader, AutoComplete, DatePicker, Upload
} from 'antd';
import {UploadOutlined} from "@ant-design/icons";
import _ from "lodash";
import {connect} from "react-redux";
import Moment from "moment";
import Helmet from "react-helmet";
// import validThaiID from 'thai-id-validator';
import fetch from "isomorphic-fetch";
import {checkStatus} from "../../utility/fetch_preprocessor";
import {saveAs} from "file-saver";
import {Document} from 'react-pdf';
import {update} from 'immutability-helper';
// import Reaptcha from 'reaptcha';
import ReCAPTCHA from "react-google-recaptcha";
import {recaptchaSitekey, recaptchaTestkey} from "../../constants";

const recaptchaRef = React.createRef();
// const {TextArea} = Input;
// const FormItem = Form.Item;
// const uuidv4 = require('uuid/v4');
// const {Option, OptGroup} = Select;
// const {MonthPicker, RangePicker} = DatePicker;
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;
// const authString = "";

const {Header, Content, Footer} = Layout;

const moment = Moment;
const {Option} = Select;

const serverPrefix = (window.location.port === 3003) ? "http://localhost:32100" : "";

let todayMoment = new moment();

function ThaiNationalID(id) {
    if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) {
        return false
    }
    let i;
    let sum = 0
    for ((i = 0), (sum = 0); i < 12; i++) {
        sum += parseInt(id.charAt(i)) * (13 - i)
    }
    const check = (11 - sum % 11) % 10
    if (check === parseInt(id.charAt(12))) {
        return true
    }
    return false
}

class Register extends Component {
    state = {
        mode: "view",
        htmlData: "",
        fileList: [],
        signup: {
            recaptcha: "",
            usertype: "corporate"
        }
    };

    isCorporate = () => {
        return (this.state.signup.usertype) === "corporate"
    }


    trimmer = (x, y) => _.truncate(x, {
        'length': y,
        'omission': ' ...'
    });


    componentDidMount() {
        // console.log(this.formData);
        // console.log(this.props);
    }

    permitUploaderProps = {
        beforeUpload: (file) => {
            let files = [];
            this.setState({
                fileList: [...files]
            })
            return false;
        },
    }

    formSubmitter = event => {
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key
        formData.append("file", this.state.fileList[0].originFileObj);

        // axios
        //     .post("http://api.foo.com/bar", formData)
        //     .then(res => {
        //         console.log("res", res);
        //     })
        //     .catch(err => {
        //         console.log("err", err);
        //     });
    };

    saveSignUpData = (param) => {
        console.log("Signing-up");
        return fetch(serverPrefix+`/acs/signup`,
            {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(param)
            })
            .then(checkStatus)
            .then(res => res.json())
            .catch(err => console.log(err));
    };

    render() {
        let formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 14},
            }
        };

        let tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        let suffixSelector = (
            <Form.Item name="suffix" noStyle>
                <Select style={{width: 70}}>
                    <Option value="USD">$</Option>
                    <Option value="CNY">¥</Option>
                </Select>
            </Form.Item>
        );

        let permitUploaderProp = {
            beforeUpload: (file) => {
                let files = [];
                this.setState({
                    fileList: [...files]
                })
                return false;
            },
        }

        const reCAPTCHAHandle = (value) => {
            this.setState({signup: {...this.state.signup, recaptcha: value}})
        }

        const validateCaptcha = (_, value) => {
            const {recaptcha} = this.state.signup;
            // console.log(this.state);
            console.log(recaptcha);
            if (recaptcha !== "") {
                return Promise.resolve("Captcha Done");
            } else {
                return Promise.reject(new Error("Captcha Fail"));
            }

        };

        const requiredModeCheck = (mode) => {
            if (mode.indexOf("all") >= 0 ) {
                return true
            }
            if (mode.indexOf(this.props.match.params.system) >= 0) {
                return true
            }
            return false
        }

        return (
            <Content style={{padding: '0 50px', marginTop: 64}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        {this.props.match.params.mode === "register" ? "สมัครสมาชิก" : "แก้ไขข้อมูลสมาชิก"}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {
                            {
                                "DCB": "Deposit for Customs Broker",
                                "DIP": "Default Insurance Program",
                                "CDI": "Container Deposit Insurance",
                                "ADMIN": "ADMIN INTERFACE"
                            }[this.props.match.params.system]
                        }
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Helmet>
                    {this.props.match.params.mode === "register" ? "สมัครสมาชิก" : "แก้ไขข้อมูลสมาชิก"} /
                    {
                        {
                            "dcb": "Deposit for Customs Broker"
                        }[this.props.match.params.system]
                    }
                </Helmet>
                <Form
                    {...formItemLayout}
                    name="register"
                    initialValues={{
                        email: "",
                        password: "",
                        association: 'unselected',
                        usertype: "corporate",
                    }}
                    scrollToFirstError
                    onFinish={(x) => {
                        let formData = {
                            ...x,
                            system: this.props.match.params.system,
                            mode: this.props.match.params.mode,
                        }
                        this.saveSignUpData(formData);
                        console.log(formData);
                    }}
                >
                    <Form.Item
                        name="usertype"
                        label="ประเภทสมาชิก"
                        // rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Radio.Group value={this.state.signup.usertype}
                                     onChange={(x) => {
                                         // console.log(x);
                                         this.setState({signup: {...this.state.signup, usertype: x.target.value}})
                                     }}>
                            <Radio value={"corporate"}>นิติบุคคล</Radio>
                            <Radio value={"individual"}>บุคคลธรรมดา</Radio>
                            {requiredModeCheck(["admin","DIP"]) && <>
                                <Radio value={"thirdparty"}>บริษัทประกัน/หุ้นส่วนธุรกิจอื่นๆ</Radio>
                            </>}
                            {requiredModeCheck(["admin"]) && <>
                                <Radio value={"thaistar"}>พนักงานบริษัท Thaistar</Radio>
                                <Radio value={"admin"}>Admin</Radio>
                            </>}
                        </Radio.Group>
                    </Form.Item>
                    {requiredModeCheck(["DIP","admin"]) && <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Username สำหรับการเข้าใช้งาน"
                        />
                    </Form.Item>
                    }

                    {requiredModeCheck(["DCB","CDI"]) && <Form.Item
                        name="companyRegisteredNumber"
                        label={this.isCorporate() ? "เลขทะเบียนนิติบุคคล 13 หลัก" : "เลขที่บัตรประจำตัวประชาชน 13 หลัก"}
                        rules={[
                            {
                                required: true,
                                min: 13,
                                max: 13,
                                message: this.isCorporate() ? "เลขทะเบียนนิติบุคคล 13 หลัก" : "เลขที่บัตรประจำตัวประชาชน 13 หลัก",
                            },
                            {
                                validator(_, value) {
                                    // console.log(value);
                                    // console.log(ThaiNationalID(value));
                                    if (ThaiNationalID(value)) {
                                        return Promise.resolve("เลขทะเบียนถูกต้อง");
                                    }
                                    return Promise.reject(new Error('เลขทะเบียนนิติบุคคลไม่ถูกต้อง'));
                                },
                            },
                        ]}
                    >
                        <Input
                            placeholder={this.state.signup.usertype === "corporate"
                                ? "เลขทะเบียนนิติบุคคล 13 หลัก (ไม่ต้องใส่ เครื่องหมาย '-') (จะถูกใช้เป็น Username สำหรับการเข้าใช้งาน)"
                                : "เลขที่บัตรประจำตัวประชาชน 13 หลัก (ไม่ต้องใส่ เครื่องหมาย '-') (จะถูกใช้เป็น Username สำหรับการเข้าใช้งาน)"}
                        />
                    </Form.Item>}
                    {requiredModeCheck(["all"]) && <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'กรุณากรอกอีเมล์ให้ถูกรูปแบบ',
                            },
                            {
                                required: true,
                                message: 'กรุณากรอกอีเมล์',
                            },
                        ]}
                    >
                        <Input
                            placeholder={"E-mail จะถูกใช้สำหรับการส่งข้อมูลข่าวสาร / แจ้งเตือนการชำระเงิน-วันหมดอายุ"}/>
                    </Form.Item>}

                    {requiredModeCheck(["all"]) && <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรหัสผ่าน',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>}

                    {requiredModeCheck(["all"]) && <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรหัสผ่านซ้ำอีกครั้ง',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('รหัสผ่านทั้งสองช่องไม่เหมือนกัน'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>}

                    {requiredModeCheck(["admin"]) && <Form.Item
                        name="adminSecret"
                        label="Admin Secret"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรหัส Admin Interface',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","DIP","CDI"]) && <Form.Item
                        name="company_name_th"
                        label={this.isCorporate()
                            ? "ชื่อบริษัท (ไทย)"
                            : "ชื่อ-นามสกุล (ไทย)"}
                        rules={[{
                            required: true,
                            message: this.isCorporate()
                                ? "กรุณากรอก ชื่อบริษัท สำหรับการใช้ออกเอกสารประกัน (มีคำนำหน้า และ ลงท้าย)"
                                : "กรุณากรอก ชื่อ-นามสกุล สำหรับการใช้ออกเอกสารประกัน (มีคำนำหน้า และ ลงท้าย)",
                            whitespace: true
                        }]}
                    >
                        <Input/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","DIP","CDI"]) && <Form.Item
                        name="company_name_en"
                        label={this.isCorporate()
                            ? "ชื่อบริษัท (Eng)"
                            : "ชื่อ-นามสกุล (Eng)"}
                        rules={[{required: false, whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","CDI"]) && <Form.Item
                        name="company_permit_number"
                        label="เลขที่ใบอนุญาต"
                        rules={[{required: true, message: 'กรุณากรอกเลขที่ใบอนุญาตของท่าน', whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","DIP","CDI"]) && <Form.Item
                        name="company_address"
                        label="ที่อยู่"
                        rules={[{required: true, message: 'กรุณากรอกที่อยู่', whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","CDI"]) && <Form.Item
                        name="company_permit_desc"
                        label="เอกสารเพิ่มเติม"
                    >
                        <span><span style={{color: "green"}}>หลังจากสร้างบัญชีแล้ว กรุณาจัดเตรียมใบอนุญาตเป็น JPG/PNG/PDF เพื่อทำการอัพโหลดให้ทางบริษัทตรวจสอบ</span></span>
                    </Form.Item>
                    }
                    {/*
                    <Form.Item name="company_permit_range" label="วันที่ของใบอนุญาต" style={{marginBottom: 0}}>
                        <Form.Item
                            style={{display: 'inline-block', width: '150px'}}
                        >
                            <DatePicker name="company_permit_range_start"/>
                        </Form.Item>
                        <span style={{
                            display: 'inline-block',
                            width: '24px',
                            lineHeight: '32px',
                            textAlign: 'center'
                        }}> - </span>
                        <Form.Item style={{display: 'inline-block', width: '150px'}}>
                            <DatePicker name="company_permit_range_end"/>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        name="upload"
                        label="อัพโหลด"
                        valuePropName="fileList"

                    >
                        <Upload name="permit_document"
                                maxCount={3}
                                {...this.permitUploaderProps}
                        >
                            <Button icon={<UploadOutlined/>}>Click to upload</Button>
                        </Upload>
                    </Form.Item>*/}

                    {requiredModeCheck(["DCB","DIP","CDI"]) && <Form.Item
                        name="main_contact"
                        label="ชื่อผู้ติดต่อ"
                        rules={[{required: false, whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","DIP","CDI"]) && <Form.Item
                        name="main_contact_number"
                        label="หมายเลขโทรศัพท์ (ผู้ติดต่อ)"
                        rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Input placeholder={"+66 (x) yyyy-yyyy"} style={{width: '100%'}}/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","CDI"]) && <Form.Item
                        name="association"
                        label="สมาคมที่สังกัด"
                        // rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Select>
                            <Select.Option value={"unselected"} disabled>กรุณาเลือกสมาคมที่สังกัด</Select.Option>
                            <Select.Option value={"tacba"}>TACBA : Thai Authorized Customs Brokers
                                Association</Select.Option>
                            <Select.Option value={"ctat"}>CTAT : The Customs Broker and Transportation Association of
                                Thailand</Select.Option>
                            <Select.Option value={"eca"}>ECA : THE THAI ELECTRONIC CUSTOMS BROKERS
                                ASSOCIATION</Select.Option>
                        </Select>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","CDI"]) && <Form.Item
                        name="association_number"
                        label="เลขที่สมาชิกสมาคม"
                        rules={[{required: false, whitespace: true}]}
                    >
                        <Input/>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","CDI"]) && <Form.Item
                        name="package"
                        label="แพคเกจการใช้บริการ"
                        // rules={[{required: true, message: 'Please input your phone number!'}]}
                    >
                        <Select>
                            <Select.Option value={"unselected"} disabled>กรุณาระบุทุนประกันที่ต้องการ</Select.Option>
                            <Select.Option value={"1000000"}>ทุนประกัน/เงินมัดจำ = 1,000,000 บาท :: อัตราค่าเบี้ย 1,000
                                บาท </Select.Option>
                            <Select.Option value={"2000000"}>ทุนประกัน/เงินมัดจำ = 2,000,000 บาท :: อัตราค่าเบี้ย 2,000
                                บาท </Select.Option>
                            <Select.Option value={"5000000"}>ทุนประกัน/เงินมัดจำ = 5,000,000 บาท :: อัตราค่าเบี้ย 5,000
                                บาท </Select.Option>
                        </Select>
                    </Form.Item>}


                    {requiredModeCheck(["all"]) && <Form.Item label="Captcha" extra="เพื่อป้องกันการกรอกข้อมูลสมัครสมาชิกโดยระบบอัตโนมัติ">
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="captcha"
                                    noStyle
                                >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={recaptchaSitekey} //SITE KEY
                                        onChange={reCAPTCHAHandle}
                                        onExpired={reCAPTCHAHandle}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>}

                    {requiredModeCheck(["DCB","DIP","CDI"]) && <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                            },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            I have read the <Button type="link">agreement</Button>
                        </Checkbox>
                    </Form.Item>}

                    <Form.Item
                        // name="system"
                        valuePropName="system"
                        hidden={true}
                    >
                        <Input type={"hidden"} name={"system"} value={this.props.match.params.system}/>
                    </Form.Item>

                    <Form.Item
                        // name="mode"
                        valuePropName="mode"
                        hidden={true}
                    >
                        <Input type={"hidden"} name={"mode"} value={this.props.match.params.mode}/>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" disabled={this.state.signup.recaptcha === ""}>
                            Register
                        </Button>
                    </Form.Item>
                </Form>
                {requiredModeCheck(["all"]) && <Button type="info" htmlType="submit" onClick={() => console.log(this.state.signup)}>
                    debug
                </Button>}
            </Content>
        )
            ;
    }
}

function mapStateToProps(state) {
    // console.log('mapping state to props')
    return {}
}

// const WrappedFormWIthSubmissionButton = Form.create()(dbDataEditorForm);
// export default connect(mapStateToProps, {})(WrappedFormWIthSubmissionButton);
export default connect(mapStateToProps, {})(Register);
//export default ;
